import React from "react";
import { css, select as $ } from "glamor";
import Button from "./Button";
import InputField from "./InputField";
import CheckBox from "./CheckBox";
import { AnimatePresence, motion } from "framer-motion";

class Modal extends React.Component {
  state = {
    height: 196,
    input: "",
    error: "",
    checkBox: "",
  };
  componentDidMount() {
    const height = this.body.offsetHeight + 141;
    this.setState({ height });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.body !== this.props.body) {
      const height = this.body.offsetHeight + 141;
      this.setState({ height });
    }
  }
  render() {
    const {
      title,
      body,
      color,
      buttonOneName,
      buttonTwoName,
      buttonOneTheme,
      buttonTwoTheme,
      buttonOneAction,
      buttonTwoAction,
      checkBoxes,
      input,
      absolute,
      loading,
      disabled,
      customStyle,
      customLayerStyle,
    } = this.props;

    const { height } = this.state;

    let isDisabled = disabled;
    if (checkBoxes) {
      if (!this.state.checkBox) {
        isDisabled = true;
      }
    }

    const buttonOne = (
      <Button
        name={buttonOneName}
        theme={buttonOneTheme}
        customStyle={{
          marginRight: 24,
          display: "inline-block",
          float: "initial",
        }}
        action={buttonOneAction}
      />
    );
    const buttonTwo = (
      <Button
        name={buttonTwoName}
        theme={buttonTwoTheme}
        customStyle={{ display: "inline-block", float: "initial" }}
        loading={loading}
        autoFocus
        action={() => {
          if (checkBoxes) {
            buttonTwoAction(this.state.checkBox);
          } else {
            buttonTwoAction(this.state.input, (error) => {
              if (error) {
                if (error === "No password") {
                  this.setState({ error: "Please enter your password." });
                } else {
                  this.setState({
                    error: "Your password is incorrect. Please try again.",
                  });
                }
              } else {
                this.setState({ input: "" });
              }
            });
          }
        }}
        disabled={isDisabled}
      />
    );
    return (
      <AnimatePresence>
        <>
          <motion.div
            {...css(
              {
                position: "absolute",
                width: 338,
                height,
                background: "white",
                zIndex: 3,
                textAlign: "center",
                margin: "auto",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                borderRadius: 5,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                padding: 24,
                boxSizing: "border-box",
              },
              customStyle
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{
              duration: 0.3,
              ease: [0, 1, 0.5, 1],
            }}
          >
            <div
              {...css({
                float: "left",
                width: "100%",
                fontSize: 16,
                lineHeight: "19px",
                marginBottom: 24,
                color,
              })}
            >
              {title}
            </div>
            <div
              ref={(body) => (this.body = body)}
              {...css(
                {
                  float: "left",
                  width: "100%",
                  fontSize: 14,
                  lineHeight: "16px",
                  fontWeight: 300,
                },
                $(" p", {
                  padding: 0,
                  margin: 0,
                  marginBottom: 24,
                }),
                $(" ul", {
                  textAlign: "left",
                  paddingLeft: 24,
                  marginBottom: 24,
                })
              )}
            >
              {body}
              {input && (
                <div
                  {...css({
                    float: "left",
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 44,
                  })}
                >
                  <InputField
                    label={input.label}
                    placeholder={input.placeholder}
                    value={this.state.input}
                    changeInput={(e) =>
                      this.setState({ input: e.target.value })
                    }
                    fieldName={input.name}
                    error={this.state.error}
                    handleFocus={() => this.setState({ error: "" })}
                    type={input.type}
                  />
                </div>
              )}
              {checkBoxes && (
                <div
                  {...css({
                    float: "left",
                    width: "100%",
                    textAlign: "left",
                    marginBottom: 12,
                  })}
                >
                  {checkBoxes.map((box) => {
                    return (
                      <div
                        {...css({ float: "left", width: "100%" })}
                        key={box.id}
                      >
                        <div {...css({ float: "left", width: 24 })}>
                          <CheckBox
                            active={this.state.checkBox === box.id}
                            handleClick={() => {
                              this.setState({
                                checkBox:
                                  this.state.checkBox === box.id ? "" : box.id,
                              });
                            }}
                          />
                        </div>
                        <div
                          {...css({
                            float: "left",
                            width: "calc(100% - 30px)",
                            marginBottom: 12,
                            marginLeft: 2,
                          })}
                        >
                          {box.text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div
              {...css({
                float: "initial",
                textAlign: "center",
                display: "inline-block",
              })}
            >
              <div {...css({ float: "left" })}>{buttonOne}</div>
              <div {...css({ float: "left" })}>{buttonTwo}</div>
            </div>
          </motion.div>
          <motion.div
            {...css(
              {
                position: absolute ? "absolute" : "fixed",
                background: "rgba(0, 0, 0, 0.2)",
                width: "100%",
                height: "100%",
                zIndex: 2,
                top: 0,
                left: 0,
              },
              customLayerStyle
            )}
            onClick={this.props.handleFocus}
            data-testid="handleFocus"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            transition={{
              duration: 0.3,
              ease: [0, 1, 0.5, 1],
            }}
          />
        </>
      </AnimatePresence>
    );
  }
}

export default Modal;
