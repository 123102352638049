import React from "react";
import { useConfetti } from "../context/ConfettiContext";
import ConfettiExplosion from "react-confetti-explosion";
import { colors } from "../../helpers/styles";

const confettiColors = [
  colors.blue,
  colors.red,
  colors.violet,
  colors.green,
  colors.orange,
];

const Explosion = () => {
  const { confettiExplosion } = useConfetti();

  return (
    <>
      {confettiExplosion && (
        <div
          style={{
            position: "fixed",
            width: "100px",
            height: "100px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <ConfettiExplosion
            width={1000}
            height="120vh"
            duration={5000}
            colors={confettiColors}
          />
        </div>
      )}
    </>
  );
};

export default Explosion;
