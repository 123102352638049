// a list of currencies based on the countries
const CURRENCIES = [
  {
    CtryNm: "AFGHANISTAN",
    CcyNm: "Afghani",
    Ccy: "AFN",
    CcyNbr: "971",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ÅLAND ISLANDS",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ALBANIA",
    CcyNm: "Lek",
    Ccy: "ALL",
    CcyNbr: "008",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ALGERIA",
    CcyNm: "Algerian Dinar",
    Ccy: "DZD",
    CcyNbr: "012",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "AMERICAN SAMOA",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ANDORRA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ANGOLA",
    CcyNm: "Kwanza",
    Ccy: "AOA",
    CcyNbr: "973",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ANGUILLA",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ANTARCTICA",
    CcyNm: "No universal currency",
  },
  {
    CtryNm: "ANTIGUA AND BARBUDA",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ARGENTINA",
    CcyNm: "Argentine Peso",
    Ccy: "ARS",
    CcyNbr: "032",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ARMENIA",
    CcyNm: "Armenian Dram",
    Ccy: "AMD",
    CcyNbr: "051",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ARUBA",
    CcyNm: "Aruban Florin",
    Ccy: "AWG",
    CcyNbr: "533",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "AUSTRALIA",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "AUSTRIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "AZERBAIJAN",
    CcyNm: "Azerbaijan Manat",
    Ccy: "AZN",
    CcyNbr: "944",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BAHAMAS (THE)",
    CcyNm: "Bahamian Dollar",
    Ccy: "BSD",
    CcyNbr: "044",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BAHRAIN",
    CcyNm: "Bahraini Dinar",
    Ccy: "BHD",
    CcyNbr: "048",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "BANGLADESH",
    CcyNm: "Taka",
    Ccy: "BDT",
    CcyNbr: "050",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BARBADOS",
    CcyNm: "Barbados Dollar",
    Ccy: "BBD",
    CcyNbr: "052",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BELARUS",
    CcyNm: "Belarusian Ruble",
    Ccy: "BYN",
    CcyNbr: "933",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BELGIUM",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BELIZE",
    CcyNm: "Belize Dollar",
    Ccy: "BZD",
    CcyNbr: "084",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BENIN",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "BERMUDA",
    CcyNm: "Bermudian Dollar",
    Ccy: "BMD",
    CcyNbr: "060",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BHUTAN",
    CcyNm: "Indian Rupee",
    Ccy: "INR",
    CcyNbr: "356",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BHUTAN",
    CcyNm: "Ngultrum",
    Ccy: "BTN",
    CcyNbr: "064",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BOLIVIA (PLURINATIONAL STATE OF)",
    CcyNm: "Boliviano",
    Ccy: "BOB",
    CcyNbr: "068",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BOLIVIA (PLURINATIONAL STATE OF)",
    CcyNm: {
      "@IsFund": "true",
      "#text": "Mvdol",
    },
    Ccy: "BOV",
    CcyNbr: "984",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BONAIRE, SINT EUSTATIUS AND SABA",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BOSNIA AND HERZEGOVINA",
    CcyNm: "Convertible Mark",
    Ccy: "BAM",
    CcyNbr: "977",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BOTSWANA",
    CcyNm: "Pula",
    Ccy: "BWP",
    CcyNbr: "072",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BOUVET ISLAND",
    CcyNm: "Norwegian Krone",
    Ccy: "NOK",
    CcyNbr: "578",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BRAZIL",
    CcyNm: "Brazilian Real",
    Ccy: "BRL",
    CcyNbr: "986",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BRITISH INDIAN OCEAN TERRITORY (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BRUNEI DARUSSALAM",
    CcyNm: "Brunei Dollar",
    Ccy: "BND",
    CcyNbr: "096",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BULGARIA",
    CcyNm: "Bulgarian Lev",
    Ccy: "BGN",
    CcyNbr: "975",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "BURKINA FASO",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "BURUNDI",
    CcyNm: "Burundi Franc",
    Ccy: "BIF",
    CcyNbr: "108",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CABO VERDE",
    CcyNm: "Cabo Verde Escudo",
    Ccy: "CVE",
    CcyNbr: "132",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CAMBODIA",
    CcyNm: "Riel",
    Ccy: "KHR",
    CcyNbr: "116",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CAMEROON",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CANADA",
    CcyNm: "Canadian Dollar",
    Ccy: "CAD",
    CcyNbr: "124",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CAYMAN ISLANDS (THE)",
    CcyNm: "Cayman Islands Dollar",
    Ccy: "KYD",
    CcyNbr: "136",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CENTRAL AFRICAN REPUBLIC (THE)",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CHAD",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CHILE",
    CcyNm: "Chilean Peso",
    Ccy: "CLP",
    CcyNbr: "152",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CHILE",
    CcyNm: {
      "@IsFund": "true",
      "#text": "Unidad de Fomento",
    },
    Ccy: "CLF",
    CcyNbr: "990",
    CcyMnrUnts: "4",
  },
  {
    CtryNm: "CHINA",
    CcyNm: "Yuan Renminbi",
    Ccy: "CNY",
    CcyNbr: "156",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CHRISTMAS ISLAND",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "COCOS (KEELING) ISLANDS (THE)",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "COLOMBIA",
    CcyNm: "Colombian Peso",
    Ccy: "COP",
    CcyNbr: "170",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "COLOMBIA",
    CcyNm: {
      "@IsFund": "true",
      "#text": "Unidad de Valor Real",
    },
    Ccy: "COU",
    CcyNbr: "970",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "COMOROS (THE)",
    CcyNm: "Comorian Franc",
    Ccy: "KMF",
    CcyNbr: "174",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CONGO (THE DEMOCRATIC REPUBLIC OF THE)",
    CcyNm: "Congolese Franc",
    Ccy: "CDF",
    CcyNbr: "976",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CONGO (THE)",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "COOK ISLANDS (THE)",
    CcyNm: "New Zealand Dollar",
    Ccy: "NZD",
    CcyNbr: "554",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "COSTA RICA",
    CcyNm: "Costa Rican Colon",
    Ccy: "CRC",
    CcyNbr: "188",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CÔTE D'IVOIRE",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "CROATIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CUBA",
    CcyNm: "Cuban Peso",
    Ccy: "CUP",
    CcyNbr: "192",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CUBA",
    CcyNm: "Peso Convertible",
    Ccy: "CUC",
    CcyNbr: "931",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CURAÇAO",
    CcyNm: "Netherlands Antillean Guilder",
    Ccy: "ANG",
    CcyNbr: "532",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CYPRUS",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "CZECHIA",
    CcyNm: "Czech Koruna",
    Ccy: "CZK",
    CcyNbr: "203",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "DENMARK",
    CcyNm: "Danish Krone",
    Ccy: "DKK",
    CcyNbr: "208",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "DJIBOUTI",
    CcyNm: "Djibouti Franc",
    Ccy: "DJF",
    CcyNbr: "262",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "DOMINICA",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "DOMINICAN REPUBLIC (THE)",
    CcyNm: "Dominican Peso",
    Ccy: "DOP",
    CcyNbr: "214",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ECUADOR",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "EGYPT",
    CcyNm: "Egyptian Pound",
    Ccy: "EGP",
    CcyNbr: "818",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "EL SALVADOR",
    CcyNm: "El Salvador Colon",
    Ccy: "SVC",
    CcyNbr: "222",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "EL SALVADOR",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "EQUATORIAL GUINEA",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "ERITREA",
    CcyNm: "Nakfa",
    Ccy: "ERN",
    CcyNbr: "232",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ESTONIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ESWATINI",
    CcyNm: "Lilangeni",
    Ccy: "SZL",
    CcyNbr: "748",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ETHIOPIA",
    CcyNm: "Ethiopian Birr",
    Ccy: "ETB",
    CcyNbr: "230",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "EUROPEAN UNION",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FALKLAND ISLANDS (THE) [MALVINAS]",
    CcyNm: "Falkland Islands Pound",
    Ccy: "FKP",
    CcyNbr: "238",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FAROE ISLANDS (THE)",
    CcyNm: "Danish Krone",
    Ccy: "DKK",
    CcyNbr: "208",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FIJI",
    CcyNm: "Fiji Dollar",
    Ccy: "FJD",
    CcyNbr: "242",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FINLAND",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FRANCE",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FRENCH GUIANA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "FRENCH POLYNESIA",
    CcyNm: "CFP Franc",
    Ccy: "XPF",
    CcyNbr: "953",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "FRENCH SOUTHERN TERRITORIES (THE)",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GABON",
    CcyNm: "CFA Franc BEAC",
    Ccy: "XAF",
    CcyNbr: "950",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "GAMBIA (THE)",
    CcyNm: "Dalasi",
    Ccy: "GMD",
    CcyNbr: "270",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GEORGIA",
    CcyNm: "Lari",
    Ccy: "GEL",
    CcyNbr: "981",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GERMANY",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GHANA",
    CcyNm: "Ghana Cedi",
    Ccy: "GHS",
    CcyNbr: "936",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GIBRALTAR",
    CcyNm: "Gibraltar Pound",
    Ccy: "GIP",
    CcyNbr: "292",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GREECE",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GREENLAND",
    CcyNm: "Danish Krone",
    Ccy: "DKK",
    CcyNbr: "208",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GRENADA",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GUADELOUPE",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GUAM",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GUATEMALA",
    CcyNm: "Quetzal",
    Ccy: "GTQ",
    CcyNbr: "320",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GUERNSEY",
    CcyNm: "Pound Sterling",
    Ccy: "GBP",
    CcyNbr: "826",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "GUINEA",
    CcyNm: "Guinean Franc",
    Ccy: "GNF",
    CcyNbr: "324",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "GUINEA-BISSAU",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "GUYANA",
    CcyNm: "Guyana Dollar",
    Ccy: "GYD",
    CcyNbr: "328",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HAITI",
    CcyNm: "Gourde",
    Ccy: "HTG",
    CcyNbr: "332",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HAITI",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HEARD ISLAND AND McDONALD ISLANDS",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HOLY SEE (THE)",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HONDURAS",
    CcyNm: "Lempira",
    Ccy: "HNL",
    CcyNbr: "340",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HONG KONG",
    CcyNm: "Hong Kong Dollar",
    Ccy: "HKD",
    CcyNbr: "344",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "HUNGARY",
    CcyNm: "Forint",
    Ccy: "HUF",
    CcyNbr: "348",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ICELAND",
    CcyNm: "Iceland Krona",
    Ccy: "ISK",
    CcyNbr: "352",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "INDIA",
    CcyNm: "Indian Rupee",
    Ccy: "INR",
    CcyNbr: "356",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "INDONESIA",
    CcyNm: "Rupiah",
    Ccy: "IDR",
    CcyNbr: "360",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "INTERNATIONAL MONETARY FUND (IMF) ",
    CcyNm: "SDR (Special Drawing Right)",
    Ccy: "XDR",
    CcyNbr: "960",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "IRAN (ISLAMIC REPUBLIC OF)",
    CcyNm: "Iranian Rial",
    Ccy: "IRR",
    CcyNbr: "364",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "IRAQ",
    CcyNm: "Iraqi Dinar",
    Ccy: "IQD",
    CcyNbr: "368",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "IRELAND",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ISLE OF MAN",
    CcyNm: "Pound Sterling",
    Ccy: "GBP",
    CcyNbr: "826",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ISRAEL",
    CcyNm: "New Israeli Sheqel",
    Ccy: "ILS",
    CcyNbr: "376",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ITALY",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "JAMAICA",
    CcyNm: "Jamaican Dollar",
    Ccy: "JMD",
    CcyNbr: "388",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "JAPAN",
    CcyNm: "Yen",
    Ccy: "JPY",
    CcyNbr: "392",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "JERSEY",
    CcyNm: "Pound Sterling",
    Ccy: "GBP",
    CcyNbr: "826",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "JORDAN",
    CcyNm: "Jordanian Dinar",
    Ccy: "JOD",
    CcyNbr: "400",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "KAZAKHSTAN",
    CcyNm: "Tenge",
    Ccy: "KZT",
    CcyNbr: "398",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "KENYA",
    CcyNm: "Kenyan Shilling",
    Ccy: "KES",
    CcyNbr: "404",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "KIRIBATI",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "KOREA (THE DEMOCRATIC PEOPLE’S REPUBLIC OF)",
    CcyNm: "North Korean Won",
    Ccy: "KPW",
    CcyNbr: "408",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "KOREA (THE REPUBLIC OF)",
    CcyNm: "Won",
    Ccy: "KRW",
    CcyNbr: "410",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "KUWAIT",
    CcyNm: "Kuwaiti Dinar",
    Ccy: "KWD",
    CcyNbr: "414",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "KYRGYZSTAN",
    CcyNm: "Som",
    Ccy: "KGS",
    CcyNbr: "417",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LAO PEOPLE’S DEMOCRATIC REPUBLIC (THE)",
    CcyNm: "Lao Kip",
    Ccy: "LAK",
    CcyNbr: "418",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LATVIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LEBANON",
    CcyNm: "Lebanese Pound",
    Ccy: "LBP",
    CcyNbr: "422",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LESOTHO",
    CcyNm: "Loti",
    Ccy: "LSL",
    CcyNbr: "426",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LESOTHO",
    CcyNm: "Rand",
    Ccy: "ZAR",
    CcyNbr: "710",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LIBERIA",
    CcyNm: "Liberian Dollar",
    Ccy: "LRD",
    CcyNbr: "430",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LIBYA",
    CcyNm: "Libyan Dinar",
    Ccy: "LYD",
    CcyNbr: "434",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "LIECHTENSTEIN",
    CcyNm: "Swiss Franc",
    Ccy: "CHF",
    CcyNbr: "756",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LITHUANIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "LUXEMBOURG",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MACAO",
    CcyNm: "Pataca",
    Ccy: "MOP",
    CcyNbr: "446",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NORTH MACEDONIA",
    CcyNm: "Denar",
    Ccy: "MKD",
    CcyNbr: "807",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MADAGASCAR",
    CcyNm: "Malagasy Ariary",
    Ccy: "MGA",
    CcyNbr: "969",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MALAWI",
    CcyNm: "Malawi Kwacha",
    Ccy: "MWK",
    CcyNbr: "454",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MALAYSIA",
    CcyNm: "Malaysian Ringgit",
    Ccy: "MYR",
    CcyNbr: "458",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MALDIVES",
    CcyNm: "Rufiyaa",
    Ccy: "MVR",
    CcyNbr: "462",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MALI",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "MALTA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MARSHALL ISLANDS (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MARTINIQUE",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MAURITANIA",
    CcyNm: "Ouguiya",
    Ccy: "MRU",
    CcyNbr: "929",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MAURITIUS",
    CcyNm: "Mauritius Rupee",
    Ccy: "MUR",
    CcyNbr: "480",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MAYOTTE",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MEMBER COUNTRIES OF THE AFRICAN DEVELOPMENT BANK GROUP",
    CcyNm: "ADB Unit of Account",
    Ccy: "XUA",
    CcyNbr: "965",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "MEXICO",
    CcyNm: "Mexican Peso",
    Ccy: "MXN",
    CcyNbr: "484",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MEXICO",
    CcyNm: {
      "@IsFund": "true",
      "#text": "Mexican Unidad de Inversion (UDI)",
    },
    Ccy: "MXV",
    CcyNbr: "979",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MICRONESIA (FEDERATED STATES OF)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MOLDOVA (THE REPUBLIC OF)",
    CcyNm: "Moldovan Leu",
    Ccy: "MDL",
    CcyNbr: "498",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MONACO",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MONGOLIA",
    CcyNm: "Tugrik",
    Ccy: "MNT",
    CcyNbr: "496",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MONTENEGRO",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MONTSERRAT",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MOROCCO",
    CcyNm: "Moroccan Dirham",
    Ccy: "MAD",
    CcyNbr: "504",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MOZAMBIQUE",
    CcyNm: "Mozambique Metical",
    Ccy: "MZN",
    CcyNbr: "943",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "MYANMAR",
    CcyNm: "Kyat",
    Ccy: "MMK",
    CcyNbr: "104",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NAMIBIA",
    CcyNm: "Namibia Dollar",
    Ccy: "NAD",
    CcyNbr: "516",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NAMIBIA",
    CcyNm: "Rand",
    Ccy: "ZAR",
    CcyNbr: "710",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NAURU",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NEPAL",
    CcyNm: "Nepalese Rupee",
    Ccy: "NPR",
    CcyNbr: "524",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NETHERLANDS (THE)",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NEW CALEDONIA",
    CcyNm: "CFP Franc",
    Ccy: "XPF",
    CcyNbr: "953",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "NEW ZEALAND",
    CcyNm: "New Zealand Dollar",
    Ccy: "NZD",
    CcyNbr: "554",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NICARAGUA",
    CcyNm: "Cordoba Oro",
    Ccy: "NIO",
    CcyNbr: "558",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NIGER (THE)",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "NIGERIA",
    CcyNm: "Naira",
    Ccy: "NGN",
    CcyNbr: "566",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NIUE",
    CcyNm: "New Zealand Dollar",
    Ccy: "NZD",
    CcyNbr: "554",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NORFOLK ISLAND",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NORTHERN MARIANA ISLANDS (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "NORWAY",
    CcyNm: "Norwegian Krone",
    Ccy: "NOK",
    CcyNbr: "578",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "OMAN",
    CcyNm: "Rial Omani",
    Ccy: "OMR",
    CcyNbr: "512",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "PAKISTAN",
    CcyNm: "Pakistan Rupee",
    Ccy: "PKR",
    CcyNbr: "586",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PALAU",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PALESTINE, STATE OF",
    CcyNm: "No universal currency",
  },
  {
    CtryNm: "PANAMA",
    CcyNm: "Balboa",
    Ccy: "PAB",
    CcyNbr: "590",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PANAMA",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PAPUA NEW GUINEA",
    CcyNm: "Kina",
    Ccy: "PGK",
    CcyNbr: "598",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PARAGUAY",
    CcyNm: "Guarani",
    Ccy: "PYG",
    CcyNbr: "600",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "PERU",
    CcyNm: "Sol",
    Ccy: "PEN",
    CcyNbr: "604",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PHILIPPINES (THE)",
    CcyNm: "Philippine Peso",
    Ccy: "PHP",
    CcyNbr: "608",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PITCAIRN",
    CcyNm: "New Zealand Dollar",
    Ccy: "NZD",
    CcyNbr: "554",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "POLAND",
    CcyNm: "Zloty",
    Ccy: "PLN",
    CcyNbr: "985",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PORTUGAL",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "PUERTO RICO",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "QATAR",
    CcyNm: "Qatari Rial",
    Ccy: "QAR",
    CcyNbr: "634",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "RÉUNION",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ROMANIA",
    CcyNm: "Romanian Leu",
    Ccy: "RON",
    CcyNbr: "946",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "RUSSIAN FEDERATION (THE)",
    CcyNm: "Russian Ruble",
    Ccy: "RUB",
    CcyNbr: "643",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "RWANDA",
    CcyNm: "Rwanda Franc",
    Ccy: "RWF",
    CcyNbr: "646",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "SAINT BARTHÉLEMY",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA",
    CcyNm: "Saint Helena Pound",
    Ccy: "SHP",
    CcyNbr: "654",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT KITTS AND NEVIS",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT LUCIA",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT MARTIN (FRENCH PART)",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT PIERRE AND MIQUELON",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAINT VINCENT AND THE GRENADINES",
    CcyNm: "East Caribbean Dollar",
    Ccy: "XCD",
    CcyNbr: "951",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAMOA",
    CcyNm: "Tala",
    Ccy: "WST",
    CcyNbr: "882",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAN MARINO",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAO TOME AND PRINCIPE",
    CcyNm: "Dobra",
    Ccy: "STN",
    CcyNbr: "930",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SAUDI ARABIA",
    CcyNm: "Saudi Riyal",
    Ccy: "SAR",
    CcyNbr: "682",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SENEGAL",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "SERBIA",
    CcyNm: "Serbian Dinar",
    Ccy: "RSD",
    CcyNbr: "941",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SEYCHELLES",
    CcyNm: "Seychelles Rupee",
    Ccy: "SCR",
    CcyNbr: "690",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SIERRA LEONE",
    CcyNm: "Leone",
    Ccy: "SLL",
    CcyNbr: "694",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SIERRA LEONE",
    CcyNm: "Leone",
    Ccy: "SLE",
    CcyNbr: "925",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SINGAPORE",
    CcyNm: "Singapore Dollar",
    Ccy: "SGD",
    CcyNbr: "702",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SINT MAARTEN (DUTCH PART)",
    CcyNm: "Netherlands Antillean Guilder",
    Ccy: "ANG",
    CcyNbr: "532",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: 'SISTEMA UNITARIO DE COMPENSACION REGIONAL DE PAGOS "SUCRE"',
    CcyNm: "Sucre",
    Ccy: "XSU",
    CcyNbr: "994",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "SLOVAKIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SLOVENIA",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SOLOMON ISLANDS",
    CcyNm: "Solomon Islands Dollar",
    Ccy: "SBD",
    CcyNbr: "090",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SOMALIA",
    CcyNm: "Somali Shilling",
    Ccy: "SOS",
    CcyNbr: "706",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SOUTH AFRICA",
    CcyNm: "Rand",
    Ccy: "ZAR",
    CcyNbr: "710",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    CcyNm: "No universal currency",
  },
  {
    CtryNm: "SOUTH SUDAN",
    CcyNm: "South Sudanese Pound",
    Ccy: "SSP",
    CcyNbr: "728",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SPAIN",
    CcyNm: "Euro",
    Ccy: "EUR",
    CcyNbr: "978",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SRI LANKA",
    CcyNm: "Sri Lanka Rupee",
    Ccy: "LKR",
    CcyNbr: "144",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SUDAN (THE)",
    CcyNm: "Sudanese Pound",
    Ccy: "SDG",
    CcyNbr: "938",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SURINAME",
    CcyNm: "Surinam Dollar",
    Ccy: "SRD",
    CcyNbr: "968",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SVALBARD AND JAN MAYEN",
    CcyNm: "Norwegian Krone",
    Ccy: "NOK",
    CcyNbr: "578",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SWEDEN",
    CcyNm: "Swedish Krona",
    Ccy: "SEK",
    CcyNbr: "752",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SWITZERLAND",
    CcyNm: "Swiss Franc",
    Ccy: "CHF",
    CcyNbr: "756",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SWITZERLAND",
    CcyNm: {
      "@IsFund": "true",
      "#text": "WIR Euro",
    },
    Ccy: "CHE",
    CcyNbr: "947",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SWITZERLAND",
    CcyNm: {
      "@IsFund": "true",
      "#text": "WIR Franc",
    },
    Ccy: "CHW",
    CcyNbr: "948",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "SYRIAN ARAB REPUBLIC",
    CcyNm: "Syrian Pound",
    Ccy: "SYP",
    CcyNbr: "760",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TAIWAN (PROVINCE OF CHINA)",
    CcyNm: "New Taiwan Dollar",
    Ccy: "TWD",
    CcyNbr: "901",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TAJIKISTAN",
    CcyNm: "Somoni",
    Ccy: "TJS",
    CcyNbr: "972",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TANZANIA, UNITED REPUBLIC OF",
    CcyNm: "Tanzanian Shilling",
    Ccy: "TZS",
    CcyNbr: "834",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "THAILAND",
    CcyNm: "Baht",
    Ccy: "THB",
    CcyNbr: "764",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TIMOR-LESTE",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TOGO",
    CcyNm: "CFA Franc BCEAO",
    Ccy: "XOF",
    CcyNbr: "952",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "TOKELAU",
    CcyNm: "New Zealand Dollar",
    Ccy: "NZD",
    CcyNbr: "554",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TONGA",
    CcyNm: "Pa’anga",
    Ccy: "TOP",
    CcyNbr: "776",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TRINIDAD AND TOBAGO",
    CcyNm: "Trinidad and Tobago Dollar",
    Ccy: "TTD",
    CcyNbr: "780",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TUNISIA",
    CcyNm: "Tunisian Dinar",
    Ccy: "TND",
    CcyNbr: "788",
    CcyMnrUnts: "3",
  },
  {
    CtryNm: "TÜRKİYE",
    CcyNm: "Turkish Lira",
    Ccy: "TRY",
    CcyNbr: "949",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TURKMENISTAN",
    CcyNm: "Turkmenistan New Manat",
    Ccy: "TMT",
    CcyNbr: "934",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TURKS AND CAICOS ISLANDS (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "TUVALU",
    CcyNm: "Australian Dollar",
    Ccy: "AUD",
    CcyNbr: "036",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UGANDA",
    CcyNm: "Uganda Shilling",
    Ccy: "UGX",
    CcyNbr: "800",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "UKRAINE",
    CcyNm: "Hryvnia",
    Ccy: "UAH",
    CcyNbr: "980",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UNITED ARAB EMIRATES (THE)",
    CcyNm: "UAE Dirham",
    Ccy: "AED",
    CcyNbr: "784",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND (THE)",
    CcyNm: "Pound Sterling",
    Ccy: "GBP",
    CcyNbr: "826",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UNITED STATES MINOR OUTLYING ISLANDS (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UNITED STATES OF AMERICA (THE)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "UNITED STATES OF AMERICA (THE)",
    CcyNm: {
      "@IsFund": "true",
      "#text": "US Dollar (Next day)",
    },
    Ccy: "USN",
    CcyNbr: "997",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "URUGUAY",
    CcyNm: "Peso Uruguayo",
    Ccy: "UYU",
    CcyNbr: "858",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "URUGUAY",
    CcyNm: {
      "@IsFund": "true",
      "#text": "Uruguay Peso en Unidades Indexadas (UI)",
    },
    Ccy: "UYI",
    CcyNbr: "940",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "URUGUAY",
    CcyNm: "Unidad Previsional",
    Ccy: "UYW",
    CcyNbr: "927",
    CcyMnrUnts: "4",
  },
  {
    CtryNm: "UZBEKISTAN",
    CcyNm: "Uzbekistan Sum",
    Ccy: "UZS",
    CcyNbr: "860",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "VANUATU",
    CcyNm: "Vatu",
    Ccy: "VUV",
    CcyNbr: "548",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    CcyNm: "Bolívar Soberano",
    Ccy: "VES",
    CcyNbr: "928",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "VENEZUELA (BOLIVARIAN REPUBLIC OF)",
    CcyNm: "Bolívar Soberano",
    Ccy: "VED",
    CcyNbr: "926",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "VIET NAM",
    CcyNm: "Dong",
    Ccy: "VND",
    CcyNbr: "704",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "VIRGIN ISLANDS (BRITISH)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "VIRGIN ISLANDS (U.S.)",
    CcyNm: "US Dollar",
    Ccy: "USD",
    CcyNbr: "840",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "WALLIS AND FUTUNA",
    CcyNm: "CFP Franc",
    Ccy: "XPF",
    CcyNbr: "953",
    CcyMnrUnts: "0",
  },
  {
    CtryNm: "WESTERN SAHARA",
    CcyNm: "Moroccan Dirham",
    Ccy: "MAD",
    CcyNbr: "504",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "YEMEN",
    CcyNm: "Yemeni Rial",
    Ccy: "YER",
    CcyNbr: "886",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ZAMBIA",
    CcyNm: "Zambian Kwacha",
    Ccy: "ZMW",
    CcyNbr: "967",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ZIMBABWE",
    CcyNm: "Zimbabwe Dollar",
    Ccy: "ZWL",
    CcyNbr: "932",
    CcyMnrUnts: "2",
  },
  {
    CtryNm: "ZZ01_Bond Markets Unit European_EURCO",
    CcyNm: "Bond Markets Unit European Composite Unit (EURCO)",
    Ccy: "XBA",
    CcyNbr: "955",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ02_Bond Markets Unit European_EMU-6",
    CcyNm: "Bond Markets Unit European Monetary Unit (E.M.U.-6)",
    Ccy: "XBB",
    CcyNbr: "956",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ03_Bond Markets Unit European_EUA-9",
    CcyNm: "Bond Markets Unit European Unit of Account 9 (E.U.A.-9)",
    Ccy: "XBC",
    CcyNbr: "957",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ04_Bond Markets Unit European_EUA-17",
    CcyNm: "Bond Markets Unit European Unit of Account 17 (E.U.A.-17)",
    Ccy: "XBD",
    CcyNbr: "958",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ06_Testing_Code",
    CcyNm: "Codes specifically reserved for testing purposes",
    Ccy: "XTS",
    CcyNbr: "963",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ07_No_Currency",
    CcyNm: "The codes assigned for transactions where no currency is involved",
    Ccy: "XXX",
    CcyNbr: "999",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ08_Gold",
    CcyNm: "Gold",
    Ccy: "XAU",
    CcyNbr: "959",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ09_Palladium",
    CcyNm: "Palladium",
    Ccy: "XPD",
    CcyNbr: "964",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ10_Platinum",
    CcyNm: "Platinum",
    Ccy: "XPT",
    CcyNbr: "962",
    CcyMnrUnts: "N.A.",
  },
  {
    CtryNm: "ZZ11_Silver",
    CcyNm: "Silver",
    Ccy: "XAG",
    CcyNbr: "961",
    CcyMnrUnts: "N.A.",
  },
].filter((c) => {
  return typeof c.CcyNm === "string" && c.Ccy;
});

// filter out any double currencies based on the Ccy
const filteredCurrencies = CURRENCIES.reduce((acc, curr) => {
  if (!acc.find((currency) => currency.Ccy === curr.Ccy)) {
    acc.push(curr);
  }
  return acc;
}, []);

// put the currencies EUR, USD, GBP on top
const sortedCurrencies = filteredCurrencies.sort((a, b) => {
  if (a.Ccy === "EUR") {
    return -1;
  }
  if (b.Ccy === "EUR") {
    return 1;
  }
  if (a.Ccy === "USD") {
    return -1;
  }
  if (b.Ccy === "USD") {
    return 1;
  }
  if (a.Ccy === "GBP") {
    return -1;
  }
  if (b.Ccy === "GBP") {
    return 1;
  }

  // now filter alphabetically
  if (a.Ccy < b.Ccy) {
    return -1;
  }
  return 0;
});

export const currencyList = sortedCurrencies.map((c) => {
  const currencySymbol = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: c.Ccy,
  }).format(0)[0];

  return {
    id: c.Ccy,
    name: `${c.CcyNm} (${c.Ccy} – ${currencySymbol})`,
  };
});

export function getCurrencyBasedOnCode(code: string) {
  return currencyList.find((c) => c.id === code);
}

export function getCurrencyBasedOnName(name: string) {
  return currencyList.find((c) => c.name === name);
}
