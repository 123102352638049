import React from "react";
import { css, select as $ } from "glamor";
import checkBox from "../../assets/images/check-box.svg";
import checkBoxEmpty from "../../assets/images/check-box-empty.svg";
import { colors } from "../../helpers/styles";

export default function CheckBox({
  active,
  handleClick,
  label,
  ariaLabel,
  customStyle,
  rounded,
  disabled,
  viewOnly,
}) {
  let image = (
    <img
      src={active ? checkBox : checkBoxEmpty}
      alt={active ? "Active" : "Inactive"}
      onClick={(e) => {
        if (!viewOnly) {
          handleClick(e);
        }
      }}
      {...css(
        {
          cursor: viewOnly ? "default" : "pointer",
          height: 20,
          opacity: disabled ? 0.5 : 1,
        },
        $(":active", {
          transform: viewOnly ? "" : "scaleX(1.1)",
        }),
        customStyle
      )}
      aria-label={ariaLabel}
    />
  );
  if (rounded) {
    image = (
      <span
        onClick={(e) => {
          if (!viewOnly) {
            handleClick(e);
          }
        }}
        className={active ? "icon-tick_checked" : "icon-tick_unchecked"}
        {...css(
          {
            position: "absolute",
            left: -5.62,
            top: -1.5,
            color: active ? colors.blue : "rgba(0,0,0,0.38)",
            fontSize: 22,
            cursor: viewOnly ? "default" : "pointer",
          },
          $(":active", {
            transform: viewOnly ? "" : "scaleX(1.1)",
          }),
          customStyle
        )}
      />
    );
  }

  return label ? (
    <div>
      {rounded && (
        <div
          {...css({
            float: "left",
            marginRight: 14.9,
            width: 15.62,
            height: 15.62,
            position: "relative",
          })}
        >
          {image}{" "}
        </div>
      )}
      {!rounded && (
        <div {...css({ float: "left", marginRight: 14.9 })}>{image}</div>
      )}
      <div {...css({ float: "left" })}>{label}</div>
    </div>
  ) : (
    image
  );
}
