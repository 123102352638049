import React, { useState, useEffect } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";

import AuthHeader from "./AuthHeader";
import AuthBox from "./AuthBox";
import { usePageVisibility, usePrevious } from "../../helpers/hooks";

export default function NotVerified({ user, refetch }) {
  const query = useQuery(USER_QUERY, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: 3000,
  });
  const [sendVerificationEmail, { data }] = useMutation(
    SEND_VERIFICATION_EMAIL,
    {
      onCompleted: () => {},
    }
  );
  const isVisible = usePageVisibility();
  const [disabled, setDisabled] = useState(false);

  const prevVisible = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevVisible === false) {
      handleRefetch();
    }
  }, [isVisible]);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  }, [disabled]);

  async function handleRefetch() {
    await refetch();
  }

  async function handleResendEmail() {
    setDisabled(true);

    const result = await sendVerificationEmail({
      variables: {
        id: user.id,
      },
    }).catch((err) => {
      if (err.message === "User is already verified") {
        window.location.reload();
      }
    });
  }

  function handleSignOut() {
    window.localStorage.removeItem("graphcoolToken");
    window.location.reload();
  }

  return (
    <div className="auth-container">
      <AuthHeader />
      <AuthBox
        icon="bi_com-email"
        alt="Soon - Scheduling App"
        title="Check your email"
        bodyText={
          <div>
            <p
              style={{
                color: "rgba(0,0,0,0.54)",
                fontWeight: 300,
                fontSize: 14,
                padding: 0,
                margin: 0,
                marginBottom: 24,
                wordBreak: "break-word",
              }}
            >
              We have sent an email verification link to <u>{user.email}</u>.
              Please check your email for instructions.
            </p>
            <p
              style={{
                color: "rgba(0,0,0,0.54)",
                fontWeight: 300,
                fontSize: 14,
                padding: 0,
                margin: 0,
                marginBottom: 24,
              }}
            >
              If the email doesn't appear within a few minutes, check your spam
              folder.
            </p>
            <p
              style={{
                color: "rgba(0,0,0,0.54)",
                fontWeight: 300,
                fontSize: 14,
                padding: 0,
                margin: 0,
              }}
            >
              Wrong email? Please sign out and register again. Email verified?
              Refresh your browser.
            </p>
          </div>
        }
        button={{
          name: "Sign out",
          theme: "red-border",
          action: handleSignOut,
          customStyle: { marginBottom: 12, marginTop: 24 },
        }}
        buttonTwo={{
          name: "Resend link",
          theme: "blue-border",
          action: handleResendEmail,
          customStyle: { marginBottom: 12 },
          disabled: disabled,
        }}
      />
    </div>
  );
}

const USER_QUERY = gql`
  query verifiedUser {
    user {
      id
      isVerified
    }
  }
`;

const SEND_VERIFICATION_EMAIL = gql`
  mutation($id: ID!) {
    sendVerificationEmail(id: $id)
  }
`;
