import React from "react";
import { css } from "glamor";
import "./avatar.css";

const Avatar = (props) => {
  const letter = props.firstName ? props.firstName.toUpperCase().charAt(0) : "";
  let number = letter.charCodeAt(0) - 65;
  let size;
  let width;
  let height;
  if (props.size === "small") {
    size = " avatar_small";
    width = "24px";
    height = "24px";
  } else if (props.size === "extraLarge") {
    size = " avatar_extra_large";
    width = "96px";
    height = "96px";
  } else if (props.size === "large") {
    size = " avatar_large";
    width = "48px";
    height = "48px";
  } else if (props.size === "medium") {
    size = " avatar_medium";
    width = "42px";
    height = "42px";
  } else if (props.size === "regularMedium") {
    size = " avatar_regularMedium";
    width = "36px";
    height = "36px";
  } else if (props.size === "mediumLarge") {
    size = " avatar_medium_large";
    width = "72px";
    height = "72px";
  } else {
    size = " avatar_regular";
    width = "30px";
    height = "30px";
  }
  let color;
  if (number > 27) {
    number = props.firstName ? props.firstName.length : 5;
  }

  switch (true) {
    case number < 6:
      color = "avatar_avatar_red";
      break;
    case number > 5 && number < 12:
      color = "avatar_avatar_blue";
      break;
    case number > 11 && number < 16:
      color = "avatar_avatar_purple";
      break;
    case number > 15 && number < 24:
      color = "avatar_avatar_yellow";
      break;
    case number > 23 && number < 28:
      color = "avatar_avatar_green";
      break;
    default:
      color = "avatar_avatar_grey";
      break;
  }

  const deleted = props.firstName === "Deleted" && props.lastName === "User";

  return (
    <div style={props.customStyle}>
      {props.avatar ? (
        <img
          src={props.avatar}
          alt={props.firstName + " " + props.lastName}
          width={width}
          height={height}
          style={{ borderRadius: "5px" }}
        />
      ) : deleted ? (
        <div
          className={"avatar_avatar " + size}
          {...css({
            background: "#e2e2e2",
            fontWeight: props.size === "small" && 900,
          })}
        >
          DU
        </div>
      ) : props.firstName ? (
        <div
          className={"avatar_avatar " + color + size}
          style={props.custom}
          {...css({ fontWeight: props.size === "small" && 900 })}
        >
          {props.lastName
            ? props.firstName.toUpperCase().charAt(0) +
              props.lastName.toUpperCase().charAt(0)
            : props.firstName.toUpperCase().charAt(0)}
        </div>
      ) : (
        <div className={"avatar_avatar " + size} />
      )}
    </div>
  );
};

export default Avatar;
