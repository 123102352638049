interface EmbedType {
  type: "loom" | "giphy" | "unsplash" | "youtube";
  url: string;
}

export default function Embed({
  embeds,
}: {
  embeds: {
    type: "loom" | "giphy" | "unsplash" | "youtube";
    url: string;
  }[];
}) {
  const list = embeds.map((embed: EmbedType, index: number) => {
    if (embed.type === "loom") {
      return <Loom key={index} url={embed.url} />;
    }
    if (embed.type === "giphy") {
      return <Giphy key={index} url={embed.url} />;
    }
    if (embed.type === "unsplash") {
      return <Unsplash key={index} url={embed.url} />;
    }
    if (embed.type === "youtube") {
      return <Youtube key={index} url={embed.url} />;
    }
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      {list}
    </div>
  );
}

function Loom({ url }: { url: string }) {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "75%",
        height: 0,
      }}
    >
      <iframe
        src={url}
        frameBorder="0"
        webkitAllowFullScreen
        mozAllowFullScreen
        allowFullScreen
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: 5,
        }}
      />
    </div>
  );
}

function Unsplash({ url }: { url: string }) {
  return <img src={url} style={{ width: "100%", borderRadius: 5 }} />;
}

function Giphy({ url }: { url: string }) {
  return <img src={url} style={{ width: "100%", borderRadius: 5 }} />;
}

function Youtube({ url }: { url: string }) {
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "75%",
        height: 0,
      }}
    >
      <iframe
        src={url}
        frameBorder="0"
        webkitAllowFullScreen
        mozAllowFullScreen
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: 5,
        }}
      />
    </div>
  );
}
