import styles from "./ToolBox.module.css";

export default function Label(props: {
  children: string;
  style: React.CSSProperties;
}) {
  return (
    <span className={styles.label} style={props.style}>
      {props.children}
    </span>
  );
}
