import { useEffect } from "react";

export function ErrorFallback({ error }) {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    // Failed to fetch dynamically imported module
    const dynamicFailedMessage = /Failed to fetch dynamically imported module/;

    if (error?.message && chunkFailedMessage.test(error.message)) {
      alert("missing chunk");
    }
    if (error?.message && dynamicFailedMessage.test(error.message)) {
      alert("missing dynamic import");
    }
  }, [error]);

  return (
    <div>
      <p>Something went wrong.</p>
      <pre>{error?.message}</pre>
    </div>
  );
}
