import React from "react";
import loader from "../../assets/images/loader.svg";
import { css } from "glamor";

const Loader = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "2",
          lineHeight: "initial",
        }}
      >
        <img width="25px" height="25px" src={loader} alt="Loading Soon" />
      </div>
      {props.background && (
        <div
          {...css(
            {
              position: "fixed",
              width: "100%",
              height: "100%",
              background: "white",
              top: "60px",
              opacity: "0.6",
              zIndex: 1,
            },
            props.backgroundStyle
          )}
          onClick={() => {}}
        />
      )}
    </div>
  );
};

export default Loader;
