import React from "react";
import Moment from "moment-timezone";
import { css, media } from "glamor";
import { formatISOasAMPM } from "../../helpers/functions";

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function getDuration(startTime, endTime) {
  const d = Moment.duration(Moment(endTime).diff(startTime));
  const asDays = Math.round(d.asDays());
  const asHours = Math.round(d.asHours());
  const days = d.days();
  const hours = d.hours();
  const minutes = d.minutes();

  let fDays;
  if (days === 1) {
    fDays =
      Moment(startTime).format("HH:mm") === "00:00" &&
      Moment(endTime).format("HH:mm") === "00:00"
        ? { amount: days, unit: "day" }
        : { amount: 24, unit: "hours" };
  } else {
    fDays = days > 0 && { amount: days, unit: "days" };
  }

  // hours
  const minutesAsPercentage = round(minutes / 60, 2);
  const hoursMinutes = hours + minutesAsPercentage;
  let fHours =
    hoursMinutes === 1
      ? { amount: hoursMinutes, unit: "hour" }
      : hoursMinutes > 0 && { amount: hoursMinutes, unit: "hours" };

  if (d.asHours() > 24) {
    let startDuration = 0;
    let endDuration = 0;
    if (Moment(startTime).format("HH:mm") !== "00:00") {
      startDuration = Moment.duration(
        Moment(startTime)
          .add(1, "day")
          .hours("00")
          .minutes("00")
          .diff(startTime)
      ).asHours();
    }
    if (Moment(endTime).format("HH:mm") !== "00:00") {
      endDuration = Moment.duration(
        Moment(endTime).diff(
          Moment(endTime)
            .hours("00")
            .minutes("00")
        )
      ).asHours();
    }
    fHours = startDuration + endDuration > 0 && {
      amount: round(startDuration + endDuration, 2),
      unit: startDuration + endDuration === 1 ? "hour" : "hours",
    };
    const remainingDuration = d.asHours() - (startDuration + endDuration);
    fDays = remainingDuration / 24 > 0 && {
      amount: round(remainingDuration / 24),
      unit: remainingDuration / 24 === 1 ? "day" : "days",
    };
  }

  return { days, hours, minutes, fDays, fHours, asDays, asHours };
}

export function allDayCheck(startTime, endTime) {
  const duration = Moment.duration(Moment(endTime).diff(startTime)).asHours();
  if (duration >= 24) {
    return true;
  } else {
    return false;
  }
}

export function getWhen(startTime, endTime, notification, format12h) {
  let when;
  const duration = Moment.duration(Moment(endTime).diff(startTime));
  const st = format12h
    ? formatISOasAMPM(startTime)
    : Moment(startTime).format("HH:mm");
  const et = format12h
    ? formatISOasAMPM(endTime)
    : Moment(endTime).format("HH:mm");

  const before =
    Moment()
      .hours(Moment(startTime).format("HH"))
      .minutes(Moment(startTime).format("mm"))
      .isBefore(
        Moment()
          .hours(Moment(endTime).format("HH"))
          .minutes(Moment(endTime).format("mm"))
      ) && duration.asHours() < 48;

  if (duration.asHours() < 25 || before) {
    when = (
      <span>
        {Moment(startTime).format("ddd, D MMM")}{" "}
        <span {...css({ color: "#E2E2E2", fontWeight: 300 })}>|</span>{" "}
        <span>
          {st}–{et}
        </span>
        {!Moment(startTime).isSame(endTime, "day") && <small>+1</small>}
      </span>
    );
    // all day === vandaag 00:00 t/m morgen 00:00
    if (
      duration.asDays() === 1 &&
      Moment(startTime).format("HH:mm") === "00:00" &&
      Moment(endTime).format("HH:mm") === "00:00"
    ) {
      when = (
        <span>
          {Moment(startTime).format("ddd, D MMM")}{" "}
          <span {...css({ color: "#E2E2E2", fontWeight: 300 })}>| </span>
          All Day
        </span>
      );
    }
  } else {
    // meer dan 48 uur => 12-20 - 14-20
    if (
      Moment(startTime).format("HH:mm") === "00:00" &&
      Moment(endTime).format("HH:mm") === "00:00"
    ) {
      // allday
      when = `${Moment(startTime).format("ddd")}, ${Moment(startTime).format(
        "DD"
      )} ${
        Moment(startTime).isSame(new Date(), "year")
          ? Moment(startTime).format("MMM")
          : Moment(startTime).format("MMM, YYYY")
      } – ${
        Moment(endTime)
          .subtract(1, "day")
          .isSame(new Date(), "year")
          ? Moment(endTime)
              .subtract(1, "day")
              .format("ddd, DD MMM")
          : Moment(endTime)
              .subtract(1, "day")
              .format("ddd, DD MMM, YYYY")
      }`;
    } else {
      if (notification) {
        const startTimeFormatted = format12h
          ? formatISOasAMPM(startTime)
          : Moment(startTime).format("HH:mm");
        const endTimeFormatted = format12h
          ? formatISOasAMPM(endTime)
          : Moment(endTime).format("HH:mm");

        when = `${
          Moment(startTime).isSame(new Date(), "year")
            ? startTimeFormatted + Moment(startTime).format(", DD MMM")
            : startTimeFormatted + Moment(startTime).format(", DD MMM, YYYY")
        } – ${
          Moment(endTime).isSame(new Date(), "year")
            ? endTimeFormatted + Moment(endTime).format(", DD MMM")
            : endTimeFormatted + Moment(endTime).format(", DD MMM, YYYY")
        }`;
      } else {
        when = (
          <div
            {...css(
              { float: "left", width: "100%", marginTop: 14 },
              media("(max-width: 500px)", {
                marginTop: 0,
              })
            )}
          >
            <div
              {...css({
                display: "inline-block",
                lineHeight: "17px",
                float: "left",
                marginRight: 4,
              })}
            >
              <span {...css({ lineHeight: "17px" })}>
                {Moment(startTime).format("ddd, DD MMM, YYYY")} –{" "}
              </span>
              <br />
              {Moment(startTime).format("HH:mm") !== "00:00" && (
                <div
                  {...css({
                    lineHeight: "15px",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.54)",
                    fontWeight: 300,
                  })}
                >
                  starts{" "}
                  {format12h
                    ? formatISOasAMPM(startTime)
                    : Moment(startTime).format("HH:mm")}
                </div>
              )}
            </div>
            <div
              {...css({
                display: "inline-block",
                lineHeight: "17px",
                float: "left",
              })}
            >
              <span {...css({ lineHeight: "17px" })}>
                {Moment(endTime).format("HH:mm") === "00:00"
                  ? Moment(endTime)
                      .subtract(1, "day")
                      .format("ddd, DD MMM, YYYY")
                  : Moment(endTime).format("ddd, DD MMM, YYYY")}
              </span>
              <br />
              {Moment(endTime).format("HH:mm") !== "00:00" && (
                <div
                  {...css({
                    lineHeight: "15px",
                    fontSize: 12,
                    color: "rgba(0, 0, 0, 0.54)",
                    fontWeight: 300,
                  })}
                >
                  ends{" "}
                  {format12h
                    ? formatISOasAMPM(endTime)
                    : Moment(endTime).format("HH:mm")}
                </div>
              )}
            </div>
          </div>
        );
      }
    }
  }
  return when;
}
