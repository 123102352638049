import { gql, useQuery } from "@apollo/client";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useConfetti } from "../context/ConfettiContext";

type Achievement = {
  id: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  isNew: boolean;
  goal: {
    id: string;
    code: string;
    description: string;
  };
};

export default function Achievements() {
  const { showConfetti } = useConfetti();
  const query = useQuery(GET_ACHIEVEMENTS_QUERY, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    pollInterval: 8000,
  });

  function handleAchievement(achievement: Achievement) {
    toast.success("Account setup task completed");
    showConfetti();
  }

  useEffect(() => {
    if (!query.loading && query.data) {
      const achievements = query.data.getUserAchievements;
      if (!achievements) return;
      achievements.forEach((achievement: Achievement) => {
        if (achievement.isNew) {
          handleAchievement(achievement);
        }
      });

      const needsMoreAchievements = achievements.some((a: Achievement) => {
        return a.status === "IN_PROGRESS";
      });
      if (!needsMoreAchievements) {
        query.stopPolling();
      }
    }
  }, [query.data]);

  return <></>;
}

const GET_ACHIEVEMENTS_QUERY = gql`
  query AccountSetup {
    getUserAchievements {
      id
      status
      createdAt
      updatedAt
      isNew
      goal {
        id
        code
        description
      }
    }
  }
`;
