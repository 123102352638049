import ModalLarge from "./ModalLarge";
import Button from "./Button";

export default function SoonImproved({ action, dismiss }) {
  return (
    <ModalLarge
      icon={
        <span
          className="bi_interface-circle-tick"
          style={{ color: "#28AAFF", fontSize: 24 }}
        />
      }
      innerCircleStyle={{}}
      title="New update available"
      subtitle="Soon just got better!"
      content={
        <span>
          A new version is ready. You can update now, or do it later by
          reloading the app.
          <br />
          <br />
          Updating now will discard any unsaved work. To avoid losing progress,
          save your work first and reload the page at a later time.
        </span>
      }
      actions={[
        <Button action={action} name="Update now" theme="blue-border" />,
        <Button
          action={dismiss}
          name="Update on next reload"
          theme="grey-border"
        />,
      ]}
    />
  );
}
