import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import { css, select as $, media } from "glamor";
import NotificationFooter from "./NotificationFooter";
import NotificationList from "./NotificationList";

const bounce = css.keyframes({
  "0%": { transform: "translateY(-50%)" },
  "100%": { transform: "translateY(0%)" },
});

const notificationBox = css(
  {
    position: "absolute",
    float: "left",
    left: "-264px",
    top: "50px",
    width: "312px",
    maxHeight: "504px",
    background: "white",
    zIndex: 6,
    borderRadius: "5px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    color: "black",
    textAlign: "left",
  },
  media("(max-width: 900px)", {
    position: "fixed",
    left: "0",
    top: "0",
    borderRadius: "0px",
    width: "100%",
    maxHeight: "100%",
    bottom: "0",
  })
);

const float = css({
  float: "left",
});

const hundred = css({
  width: "100%",
});

const notificationHeader = css(
  {
    borderBottom: "1px solid #e2e2e2",
    cursor: "pointer",
    boxSizing: "border-box",
    color: "rgba(0,0,0,0.54)",
    fontSize: "14px",
    lineHeight: "17px",
    padding: "12px",
    textAlign: "center",
  },
  media("(max-width: 900px)", {
    display: "none",
  })
);

export default class Notifications extends React.Component {
  constructor() {
    super();
    this.state = {
      unreadView: false,
      screenHeight: "",
      markAllRead: false,
      view: "notifications",
    };
  }

  componentDidMount() {
    const screenHeight = window.innerHeight - 120 + "px";
    this.setState({ screenHeight });
  }

  handleView(action) {
    this.setState({ unreadView: action });
  }

  markAllRead = (boolean) => {
    const markAllRead = boolean === "false" ? false : true;
    this.setState({ markAllRead });
  };

  render() {
    const notificationBody = css(
      {
        padding: this.state.view === "settings" ? "6px 0px 6px 6px" : 6,
        marginBottom: "44px",
        boxSizing: "border-box",
        overflowY: "scroll",
        maxHeight: "calc(100% - 42px)",
      },
      $(
        "& ul",
        {
          margin: "0",
          padding: "0",
          listStyleType: "none",
          overflowY: "scroll",
          maxHeight: "415px",
        },
        $("::-webkit-scrollbar", {
          display: "none",
        }),
        media("(max-width: 900px)", {
          height: "auto",
          overflowY: "visible",
        })
      ),
      $("::-webkit-scrollbar", {
        display: "none",
      }),
      $("& p", {
        fontSize: "14px",
        fontWeight: "300",
        lineHeight: "14px",
        padding: "24px",
        textAlign: "center",
        color: "rgba(0,0,0,0.54)",
      }),
      media(
        "(max-width: 900px)",
        {
          paddingTop: "0px",
          paddingBottom: "0px",
          marginBottom: "0px",
        },
        $("& ul", {
          maxHeight: this.state.screenHeight,
        })
      )
    );

    return (
      <div {...notificationBox}>
        <div {...css(notificationHeader)}>
          {this.state.view === "settings"
            ? "Notification Settings"
            : "Notifications"}
          {this.state.view === "settings" ? (
            <div
              className="tooltip"
              {...css({
                position: "absolute",
                left: 12,
              })}
            >
              <span
                className="bi_interface-arrow-left"
                id="notificationPanel"
                onClick={() => this.setState({ view: "notifications" })}
              />
              <span
                className="tooltiptext"
                {...css(
                  {
                    lineHeight: "16px",
                    marginTop: "5px",
                    marginLeft: "-22px !important",
                  },
                  $("::after ", {
                    left: "16.5px",
                  })
                )}
              >
                Back
              </span>
            </div>
          ) : (
            <div
              className="tooltip"
              {...css({
                position: "absolute",
                right: 12,
              })}
            >
              <span
                className="bi_setting-gear-a"
                onClick={this.props.handleOpenNotificationSettings}
                id="notificationPanel"
                {...css(
                  {
                    fontSize: 17,
                    color: "rgba(0,0,0,0.54)",
                  },
                  $(":hover", {
                    color: "rgba(0,0,0,0.87)",
                  })
                )}
              />
              <span
                className="tooltiptext"
                {...css(
                  {
                    lineHeight: "16px",
                    marginTop: "5px",
                    marginLeft: "-118px !important",
                  },
                  $("::after ", {
                    left: "114.5px",
                  })
                )}
              >
                Notification Settings
              </span>
            </div>
          )}
        </div>

        <div {...css(float, hundred, notificationBody)}>
          <div
            {...css(
              media("(min-width: 900px)", {
                display: "none",
              })
            )}
          >
            <VisibilitySensor>
              {({ isVisible }) => {
                return (
                  <div
                    {...css({
                      float: "left",
                      width: "100%",
                      height: this.state.view === "settings" ? 44 : 93,
                    })}
                  >
                    <div
                      {...css({
                        float: "left",
                        width: "100%",
                        boxSizing: "border-box",
                        cursor: "pointer",
                        paddingLeft: "9px",
                        fontSize: "16px",
                        lineHeight: "40px",
                      })}
                    >
                      <span
                        {...css(
                          {
                            float: "left",
                            paddingRight: 4,
                            fontSize: 20,
                            color: "rgba(0,0,0,0.54)",
                          },
                          $(":active", {
                            color: "rgba(0,0,0,0.87)",
                          })
                        )}
                        className="bi_interface-cross"
                        onClick={this.props.handleFocus}
                      />
                      <span
                        {...css(
                          {
                            float: "right",
                            fontSize: 20,
                            paddingRight: 7,
                            color: "rgba(0,0,0,0.54)",
                          },
                          $(":active", {
                            color: "rgba(0,0,0,0.87)",
                          })
                        )}
                        className="bi_setting-gear-a"
                        onClick={this.props.handleOpenNotificationSettings}
                      />
                    </div>{" "}
                    {this.state.view === "notifications" ? (
                      <div
                        {...css({
                          float: "left",
                          width: "100%",
                          fontSize: "36px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                          lineHeight: "32px",
                          marginBottom: "9px",
                          textAlign: "left",
                          paddingLeft: "9px",
                          boxSizing: "border-box",
                        })}
                      >
                        Notifications
                      </div>
                    ) : (
                      <div
                        {...css({
                          position: "fixed",
                          width: "100%",
                          background: "white",
                          top: "0",
                          left: "0",
                          borderBottom: "1px solid #E2E2E2",
                          zIndex: "1",
                          textAlign: "center",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          fontSize: "20px",
                          lineHeight: "24px",
                        })}
                      >
                        <span
                          {...css({
                            position: "absolute",
                            left: "0",
                            top: "0",
                            cursor: "pointer",
                            paddingLeft: "15px",
                            fontSize: "20px",
                            lineHeight: "44px",
                            boxSizing: "border-box",
                          })}
                          id="notificationPanel"
                          className="bi_interface-arrow-left"
                          onClick={() =>
                            this.setState({ view: "notifications" })
                          }
                        />
                        Notification Settings{" "}
                      </div>
                    )}
                    {!isVisible && (
                      <div
                        {...css({
                          position: "fixed",
                          width: "100%",
                          background: "white",
                          top: "0",
                          left: "0",
                          borderBottom: "1px solid #E2E2E2",
                          animation: `${bounce} 0.6s`,
                          zIndex: "1",
                          textAlign: "center",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          fontSize: "20px",
                          lineHeight: "24px",
                        })}
                      >
                        <div
                          {...css({
                            position: "absolute",
                            left: "0",
                            top: "0",
                            boxSizing: "border-box",
                            cursor: "pointer",
                            paddingLeft: "15px",
                            fontSize: "20px",
                            lineHeight: "48px",
                          })}
                          onClick={this.props.handleFocus}
                        >
                          <span
                            {...css(
                              {
                                color: "rgba(0,0,0,0.54)",
                              },
                              $(":active", {
                                color: "rgba(0,0,0,0.87)",
                              })
                            )}
                            className="bi_interface-cross"
                          />
                        </div>
                        Notifications
                        <span
                          {...css(
                            {
                              right: 12,
                              position: "absolute",
                              fontSize: 20,
                              color: "rgba(0,0,0,0.54)",
                            },
                            $(":active", {
                              color: "rgba(0,0,0,0.87)",
                            })
                          )}
                          className="bi_setting-gear-a"
                          onClick={() => this.setState({ view: "settings" })}
                        />
                      </div>
                    )}
                  </div>
                );
              }}
            </VisibilitySensor>
          </div>

          {this.state.view === "notifications" && (
            <NotificationList
              unread={this.state.unreadView}
              userId={this.props.userId}
              notifications={this.state.notifications}
              handleFocus={this.props.handleFocus}
              teamId={this.props.teamId}
              markAll={this.state.markAllRead}
              markAllRead={this.markAllRead}
              match={this.props.match}
              format12h={this.props.format12h}
            />
          )}
        </div>

        {this.state.view === "notifications" && (
          <NotificationFooter
            userId={this.props.userId}
            markAllRead={this.markAllRead}
          />
        )}
      </div>
    );
  }
}
