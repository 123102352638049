import React from "react";
import "./button.css";
import { css } from "glamor";

class Button extends React.Component {
  state = {
    timeout: false,
  };
  handleTimeOut = () => {};

  render() {
    const { nature, size, text, handleClick, intercomTarget } = this.props;
    let style;

    switch (nature) {
      case "confirm":
        style = "btn btn-confirm ";
        break;
      case "neutral":
        style = "btn btn-neutral ";
        break;
      case "cancel":
        style = "btn btn-cancel ";
        break;
      case "red":
        style = "btn btn-red ";
        break;
      case "red-border":
        style = "btn btn-red-border ";
        break;
      case "purple":
        style = "btn btn-purple ";
        break;
      case "purple-border":
        style = "btn btn-purple-border ";
        break;
      case "blue-border":
        style = "btn btn-blue-border ";
        break;
      case "orange-border":
        style = "btn btn-orange-border ";
        break;
      case "white-border":
        style = "btn btn-white-border ";
        break;
      case "grey-border":
        style = "btn btn-grey-border ";
        break;
      case "green":
        style = "btn btn-green ";
        break;
      case "green-border":
        style = "btn btn-green-border ";
        break;
      case "black-border":
        style = "btn btn-black-border ";
        break;
      default:
        style = "btn ";
        break;
    }

    const loading = this.props.loading;
    const isLoading = (
      <svg className="spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        />
      </svg>
    );
    const { customStyle, name } = this.props;
    return (
      <div>
        {size === "xs" ? (
          <button
            {...css({
              padding: "6px 8px",
              fontSize: "14px",
              lineHeight: "17px",
              fontWeight: "500",
              textAlign: "center",
              borderRadius: "3px",
              border: "0",
              fontFamily: "Museo Sans",
              cursor: "pointer",
              ...(customStyle && { ...customStyle }),
            })}
            onClick={handleClick}
            disabled={this.props.disabled}
            name={name}
            data-intercom-target={intercomTarget}
          >
            {text}
          </button>
        ) : (
          <div>
            {this.props.disabled ? (
              <button
                style={this.props.buttonStyle}
                className={style + size}
                onClick={this.props.handleClick}
                disabled
                {...css({
                  ...(customStyle && { ...customStyle }),
                })}
                name={name}
              >
                {loading ? isLoading : this.props.text}
              </button>
            ) : loading ? (
              <button
                style={this.props.buttonStyle}
                className={style + size}
                onClick={this.props.handleClick}
                disabled
                {...css({
                  ...(customStyle && { ...customStyle }),
                })}
                name={name}
              >
                {isLoading}
              </button>
            ) : (
              <button
                className={style + size}
                onClick={this.props.handleClick}
                style={this.props.buttonStyle}
                {...css({
                  ...(customStyle && { ...customStyle }),
                })}
                name={name}
                data-intercom-target={intercomTarget}
              >
                {this.props.text}
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Button;
