import React from "react";
import { css, select as $ } from "glamor";
import { colors } from "../../helpers/styles";
import matchSorter from "match-sorter";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

class Dropdown extends React.PureComponent {
  handleKeyDown = (e, action) => {
    e.preventDefault();
    if (e.keyCode === 40) {
      e.target.nextSibling && e.target.nextSibling.focus();
    } else if (e.keyCode === 38) {
      e.target.previousSibling && e.target.previousSibling.focus();
    } else if (e.keyCode === 13) {
      action(e.target.innerText);
      this.props.handleFocus();
    } else if (e.keyCode === 37) {
      this.props.input.focus();
    } else {
      return;
    }
  };
  render() {
    const { options, handleFocus, emptyText, emptyStyle } = this.props;

    const list = options.map((item) => {
      return (
        <li
          tabIndex="0"
          onKeyDown={(e) => this.handleKeyDown(e, item.action)}
          key={item.id}
          {...css(
            {
              float: "left",
              width: "100%",
              boxSizing: "border-box",
              padding: "3px 6px",
              lineHeight: "20px",
              fontSize: 14,
              fontWeight: item.selected ? 500 : 300,
              color: item.selected ? colors.blue : "rgba(0, 0, 0, 0.54)",
            },
            $(":hover", {
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: 3,
              cursor: "pointer",
              fontWeight: 500,
            })
          )}
          onClick={() => {
            item.action(item.name);
            handleFocus();
          }}
        >
          {item.name}
        </li>
      );
    });
    return (
      <OutsideAlerter action={handleFocus}>
        <div
          {...css(
            {
              position: "absolute",
              width: "100%",
              background: "white",
              borderRadius: "5px",
              zIndex: 2,
              color: "rgba(0,0,0,0.54)",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              top: 58,
              left: 0,
            },
            this.props.customStyle
          )}
        >
          {options.length > 0 || !emptyText ? (
            <ul
              {...css(
                {
                  float: "left",
                  width: "100%",
                  listStyle: "none",
                  padding: "3px 6px 6px 6px",
                  boxSizing: "border-box",
                  margin: 0,
                  maxHeight: 200,
                  overflowY: "scroll",
                },
                $("::-webkit-scrollbar", {
                  display: "none",
                })
              )}
              ref={(list) => (this.list = list)}
            >
              {list}
            </ul>
          ) : (
            <div {...css(emptyStyle)}>{emptyText}</div>
          )}
        </div>
      </OutsideAlerter>
    );
  }
}

class InputField extends React.Component {
  state = {
    focus: false,
    dropdownMenu: false,
    error: false,
  };

  componentDidUpdate(prevState, prevProps) {
    if (this.props.value !== prevProps.value) {
      if (!this.props.options.map((o) => o.name).includes(this.props.value)) {
        if (!this.state.error) {
          this.setState({ error: true });
        }
      } else {
        if (this.state.error) {
          this.setState({ error: false });
        }
      }
    }
  }

  onBlur = (e) => {
    this.setState({ focus: false });
    if (this.props.handleBlur) this.props.handleBlur(e);
  };

  onFocus = () => {
    this.props.error && this.props.handleFocus && this.props.handleFocus();
    this.setState({ focus: true, error: false });
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 40) {
      this.dropdown &&
        this.dropdown.item &&
        this.dropdown.item.list.children[0].focus();
    } else {
      return;
    }
  };

  render() {
    const {
      customStyle,
      disabled,
      options,
      autoFocus,
      emptyText,
      externalFocus,
      arrowUp,
      emptyStyle,
      loading,
    } = this.props;

    const error = this.state.error && !loading;

    const matchedOptions = this.props.options
      .map((o) => o.name)
      .includes(this.props.value)
      ? this.props.options
      : matchSorter(options, this.props.value, {
          keys: ["name"],
        });

    const { focus, dropdownMenu } = this.state;
    return (
      <div
        {...css(
          customStyle,
          { float: "left", width: "100%" },
          $(":hover .input-field-arrow", {
            color: "rgba(0,0,0,0.87)",
          })
        )}
      >
        <label
          {...css({
            float: "left",
            fontSize: 14,
            lineHeight: "17px",
            fontWeight: 500,
            color: error
              ? colors.red
              : focus || dropdownMenu
              ? colors.blue
              : "rgba(0, 0, 0, 0.54)",
          })}
        >
          {this.props.label}
        </label>
        <div {...css({ position: "relative" })}>
          <div
            onClick={
              options
                ? () => {
                    !disabled && this.setState({ dropdownMenu: true });
                  }
                : () => {}
            }
            data-testid="inputField"
          >
            <input
              autoComplete="off"
              id="123"
              autoFocus={autoFocus}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              name={this.props.name}
              type={this.props.type}
              onChange={this.props.handleChange}
              onKeyDown={(e) => this.handleKeyDown(e)}
              value={this.props.value}
              placeholder={this.props.placeholder}
              disabled={disabled}
              ref={(input) => (this.input = input)}
              {...css(
                {
                  float: "left",
                  border: 0,
                  borderBottom: error
                    ? `1px solid ${colors.red}`
                    : dropdownMenu || externalFocus
                    ? `1px solid ${colors.blue}`
                    : "1px solid rgba(226, 226, 226, 1)",
                  fontFamily: "Museo Sans",
                  fontSize: 16,
                  color: error ? colors.red : "#28AAFF",
                  textShadow: "0px 0px 0px rgba(0, 0, 0, 0.87)",
                  WebkitTextFillColor: "transparent",
                  width: "100%",
                  height: 33,
                  fontWeight: 300,
                  boxSizing: "border-box",
                },
                $("::-webkit-input-placeholder", {
                  textShadow: "none",
                  WebkitTextFillColor: "initial",
                }),
                $(":focus", {
                  outline: 0,
                  borderBottom: error
                    ? `1px solid ${colors.red}`
                    : `1px solid ${colors.blue}`,
                }),
                $(":disabled", {
                  background: "white",
                })
              )}
            />
            {options && !disabled && (
              <span
                {...css({
                  position: "absolute",
                  right: -4,
                  top: arrowUp ? (this.props.label ? 15 : 0) : 27,
                  fontSize: 18.75,
                  cursor: "pointer",
                  color: "rgba(0,0,0,0.54)",
                })}
                className="bi_interface-bottom input-field-arrow"
              />
            )}
          </div>
          {dropdownMenu && (
            <Dropdown
              options={matchedOptions}
              handleFocus={() => this.setState({ dropdownMenu: false })}
              emptyText={emptyText}
              emptyStyle={emptyStyle}
              exclude="123"
              ref={(dropdown) => (this.dropdown = dropdown)}
              input={this.input}
              customStyle={this.props.dropdownCustomStyle}
            />
          )}
          {error && <div {...css(errorText)}>{error}</div>}
        </div>
      </div>
    );
  }
}

export default InputField;

const errorText = {
  position: "absolute",
  left: 0,
  top: 38,
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "500",
  height: "20px",
  color: colors.red,
};
