/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { css, jsx } from "@emotion/core";
import { gql, useMutation } from "@apollo/client";
import Moment from "moment-timezone";

import Button from "../toolBox/Button";
import {
  ModalContainer,
  ModalHeader,
  ModalProfileCard,
  ModalDescription,
  ModalItem,
} from "../toolBox/MenuModal";

import GoogleCalendarIcon from "../../assets/images/google-calendar-icon-large.png";
import OutlookCalendarIcon from "../../assets/images/outlook-calendar-icon.svg";
import CheckBox from "../toolBox/CheckBox";
import ToolTip from "../toolBox/ToolTip";
import Modal from "../toolBox/Modal";
import { colors } from "../../helpers/styles";
import { connectGoogle, connectOutlook } from "../../helpers/apiConnections";
import { GET_INTEGRATION_STATUSES } from "../header/Header";
import toast from "react-hot-toast";

function checkIfEntryTypesChanged(currentEntryTypes, newEntryTypes) {
  if (!currentEntryTypes || !newEntryTypes) {
    return false;
  }
  // currentEntryTypes = ["ACTIVITIES", "LEAVES"]
  // newEntryTypes = ["ACTIVITIES", "EVENTS", ]

  // first check if the length is different
  if (currentEntryTypes.length !== newEntryTypes.length) {
    return true;
  } else {
    // if the length is the same, check if the values are the same
    for (let i = 0; i < currentEntryTypes.length; i++) {
      if (currentEntryTypes[i] !== newEntryTypes[i]) {
        return true;
      }
    }
  }
  return false;
}

export default function CalendarSettings({
  userId,
  googleCalendarConfig,
  closeModal,
  googleConnectionStatus,
  lastSynced,
  emailConnected,
  type,
  config,
  connectionStatus,
  integrationId,
}) {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalCalendarSharing, setModalCalendarSharing] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [calendarType, setCalendarType] = useState(
    config.calendarType ? config.calendarType : "MY_CALENDAR"
  );
  const [entryTypes, setEntryTypes] = useState(
    config.entryTypes ? config.entryTypes : ["ACTIVITIES", "EVENTS", "LEAVES"]
  );
  const [shareCalendar, setShareCalendar] = useState(config.shareCalendar);
  const [shareTypes, setShareTypes] = useState(
    config.shareTypes ? config.shareTypes : []
  );

  const [
    updateGoogleCalendarConfig,
    { data, loading: updateLoading },
  ] = useMutation(UPDATE_CALENDAR_CONFIG, {
    refetchQueries: [
      { query: GET_INTEGRATION_STATUSES, variables: { userId } },
    ],
    awaitRefetchQueries: true,
  });
  const [
    updateIntegrationConfig,
    { data: integrationData, loading: updateIntegrationLoading },
  ] = useMutation(UPDATE_INTEGRATION_CONFIG, {
    refetchQueries: [
      { query: GET_INTEGRATION_STATUSES, variables: { userId } },
    ],
    awaitRefetchQueries: true,
  });

  const [disableGoogleCalendar] = useMutation(DISABLE_GOOGLE_CALENDAR);
  const [createGoogleCalendar] = useMutation(CREATE_GOOGLE_CALENDAR);
  const [disableOutlookCalendar] = useMutation(DISABLE_OUTLOOK_CALENDAR);
  const [createOutlookCalendar] = useMutation(CREATE_OUTLOOK_CALENDAR);
  const [resyncCalendars] = useMutation(RESYNC_CALENDARS);

  useEffect(() => {
    if (isTimedOut) {
      setTimeout(() => {
        setIsTimedOut(false);
      }, 10000);
    }
  }, [isTimedOut]);

  async function handleDisableCalendar() {
    setLoading(true);
    if (type === "GOOGLE_CALENDAR") {
      const result = await disableGoogleCalendar({
        variables: {
          userId,
        },
      });
    }
    if (type === "OUTLOOK_CALENDAR") {
      const result = await disableOutlookCalendar({
        variables: {
          userId,
        },
      });
    }

    window.location.reload();
  }

  async function handleConnectCalendar() {
    setLoading(true);
    if (type === "GOOGLE_CALENDAR") {
      await connectGoogle({
        userId,
        setLoading,
        callback: handleUpdateCalendarConfig,
      });
    }
    if (type === "OUTLOOK_CALENDAR") {
      await handleUpdateCalendarConfig();
      connectOutlook({ userId });
    }
  }

  async function handleUpdateCalendarConfig(remove) {
    let calendarConfig = {
      calendarType,
      entryTypes,
      shareCalendar,
      shareTypes,
      soonCalendarId: config ? config.soonCalendarId : "",
      emailConnected: config ? config.emailConnected : "",
    };

    const entryTypesChanged = checkIfEntryTypesChanged(
      calendarConfig.entryTypes,
      config.entryTypes
    );

    if (remove) {
      calendarConfig = null;
    }

    let newConfig;
    if (type === "GOOGLE_CALENDAR") {
      const updatedConfig = await updateGoogleCalendarConfig({
        variables: {
          id: userId,
          googleCalendarConfig: calendarConfig,
        },
      });
      newConfig = updatedConfig.data.updateUser.googleCalendarConfig;
    }
    if (type === "OUTLOOK_CALENDAR") {
      const updatedConfig = await updateIntegrationConfig({
        variables: {
          id: integrationId,
          config: calendarConfig,
          userId,
        },
      });
      newConfig = updatedConfig.data.updateIntegration.config;
    }

    if (entryTypesChanged && connectionStatus === "CONNECTED") {
      await resyncCalendars();
    }

    setCalendarType(newConfig.calendarType);
    setEntryTypes(newConfig.entryTypes);
    setShareCalendar(newConfig.shareCalendar);
    setShareTypes(newConfig.shareTypes);
    if (
      type === "GOOGLE_CALENDAR" &&
      calendarType === "SOON_CALENDAR" &&
      googleCalendarConfig?.calendarType === "MY_CALENDAR"
    ) {
      await createGoogleCalendar({
        variables: {
          userId,
        },
      });
    }
  }

  function handleEntryTypes(type) {
    let newEntryTypes;
    if (entryTypes.includes(type)) {
      newEntryTypes = entryTypes.filter((t) => t !== type);
    } else {
      newEntryTypes = [...entryTypes, type];
    }
    setEntryTypes(newEntryTypes.sort());
  }

  function handleShareTypes(type) {
    let newShareTypes;
    if (shareTypes.includes(type)) {
      newShareTypes = shareTypes.filter((t) => t !== type);
    } else {
      newShareTypes = [...shareTypes, type];
    }
    setShareTypes(newShareTypes.sort());
  }

  let icon;
  let name;
  if (type === "GOOGLE_CALENDAR") {
    icon = GoogleCalendarIcon;
    name = "Google Calendar";
  }
  if (type === "OUTLOOK_CALENDAR") {
    icon = OutlookCalendarIcon;
    name = "Outlook Calendar";
  }

  let changes;
  if (config) {
    if (calendarType !== config.calendarType) {
      changes = true;
    }

    if (config.entryTypes) {
      let entryTypesChanged = checkIfEntryTypesChanged(
        entryTypes,
        config.entryTypes
      );
      if (entryTypesChanged) {
        changes = true;
      }
    }
    if (config.shareTypes && shareTypes.length !== config.shareTypes.length) {
      changes = true;
    }
    if (shareCalendar !== config.shareCalendar) {
      changes = true;
    }
  }

  let firstText;
  let secondText;
  if (connectionStatus === "CONNECTED") {
    secondText = `Synced ${Moment(lastSynced).fromNow()}`;
  }
  if (
    connectionStatus === "CONNECTION_LOST" ||
    connectionStatus === "NOT_ENOUGH_SCOPES"
  ) {
    secondText = "Re-authorization required";
  }

  if (
    connectionStatus === "CONNECTED" ||
    connectionStatus === "CONNECTION_LOST" ||
    connectionStatus === "NOT_ENOUGH_SCOPES"
  ) {
    firstText = (
      <div
        css={css`
          float: left;
          width: 100%;
        `}
      >
        <div
          css={css`
            float: left;
            max-width: 338px;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {emailConnected}
        </div>
        <div
          css={css`
            float: left;
          `}
          className="tooltip"
        >
          <span
            css={css`
              width: 8px;
              height: 8px;
              background: ${connectionStatus === "CONNECTED"
                ? colors.green
                : colors.red};
              border-radius: 8px;
              margin-left: 5px;
              display: inline-block;
            `}
          />
          <span
            className="tooltiptext"
            css={css`
              margin-left: -58px !important;
              margin-top: 1px !important;
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              text-align: left;
              ::after {
                left: 56px;
              }
            `}
          >
            {connectionStatus === "CONNECTED" ? "Connected" : "Disconnected"}
          </span>
        </div>
      </div>
    );
  }

  if (connectionStatus === "NO_CONNECTION") {
    firstText = name;
    secondText = "By Soon";
  }

  const isMSTeams = import.meta.env.VITE_APP_ISMSTEAMSDOMAIN;

  return (
    <div>
      <ModalContainer customStyle={{ position: "fixed", zIndex: 11 }}>
        <ModalHeader
          leftIcon={
            connectionStatus === "CONNECTED" && {
              icon: "bi_music-repeat",
              tooltip: "Re-sync",
              disabled: isTimedOut,
              action: async () => {
                if (!isTimedOut) {
                  setIsTimedOut(true);
                  const promise = resyncCalendars();
                  toast.promise(promise, {
                    loading: "Loading",
                    success: "Re-sync started",
                    error: "Error while re-syncing",
                  });
                }
              },
            }
          }
          title="Calendar settings"
          closeModal={closeModal}
        />

        <div
          css={css`
            float: left;
            width: 100%;
            padding: 24px 16px;
            max-height: 510px;
            overflow-y: scroll;
            overflow-x: hidden;
            box-sizing: border-box;
            ::-webkit-scrollbar {
              display: none;
            }
            @media (max-width: 500px) {
              height: calc(100% - 113px);
              max-height: none;
            }
            @media (max-height: 650px) {
              max-height: calc(100% - 114px);
            }
          `}
        >
          <ModalProfileCard
            iconImage={<img src={icon} width={36} height={36} />}
            firstText={firstText}
            secondText={secondText}
          />
          <ModalDescription
            description={
              <span>
                Automatically sync all your events, activities, and leave
                requests with your {name} account in real-time and share your
                calendar to help admins prevent scheduling conflicts.{" "}
                <a
                  href={`https://www.soon.works/integrations/${name
                    .toLowerCase()
                    .replace(" ", "-")}`}
                  target="_blank"
                >
                  Learn more
                </a>
              </span>
            }
          />
          <ModalItem
            name="Calendar type"
            description="Select to which calendar Soon should be synced to."
          >
            <div
              css={css`
                float: left;
                width: 100%;
                margin-bottom: 24px;
                line-height: 20px;
              `}
            >
              <CheckBox
                label={
                  <span>
                    My calendar{" "}
                    <ToolTip
                      text={`Events, activities, and leave requests that are created in Soon will be copied to your primary ${name}. This is your default calendar and can be used if you want everything in one place and/or if you want other people to have visibility into your schedule.`}
                      customStyle={{ width: 240, top: 23, left: -13 }}
                      customArrowStyle={{ left: 17 }}
                    />
                  </span>
                }
                active={calendarType === "MY_CALENDAR"}
                handleClick={() => setCalendarType("MY_CALENDAR")}
                rounded
              />
            </div>
            <div
              css={css`
                float: left;
                width: 100%;
              `}
            >
              <CheckBox
                label={
                  <span>
                    Seperate Soon calendar{" "}
                    <ToolTip
                      text={`Soon will create a separate calendar called "Soon Calendar". Events, activities, and leave requests will be copied from Soon to the separate calendar rather than to your primary calendar.`}
                      customStyle={{ width: 240, top: 23, left: -45 }}
                      customArrowStyle={{ left: 49 }}
                    />
                  </span>
                }
                active={calendarType === "SOON_CALENDAR"}
                handleClick={() => setCalendarType("SOON_CALENDAR")}
                rounded
              />
            </div>
          </ModalItem>
          <ModalItem
            name="Entry types"
            description="Select what to sync from Soon to your calendar."
          >
            <div
              css={css`
                float: left;
                width: 100%;
                margin-bottom: 24px;
                line-height: 20px;
              `}
            >
              <CheckBox
                label={
                  <span>
                    Events{" "}
                    <ToolTip
                      text="Sync all your events with the accepted and assigned status to your calendar."
                      customStyle={{ width: 240, top: 23, left: -13 }}
                      customArrowStyle={{ left: 17 }}
                    />
                  </span>
                }
                active={entryTypes.includes("EVENTS")}
                handleClick={() => handleEntryTypes("EVENTS")}
              />
            </div>
            <div
              css={css`
                float: left;
                width: 100%;
                margin-bottom: 24px;
              `}
            >
              <CheckBox
                label={
                  <span>
                    Activities{" "}
                    <ToolTip
                      text="Sync all your activities that are part of events with the accepted and assigned status to your calendar."
                      customStyle={{ width: 240, top: 23, left: -13 }}
                      customArrowStyle={{ left: 17 }}
                    />
                  </span>
                }
                active={entryTypes.includes("ACTIVITIES")}
                handleClick={() => handleEntryTypes("ACTIVITIES")}
              />
            </div>
            <div
              css={css`
                float: left;
                width: 100%;
              `}
            >
              <CheckBox
                label={
                  <span>
                    Leave{" "}
                    <ToolTip
                      text="Sync all your leave requests with the approved status to your calendar."
                      customStyle={{ width: 240, top: 23, left: -13 }}
                      customArrowStyle={{ left: 17 }}
                    />
                  </span>
                }
                active={entryTypes.includes("LEAVES")}
                handleClick={() => handleEntryTypes("LEAVES")}
              />
            </div>
          </ModalItem>
          <ModalItem
            name="Share calendar"
            description="Show all your calendar events in the Workload section of the app to help admins prevent scheduling conflicts."
            switchActive={shareCalendar}
            handleSwitch={() => {
              if (shareCalendar) {
                setShareCalendar(!shareCalendar);
                setShareTypes([]);
              } else {
                setModalCalendarSharing(true);
              }
            }}
          >
            {shareCalendar && (
              <div>
                <div
                  css={css`
                    float: left;
                    width: 100%;
                    margin-bottom: 24px;
                    line-height: 20px;
                  `}
                >
                  <CheckBox
                    label={
                      <span>
                        Private events{" "}
                        <ToolTip
                          text="Share private events and allow people to only see the event time. Other event details won't be shared."
                          customStyle={{ width: 240, top: 23, left: -13 }}
                          customArrowStyle={{ left: 17 }}
                        />
                      </span>
                    }
                    active={shareTypes.includes("PRIVATE_EVENTS")}
                    handleClick={() => handleShareTypes("PRIVATE_EVENTS")}
                  />
                </div>
                <div
                  css={css`
                    float: left;
                    width: 100%;
                    margin-bottom: 24px;
                  `}
                >
                  <CheckBox
                    label={
                      <span>
                        Unconfirmed events{" "}
                        <ToolTip
                          text="Share events for which you haven't confirmed attendance yet."
                          customStyle={{ width: 240, top: 23, left: -45 }}
                          customArrowStyle={{ left: 49 }}
                        />
                      </span>
                    }
                    active={shareTypes.includes("UNCONFIRMED_EVENTS")}
                    handleClick={() => handleShareTypes("UNCONFIRMED_EVENTS")}
                  />
                </div>
                <div
                  css={css`
                    float: left;
                    width: 100%;
                  `}
                >
                  <CheckBox
                    label={
                      <span>
                        {type === "OUTLOOK_CALENDAR" ? "Away" : "Out of office"}{" "}
                        <ToolTip
                          text={`Share events that are marked as ${
                            type === "OUTLOOK_CALENDAR"
                              ? `"away"`
                              : `"out of office"`
                          }.`}
                          customStyle={{ width: 240, top: 23, left: -13 }}
                          customArrowStyle={{ left: 17 }}
                        />
                      </span>
                    }
                    active={shareTypes.includes("OUT_OF_OFFICE")}
                    handleClick={() => handleShareTypes("OUT_OF_OFFICE")}
                  />
                </div>
              </div>
            )}
          </ModalItem>
        </div>
        <div
          css={css`
            position: absolute;
            width: 100%;
            height: 70px;
            bottom: 0;
            border-top: 1px solid #e2e2e2;
            padding-top: 12px;
            padding-left: 16px;
            padding-right: 16px;
            box-sizing: border-box;
            background: white;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          `}
        >
          <div>
            {!["CONNECTION_LOST", "NOT_ENOUGH_SCOPES"].includes(
              connectionStatus
            ) && (
              <Button name="Cancel" theme="grey-border" action={closeModal} />
            )}
            {connectionStatus === "CONNECTED" ? (
              <div>
                {changes ? (
                  <Button
                    customStyle={{ float: "right" }}
                    name={"Update"}
                    theme={"blue-border"}
                    action={handleUpdateCalendarConfig}
                    disabled={false}
                    loading={updateLoading || updateIntegrationLoading}
                  />
                ) : (
                  <Button
                    customStyle={{ float: "right" }}
                    name={"Disable"}
                    theme={"red-border"}
                    action={() => setModal(true)}
                    disabled={false}
                    loading={loading}
                  />
                )}
              </div>
            ) : (
              <div>
                {isMSTeams ? (
                  <a
                    href={import.meta.env.VITE_APP_ISMSTEAMSDOMAIN}
                    target="_blank"
                  >
                    <Button
                      customStyle={{ float: "right" }}
                      name={
                        connectionStatus === "CONNECTION_LOST" ? (
                          <span>
                            Re-authorize{" "}
                            <span className="bi_interface-out-link" />
                          </span>
                        ) : (
                          <span>
                            Connect <span className="bi_interface-out-link" />
                          </span>
                        )
                      }
                      theme={"blue-border"}
                      disabled={false}
                      loading={loading}
                    />
                  </a>
                ) : (
                  <Button
                    customStyle={{ float: "right" }}
                    name={
                      connectionStatus === "CONNECTION_LOST" ||
                      connectionStatus === "NOT_ENOUGH_SCOPES"
                        ? "Re-authorize"
                        : "Connect"
                    }
                    theme={"blue-border"}
                    action={handleConnectCalendar}
                    disabled={false}
                    loading={loading}
                  />
                )}
                {connectionStatus === "CONNECTION_LOST" ||
                connectionStatus === "NOT_ENOUGH_SCOPES" ? (
                  <Button
                    customStyle={{ float: "right", marginRight: 24 }}
                    name={"Disable"}
                    theme={"red-border"}
                    action={() => setModal(true)}
                    disabled={false}
                    loading={loading}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </ModalContainer>
      {modal && (
        <Modal
          title="Disable integration"
          body={
            <p>Are you sure you want to disable the calendar integration?</p>
          }
          color={colors.red}
          buttonOneName="Cancel"
          buttonTwoName="Disable"
          buttonOneAction={() => setModal(false)}
          buttonTwoAction={handleDisableCalendar}
          buttonOneTheme={"red-border"}
          buttonTwoTheme={"red"}
          customStyle={{ position: "fixed", zIndex: 13 }}
          customLayerStyle={{ zIndex: 12 }}
          loading={loading}
          handleFocus={() => setModal(false)}
        />
      )}
      {modalCalendarSharing && (
        <Modal
          title="Enable calendar sharing"
          body={
            <p>Are you sure you want to share your calendar with your team?</p>
          }
          color={colors.blue}
          buttonOneName="Cancel"
          buttonTwoName="Enable"
          buttonOneAction={() => setModalCalendarSharing(false)}
          buttonTwoAction={() => {
            setShareCalendar(true);
            setShareTypes([
              "OUT_OF_OFFICE",
              "PRIVATE_EVENTS",
              "UNCONFIRMED_EVENTS",
            ]);
            setModalCalendarSharing(false);
          }}
          buttonOneTheme={"grey-border"}
          buttonTwoTheme={"blue"}
          customStyle={{ position: "fixed", zIndex: 13 }}
          customLayerStyle={{ zIndex: 12 }}
          loading={loading}
          handleFocus={() => setModal(false)}
        />
      )}
    </div>
  );
}

const UPDATE_CALENDAR_CONFIG = gql`
  mutation($id: ID!, $googleCalendarConfig: Json) {
    updateUser(id: $id, googleCalendarConfig: $googleCalendarConfig) {
      id
      googleCalendarConfig
    }
  }
`;

const UPDATE_INTEGRATION_CONFIG = gql`
  mutation($id: ID, $userId: ID, $config: Json) {
    updateIntegration(id: $id, userId: $userId, config: $config) {
      id
      config
    }
  }
`;

const DISABLE_GOOGLE_CALENDAR = gql`
  mutation($userId: ID!) {
    disableGoogleCalendar(userId: $userId)
  }
`;

const CREATE_GOOGLE_CALENDAR = gql`
  mutation($userId: ID!) {
    createSoonGoogleCalendar(userId: $userId)
  }
`;

const DISABLE_OUTLOOK_CALENDAR = gql`
  mutation($userId: ID!) {
    disableOutlookCalendar(userId: $userId)
  }
`;

const CREATE_OUTLOOK_CALENDAR = gql`
  mutation($userId: ID!) {
    createSoonOutlookCalendar(userId: $userId)
  }
`;

const RESYNC_CALENDARS = gql`
  mutation {
    resyncCalendars
  }
`;
