import React from "react";
import "./uploadImage.css";
import { uploadFile } from "../../helpers/functions";
import loader from "../../assets/images/loader.svg";
import { handleAPIError } from "../../helpers/handleErrors";
import OutsideAlerter from "../clickOutside/OutsideAlerter";


const UploadMenu = ({ handleFile, handleImage }) => {
  return (
    <div className="upload-image">
      <ul>
        <li>
          <label htmlFor="uploadImage">Update</label>
          <input
            autoComplete="off"
            id="uploadImage"
            type="file"
            style={{ display: "none" }}
            onChange={handleFile}
            accept="image/x-png,image/gif,image/jpeg"
          />
        </li>
        <li
          onClick={(e) => {
            e.stopPropagation();
            handleImage();
          }}
        >
          Remove
        </li>
      </ul>
    </div>
  );
};

const checkType = (type) => {
  if (type === "image/jpeg" || type === "image/png" || type === "image/gif") {
    return true;
  } else {
    return false;
  }
};

class UploadImage extends React.Component {
  state = {
    menu: false,
    loading: false,
  };

  handleFile = (e) => {
    this.props.action && this.props.action();
    const { preset } = this.props;
    this.setState({ menu: false, loading: true });
    const file = e.target.files[0];
    if (file.size < 8000000) {
      uploadFile(file, preset)
        .then((result) => {
          console.log(result);
          this.setState({ loading: false });
          this.props.handleImage(result, "upload");
        })
        .catch((err) => {
          this.setState({ loading: false });
          handleAPIError(err, {});
          const error = "Something went wrong. Please try again.";
          this.props.handleError(error);
        });
    } else {
      this.setState({ loading: false });
      const error =
        "The selected icon file is too large. The maximum allowed file size is 8MB";
      this.props.handleError(error);
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.props.action && this.props.action();
    const { preset } = this.props;
    acceptedFiles.forEach((f) => {
      if (f.size < 8000000 && checkType(f.type)) {
        uploadFile(f, preset)
          .then((result) => {
            this.props.handleImage(result, "upload");
          })
          .catch((err) => {
            handleAPIError(err, {});
            const error = "Something went wrong. Please try again.";
            this.props.handleError(error);
          });
      } else {
        const error =
          "The selected icon file is too large. The maximum allowed file size is 8MB";
        this.props.handleError(error);
      }
    });
  };

  handleRemove = () => {
    this.setState({ menu: false });
    this.props.handleImage("", "remove");
  };

  handleMenu = (menu) => {
    this.setState(function(prevState, props) {
      return { [menu]: !prevState[menu] };
    });
  };

  render() {
    return (
      <div
        style={this.props.uploadStyle}
        onClick={() => this.setState({ menu: true })}
      >
        {this.state.menu ? (
          <OutsideAlerter action={() => this.setState({ menu: false })}>
            <UploadMenu
              handleFocus={this.handleMenu}
              handleFile={this.handleFile}
              handleImage={this.handleRemove}
              name="menu"
            />
          </OutsideAlerter>
        ) : (
          ""
        )}

        {!this.props.image && this.props.placeholder ? (
          this.state.loading ? (
            <img width="45px" height="45px" src={loader} alt="Loading" />
          ) : (
            this.props.placeholder
          )
        ) : this.state.loading ? (
          <img width="15px" height="15px" src={loader} alt="Loading" />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default UploadImage;
