import React from "react";
import { css, select as $ } from "glamor";
import Button from "./Button";
import { colors } from "../../helpers/styles";
import MessageModal from "./MessageModal";

export default function Error({
  errorId,
  paragraphOne,
  buttonOne,
  buttonTwo,
  layerStyle,
}) {
  return (
    <>
      <div
        {...css(
          {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          },
          layerStyle
        )}
      >
        <MessageModal
          icon="bi_web-bug"
          title="Something went wrong"
          message={
            <>
              <p>
                {paragraphOne
                  ? paragraphOne
                  : "While we check things on our end, refreshing the page usually gets you going again."}
              </p>
              <p>
                However, if the problem persists hit the "Report Bug" button or
                contact us at <u>support@soon.works</u>.
              </p>
              {errorId && (
                <p>
                  When contacting us via email, provide the following code for
                  faster issue tracking:
                </p>
              )}

              {errorId && (
                <div
                  {...css({
                    float: "left",
                    width: "100%",
                    height: 26,
                    padding: 4,
                    boxSizing: "border-box",
                    marginTop: -12,
                    marginBottom: 24,
                    background: "rgba(0, 0, 0, 0.05)",
                    borderRadius: 3,
                    fontSize: 14,
                    color: "rgba(0,0,0,0.54)",
                    cursor: "pointer",
                    lineHeight: "initial",
                    overflowX: "auto",
                  })}
                  onClick={() => {
                    let textArea = document.createElement("textarea");
                    textArea.innerText = errorId;
                    document.body.appendChild(textArea);
                    textArea.select();
                    document.execCommand("copy");
                    textArea.remove();
                  }}
                >
                  {errorId}
                </div>
              )}
            </>
          }
          buttonOne={{ name: "Report Bug", action: buttonOne.action }}
          buttonTwo={{ name: "Refresh", action: buttonTwo.action }}
        />
      </div>
    </>
  );
}
