import { useEffect, lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import Moment from "moment-timezone";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ApolloProvider, ApolloClient, ApolloLink } from "@apollo/client";
import { HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import "./index.css";
import "normalize.css";
import "./components/app.css";
import "./assets/css/budicon.css";
import "./assets/css/fontello.css";
import "react-loading-skeleton/dist/skeleton.css";

import retry from "./helpers/retryLazyLoading.js";

const ErrorBoundary = lazy(() =>
  retry(() => import("./components/errorBoundary/ErrorBoundary"))
);
const Signin = lazy(() =>
  retry(() => import("./components/authentication/SignIn"))
);
const Forgot = lazy(() =>
  retry(() => import("./components/authentication/Forgot"))
);
const Reset = lazy(() =>
  retry(() => import("./components/authentication/Reset"))
);
const Create = lazy(() =>
  retry(() => import("./components/authentication/Create"))
);
const Invite = lazy(() =>
  retry(() => import("./components/authentication/Invite"))
);
const Deactivated = lazy(() =>
  retry(() => import("./components/authentication/Deactivated"))
);
const Verify = lazy(() =>
  retry(() => import("./components/authentication/Verify"))
);
const SSOToken = lazy(() =>
  retry(() => import("./components/authentication/SSOToken"))
);
const SSO = lazy(() => retry(() => import("./components/authentication/SSO")));
const AddToHomeScreen = lazy(() =>
  retry(() => import("./components/toolBox/AddToHomeScreen"))
);
const Success = lazy(() =>
  retry(() => import("./components/authentication/Success"))
);
const Azure = lazy(() =>
  retry(() => import("./components/authentication/Azure"))
);

import App from "./components/App";

import { isExpired } from "./helpers/tokenHandler";

import { bootIntercom } from "./helpers/functions";
import { ErrorFallback } from "./components/errorBoundary/ErrorFallback.js";
import ReloadPrompt from "./components/toolBox/ReloadPrompt.js";

// import SoonImproved from "./components/toolBox/SoonImproved";

if (import.meta.env.NODE_ENV === "production") {
  // console.log("sentry", import.meta.env.VITE_APP_SENTRY_DSN);
  try {
    Sentry.init({ dsn: import.meta.env.VITE_APP_SENTRY_DSN });
  } catch (err) {
    console.log(err);
  }
} else if (import.meta.env.VITE_APP_SENTRY_DSN) {
  // THIS IS FOR TESTING APOLLO PURPOSES ONLY
  try {
    Sentry.init({ dsn: import.meta.env.VITE_APP_SENTRY_DSN });
  } catch (err) {
    console.log(err);
  }
}

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_APP_GRAPHCOOL_URI + "/graphql",
});

const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("graphcoolToken");
  const authorizationHeader = token ? `Bearer ${token}` : null;
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
    },
  });
  return forward(operation);
});

const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

export const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          activities: {
            merge(existing = [], incoming) {
              return incoming;
              // this part of code is depends what you actually need to do, in my
              // case i had to save my incoming data as single object in cache
            },
          },
          events: {
            merge(existing = [], incoming) {
              return incoming;
              // this part of code is depends what you actually need to do, in my
              // case i had to save my incoming data as single object in cache
            },
          },
          leaves: {
            merge(existing = [], incoming) {
              return incoming;
              // this part of code is depends what you actually need to do, in my
              // case i had to save my incoming data as single object in cache
            },
          },
        },
      },
      CalendarUsers: {
        fields: {
          calendarEvents: {
            merge(existing = [], incoming, { variables, readField }) {
              const dateTime = variables.dateTime;
              const incomingIds = incoming.map((item) => readField("id", item));
              const existingToStay = existing.filter((item) => {
                const startTime = readField("startTime", item);
                const id = readField("id", item);

                // if a new version is in the incoming, remove from existing
                if (incomingIds.includes(id)) {
                  return false;
                }

                // if there are items outside of the range that was queried
                // keep em
                if (
                  Moment(startTime).isBefore(dateTime) ||
                  Moment(startTime).isSameOrAfter(
                    Moment(dateTime).add(1, "week")
                  )
                ) {
                  return true;
                } else {
                  return false;
                }
              });

              return [...existingToStay, ...incoming];
            },
          },
        },
      },
      Board: {
        fields: {
          events: {
            merge(existing, incoming, { readField, variables }) {
              if (existing && existing.length > 0) {
                // THIS LOGIC KEEPS EVENTS IN THE CACHE WHEN
                // TRAVERSING BETWEEN WEEKS ON THE BOARD
                // ELSE THEY WILL DISAPPEAR BECAUSE THE NEW
                // QUERY WILL NOT HAVE THEM BECAUSE IT USES
                // DIFFERENT DATE VARIABLES FOR A NEXT OR PREVIOUS WEEK
                const incomingIds = incoming.map((item) =>
                  readField("id", item)
                );

                const existingToStay = existing.filter((item) => {
                  const id = readField("id", item);
                  const existingStartTime = readField("startTime", item);

                  // if a new version is in the incoming, remove from existing
                  if (incomingIds.includes(id)) {
                    return false;
                  } else {
                    // we only do this when the existing is not within the current week
                    // because then it should be in the incoming and is probably deleted
                    let datesToFilter = variables.dates;
                    if (datesToFilter && datesToFilter.length > 7) {
                      datesToFilter = datesToFilter.slice(1, -1);
                    }

                    if (
                      datesToFilter &&
                      datesToFilter.includes(
                        Moment(existingStartTime).format("YYYY-MM-DD")
                      )
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                });

                return [...incoming, ...existingToStay];
              } else {
                return incoming;
              }
            },
          },
          templates: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Team: {
        fields: {
          leaves: {
            merge(existing, incoming, { readField, variables }) {
              // THIS LOGIC LEAVES EVENTS IN THE CACHE WHEN
              // TRAVERSING BETWEEN WEEKS ON THE BOARD
              // ELSE THEY WILL DISAPPEAR BECAUSE THE NEW
              // QUERY WILL NOT HAVE THEM BECAUSE IT USES
              // DIFFERENT DATE VARIABLES FOR A NEXT OR PREVIOUS WEEK

              if (existing && existing.length > 0) {
                const incomingIds = incoming.map((item) =>
                  readField("id", item)
                );
                const existingToStay = existing.filter((item) => {
                  const id = readField("id", item);

                  // if a new version is in the incoming, remove from existing
                  if (incomingIds.includes(id)) {
                    return false;
                  } else {
                    // unless the existing is deleted
                    // if the existing is not in the incoming
                    // and the variables dates should have included it
                    // then it is deleted, so should not be returned
                    const existingStartTime = readField("startTime", item);

                    if (
                      Moment(existingStartTime).isBetween(
                        variables.startDate,
                        variables.endDate,
                        undefined,
                        "[]"
                      )
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                });

                return [...incoming, ...existingToStay];
              } else {
                return incoming;
              }
            },
          },
        },
      },
      User: {
        fields: {
          availabilityBases: {
            merge(existing, incoming, { readField, variables }) {
              if (existing && existing.length > 0) {
                const incomingIds = incoming.map((item) =>
                  readField("id", item)
                );

                const existingToStay = existing.filter((item) => {
                  const id = readField("id", item);

                  // if a new version is in the incoming, remove from existing
                  if (incomingIds.includes(id)) {
                    return false;
                  } else {
                    return true;
                  }
                });
                return [...incoming, ...existingToStay];
              } else {
                return incoming;
              }
            },
          },
        },
      },
      Request: {
        fields: {
          activities: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});

function signedIn(Component, props) {
  const token = localStorage.getItem("graphcoolToken");
  if (!token) {
    return (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  }
  if (isExpired(token)) {
    return <Component {...props} />;
  }
  return <Redirect to="/" />;
}

function IntercomWrap({ children }) {
  useEffect(() => {
    bootIntercom({
      data: {
        app_id: import.meta.env.VITE_APP_INTERCOM_APPID,
        api_base: "https://api-iam.intercom.io",
      },
    });
  }, []);
  return children;
}

function Maintenance() {
  return <div>We're doing some maintenance, back Soon!</div>;
}

// use this to set the app to maintenance mode
const isMaintenance = import.meta.env.VITE_APP_IS_MAINTENANCE;

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <>
    <Helmet>
      <title>Soon | Workforce Management Software</title>
      <meta
        name="description"
        content="Maximize your workforce with Soon's simple scheduling software. Schedule shifts, handle leave, and streamline daily tasks to match your operations."
      />
    </Helmet>
    <IntercomWrap>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Toaster
            position="bottom-center"
            gutter={12}
            toastOptions={{
              style: {
                fontSize: 14,
                color: "rgba(0,0,0,0.87)",
                fontWeight: 500,
                border: "1px solid #E2E2E2",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              },
              success: {
                iconTheme: {
                  primary: "#1ED25A !important",
                },
              },
              error: {
                iconTheme: {
                  primary: "#FF3246 !important",
                },
              },
            }}
            containerStyle={{
              bottom: 20,
            }}
          />
          <AddToHomeScreen />
          <ReloadPrompt />
          <ApolloProvider client={client}>
            <Router>
              <Switch>
                <Route
                  exact
                  path="/verify/:verificationToken"
                  render={(props) => {
                    return <Verify {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/forgot"
                  render={(props) => signedIn(Forgot, props)}
                />
                <Route
                  exact
                  path="/reset/:id"
                  render={(props) => signedIn(Reset, props)}
                />
                <Route
                  exact
                  path="/create"
                  render={(props) => signedIn(Create, props)}
                />
                <Route
                  exact
                  path="/pitchground"
                  render={(props) => {
                    const pitchgroundProps = { ...props, pitchground: true };
                    return signedIn(Create, pitchgroundProps);
                  }}
                />
                <Route
                  exact
                  path="/azure"
                  render={(props) => {
                    const searchParams = new URLSearchParams(
                      window.location.search
                    );
                    const token = searchParams.get("token");
                    const azureProps = { ...props, azure: true, token };
                    return <Azure {...azureProps} />;
                  }}
                />
                <Route
                  exact
                  path="/appsumo"
                  render={(props) => {
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    let appsumoMail = urlParams.get("e");
                    const customerId = urlParams.get("c");
                    const source = urlParams.get("source");

                    if (appsumoMail.includes(" ")) {
                      appsumoMail = appsumoMail.replace(" ", "+");
                    }

                    if (source !== "appsumo" || !appsumoMail || !customerId) {
                      return <Redirect to="/create" />;
                    }

                    const appsumoProps = {
                      ...props,
                      appsumo: true,
                      appsumoMail,
                      customerId,
                      source,
                    };
                    return signedIn(Create, appsumoProps);
                  }}
                />
                <Route
                  exact
                  path="/signin"
                  render={(props) => signedIn(Signin, props)}
                />
                <Route exact path="/sso" render={(props) => <SSO />} />
                <Route exact path="/sso/success" component={Success} />
                <Route
                  exact
                  path="/sso/:token"
                  render={(props) => signedIn(SSOToken, props)}
                />
                <Route
                  exact
                  path="/invite/:id"
                  render={(props) => signedIn(Invite, props)}
                />
                <Route
                  exact
                  path="/invite/:id/:inviteLink"
                  render={(props) => signedIn(Invite, props)}
                />
                <Route
                  exact
                  path="/deactivated"
                  render={(props) => {
                    // check to see if there is token in localstorage
                    const token = localStorage.getItem("graphcoolToken");
                    if (!token) {
                      // if no token, let the user sign in and get one
                      return <Redirect to={"/signin"} />;
                    } else {
                      // if token is expired, remove it, let the user get a new one
                      if (isExpired(token)) {
                        localStorage.removeItem("graphcoolToken");
                        return <Redirect to={"/signin"} />;
                      } else {
                        // if there is a non-expired token, let the user go to the app
                        return <Deactivated {...props} />;
                      }
                    }
                  }}
                />
                <Route
                  path="/"
                  render={(props) => {
                    // check to see if there is token in localstorage
                    const token = localStorage.getItem("graphcoolToken");
                    if (!token) {
                      // if no token, let the user sign in and get one
                      return <Redirect to={"/signin"} />;
                    } else {
                      // if token is expired, remove it, let the user get a new one
                      if (isExpired(token)) {
                        localStorage.removeItem("graphcoolToken");
                        return <Redirect to={"/signin"} />;
                      } else {
                        // if there is a non-expired token, let the user go to the app
                        return (
                          <DndProvider backend={HTML5Backend}>
                            <App {...props} />
                          </DndProvider>
                        );
                      }
                    }
                  }}
                />
              </Switch>
            </Router>
          </ApolloProvider>
        </ErrorBoundary>
      )}
    </IntercomWrap>
  </>
);
