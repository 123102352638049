/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";

import { colors, getStatusBar } from "../../helpers/styles";

import SwitchActive from "../../assets/images/switch-active.png";
import SwitchInactive from "../../assets/images/switch-inactive.png";
import MoreOptions from "./MoreOptions";
import ToolTip from "./ToolTip";

export function ModalContainer({ children, customStyle }) {
  return (
    <div
      className="noselect"
      css={css`
        position: absolute;
        width: 432px;
        max-height: 624px;
        background: white;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 6;
        @media (max-width: 500px) {
          height: 100%;
          border-radius: 0px;
          width: 100%;
          max-height: 100%;
        }
        @media (max-height: 650px) {
          top: 24px;
          bottom: 24px;
        }
      `}
      style={customStyle}
    >
      {children}
    </div>
  );
}

export function ModalHeader({
  children,
  title,
  closeModal,
  backModal,
  leftIcon,
  tooltip,
  options,
  statusBar,
  customStyle,
}) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        height: 44px;
        line-height: 44px;
        text-align: center;
        position: relative;
        border-bottom: 1px solid #e2e2e2;
        box-sizing: border-box;
        background: #f9f9f9;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        @media (max-width: 500px) {
          border-radius: 0px;
        }
      `}
      style={customStyle}
    >
      {children ? (
        children
      ) : (
        <div>
          {backModal && (
            <span
              css={css`
                position: absolute;
                left: 14px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.54);
                font-size: 17px;
                line-height: 47px;
                :hover {
                  color: rgba(0, 0, 0, 0.87);
                }
              `}
              className="bi_interface-arrow-left"
              onClick={backModal}
            />
          )}
          {statusBar && (
            <div
              css={css`
                position: absolute;
                left: 14px;
              `}
            >
              {getStatusBar(statusBar)}
            </div>
          )}
          {tooltip && (
            <div
              css={css`
                position: absolute;
                left: 14px;
              `}
            >
              <ToolTip
                customStyle={tooltip.customStyle}
                customArrowStyle={tooltip.customArrowStyle}
                text={tooltip.text}
              />
            </div>
          )}
          {leftIcon && (
            <div
              className="tooltip"
              style={{ position: "absolute", left: 0, cursor: "default" }}
            >
              <span
                css={css`
                  position: absolute;
                  left: 14px;
                  cursor: ${leftIcon.disabled ? "default" : "pointer"};
                  color: ${leftIcon.disabled
                    ? "rgba(0, 0, 0, 0.38)"
                    : "rgba(0, 0, 0, 0.54)"};
                  font-size: 18px;
                  line-height: 47px;
                  :hover {
                    color: ${leftIcon.disabled
                      ? "rgba(0, 0, 0, 0.38)"
                      : "rgba(0, 0, 0, 0.87)"};
                  }
                `}
                className={leftIcon.icon}
                onClick={leftIcon.action}
              />
              {!leftIcon.disabled && (
                <span
                  className="tooltiptext"
                  css={css`
                    left: auto !important;
                    right: -69px !important;
                    top: 40px !important;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 17px;
                    text-align: left;
                    ::after {
                      left: 18px;
                    }
                  `}
                >
                  {leftIcon.tooltip}
                </span>
              )}
            </div>
          )}
          {title}
          {options && options.length > 0 && (
            <MoreOptions
              options={options}
              customStyle={{
                position: "absolute",
                top: 0,
                right: 53,
                textAlign: "left",
              }}
              toolTipText="More options"
            />
          )}
          {closeModal && (
            <span
              css={css`
                float: right;
                position: absolute;
                right: 14px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.54);
                :hover {
                  color: rgba(0, 0, 0, 0.87);
                }
              `}
              className="bi_interface-cross"
              onClick={closeModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

export function ModalProfileCard({
  name,
  icon,
  iconImage,
  firstText,
  secondText,
  background,
}) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        height: 37px;
        margin-bottom: 12px;
      `}
    >
      <div
        css={css`
          float: left;
          width: 36px;
          height: 36px;
          border-radius: 5px;
          background: ${background ? background : ""};
          color: white;
          margin-right: 12px;
          line-height: 36px;
          text-align: center;
          font-size: 21px;
        `}
      >
        {icon && <span className={icon} />}
        {iconImage && iconImage}
      </div>
      <div
        css={css`
          float: left;
          font-family: Museo Sans;
        `}
      >
        <span
          css={css`
            line-height: 20px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.87);
          `}
        >
          {firstText}
        </span>
        <br />
        <span
          css={css`
            line-height: 17px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.54);
            font-weight: 300;
          `}
        >
          {secondText}
        </span>
      </div>
    </div>
  );
}

export function ModalDescription({ description }) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        line-height: 17px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 300;
        margin-bottom: 24px;
        a {
          color: ${colors.blue};
          font-weight: 500;
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        }
      `}
    >
      {description}
    </div>
  );
}

export function ModalItem({
  name,
  description,
  switchActive,
  handleSwitch,
  children,
}) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        line-height: 17px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.87);
        font-weight: 300;
        margin-bottom: 24px;
      `}
    >
      <div
        css={css`
          float: left;
          position: relative;
          width: 100%;
          line-height: 20px;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 6px;
        `}
      >
        {name}

        {handleSwitch && (
          <div
            css={css`
              position: absolute;
              right: 0;
              top: 0;
            `}
          >
            <img
              onClick={handleSwitch}
              src={switchActive ? SwitchActive : SwitchInactive}
              width="35"
              height="23"
              alt="Switch"
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
          margin-bottom: 24px;
        `}
      >
        {description}
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
        `}
      >
        {children}
      </div>
    </div>
  );
}
