import React from "react";
import { css, select as $, media } from "glamor";
import { colors } from "../../helpers/styles";
import { Link } from "react-router-dom";
import Avatar from "../avatar/Avatar";
import EmptyState from "../toolBox/EmptyState";
import matchSorter from "match-sorter";
import "./header.css";
import "../board/settings/boardSettings.css";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

class Dropdown extends React.Component {
  state = {
    input: "",
  };

  render() {
    const { handleFocus, currentItem } = this.props;
    const listItems = matchSorter(this.props.items, this.state.input, {
      keys: ["name"],
    }).map((item) => {
      const isCurrent = currentItem && currentItem.id === item.id;
      return (
        <li
          key={item.id}
          {...css(
            {
              float: "left",
              width: "100%",
              height: "30px",
              lineHeight: "24px",
              boxSizing: "border-box",
              cursor: "pointer",
              padding: "3px 6px",
              borderRadius: "3px",
              fontSize: "14px",
              fontWeight: isCurrent ? 500 : 300,
            },
            $(" a", {
              color: isCurrent ? colors.blue : "rgba(0,0,0,0.54)",
            }),
            $(":hover", {
              background: "rgba(0,0,0,0.05)",
              fontWeight: "500",
            })
          )}
        >
          <Link {...css({ float: "left", width: "100%" })} to={item.link}>
            {item.firstName && (
              <div
                onClick={handleFocus}
                {...css({ float: "left", marginRight: "6px" })}
              >
                <Avatar
                  size="small"
                  firstName={item.firstName}
                  lastName={item.lastName}
                  avatar={item.avatar}
                />
              </div>
            )}
            <div
              onClick={handleFocus}
              {...css({
                float: "left",
                width: "calc(100% - 31px)",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              })}
            >
              {item.name}
            </div>
          </Link>
        </li>
      );
    });

    return (
      <OutsideAlerter action={this.props.handleFocus}>
        <div
          {...css(
            {
              position: "absolute",
              maxHeight: "266px",
              width: "288px",
              background: "white",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
              zIndex: "4",
              ...this.props.customStyle,
            },
            media("(max-width: 1023px)", {
              width: "calc(100% - 12px)",
            })
          )}
        >
          <div
            {...css({
              float: "left",
              width: "100%",
              borderBottom: "1px solid #E2E2E2",
              color: "rgba(0,0,0,0.38)",
              padding: "12px",
              paddingBottom: "0px",
              boxSizing: "border-box",
              lineHeight: "20px",
            })}
          >
            <span
              className="bi_interface-search"
              {...css({
                fontSize: "16px",
                marginRight: "6px",
              })}
            />
            <input
              autoComplete="off"
              {...css({
                fontFamily: "Museo Sans",
                width: "calc(100% - 34px)",
                border: "0px",
                height: "20px",
                lineHeight: "20px",
                fontSize: "14px",
                fontWeight: "300",
                marginBottom: "6px",
              })}
              placeholder="Search"
              value={this.state.input}
              onChange={(e) => this.setState({ input: e.target.value })}
            />
          </div>
          {listItems.length > 0 ? (
            <ul
              {...css(
                {
                  listStyle: "none",
                  margin: "0",
                  padding: "6px",
                  maxHeight: "200px",
                  overflowY: "scroll",
                },
                $("::-webkit-scrollbar", {
                  display: "none",
                })
              )}
            >
              {listItems}
            </ul>
          ) : (
            <EmptyState
              title="No results"
              message="No users found matching your search."
              icon="bi_interface-search"
              size="small"
              customStyle={{ border: 0 }}
            />
          )}
        </div>
      </OutsideAlerter>
    );
  }
}

const DropdownMenu = Dropdown;

class HeaderSwitcher extends React.Component {
  state = {
    open: false,
  };

  render() {
    const { currentItem, items } = this.props;
    return (
      <div className="header_board_switcher" {...css({ position: "relative" })}>
        <div
          onClick={() => this.setState({ open: true })}
          className="header_dropdown"
        >
          <div
            className="header_dropdown_left"
            {...css({ fontSize: "14px", width: "100%" })}
          >
            <div
              {...css({
                width: "calc(100% - 18px)",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              })}
            >
              {currentItem.name}
            </div>
            <div
              {...css({
                position: "absolute",
                right: "6px",
                top: 12,
                fontSize: 15,
              })}
            >
              <span className="bi_interface-bottom" />
            </div>
          </div>
          <div className="header_dropdown_right">
            <span className="bi_interface-bottom" />
          </div>
        </div>
        {this.state.open && (
          <DropdownMenu
            items={items}
            currentItem={currentItem}
            handleFocus={() => this.setState({ open: false })}
            customStyle={{ left: 12 }}
          />
        )}
      </div>
    );
  }
}

export default HeaderSwitcher;
