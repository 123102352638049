import React from "react";
import { css, select as $, media } from "glamor";
import { graphql } from "@apollo/client/react/hoc";
import { gql, useMutation } from "@apollo/client";
import { handleAPIError } from "../../helpers/handleErrors";
import { NOTI_COUNT_QUERY } from "../header/Header";
import toast from "react-hot-toast";

export default function NotificationFooter({ userId, markAllRead }) {
  const [handleMarkAllAsRead, { data }] = useMutation(MARK_ALL_READ, {
    refetchQueries: [{ query: NOTI_COUNT_QUERY }],
    awaitRefetchQueries: true,
  });

  function handleMarkAll() {
    const promise = handleMarkAllAsRead({ variables: { userId } })
      .then((r) => {
        markAllRead("true");
      })
      .catch((e) => {
        handleAPIError(e, {});
        console.log(e);
      });

    toast.promise(promise, {
      success: "Marked all read",
      loading: "Loading",
      error: "Error while marking",
    });
  }

  return (
    <div {...css(float, hundred, notificationMeta, notificationFooter)}>
      <span onClick={handleMarkAll}>Mark all read</span>
    </div>
  );
}

const notificationFooter = css(
  {
    position: "absolute",
    bottom: "0",
    borderTop: "1px solid #e2e2e2",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    color: "#0095FF",
    fontSize: "14px",
    height: "40px",
    lineHeight: "40px",
  },
  $(
    "& span",
    {
      cursor: "pointer",
    },
    $(":hover", {
      textDecoration: "underline",
    })
  ),
  media("(max-width: 900px)", {
    fontSize: "16px",
  })
);

const float = css({
  float: "left",
});

const hundred = css({
  width: "100%",
});

const notificationMeta = css(
  {
    height: "44px",
    lineHeight: "44px",
    fontSize: "14px",
    textAlign: "center",
  },
  media("(max-width: 900px)", {
    height: "40px",
    lineHeight: "40px",
    fontSize: "16px",
  })
);

const MARK_ALL_READ = gql`
  mutation($userId: ID!) {
    markAllNotificationsAsRead(userId: $userId) {
      count
    }
  }
`;
