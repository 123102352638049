import { useRegisterSW } from "virtual:pwa-register/react";
import { pwaInfo } from "virtual:pwa-info";
import SoonImproved from "./SoonImproved";

console.log(pwaInfo);

function ReloadPrompt() {
  const reloadSW = "true";

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);

      // Periodic check for updates every hour
      const checkForUpdates = () => {
        console.log("Checking for service worker update...");
        r.update();
      };

      if (reloadSW === "true") {
        r && setInterval(checkForUpdates, 3600 * 1000); // Every hour (3600 seconds)
      } else {
        console.log("SW Registered: " + r);
      }

      // Check for updates when the tab becomes active
      const handleVisibilityChange = () => {
        if (document.visibilityState === "visible") {
          if ("requestIdleCallback" in window) {
            requestIdleCallback(checkForUpdates);
          } else {
            setTimeout(checkForUpdates, 1000);
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  if (needRefresh) {
    return (
      <SoonImproved
        action={() => {
          updateServiceWorker(true);
        }}
        dismiss={close}
      />
    );
  } else {
    return null;
  }
}

export default ReloadPrompt;
