import React from "react";
import HeaderDropdown from "./HeaderDropdown";
import { css } from "glamor";

class BoardSwitcher extends React.Component {
  state = {
    open: false,
  };

  handleDropdown = (action) => {
    if (action === "close") {
      this.setState({ open: false });
    } else if (action === "open") {
      this.setState({ open: true });
    } else {
      return;
    }
  };

  render() {
    const { boardName, boards, boardId } = this.props;
    return (
      <div className="header_board_switcher" {...css({ position: "relative" })}>
        <div
          onClick={() => this.handleDropdown("open")}
          className="header_dropdown"
          {...css({ position: "relative" })}
        >
          <div
            className="header_dropdown_left"
            {...css({
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              width: "calc(100% - 24px)",
              position: "relative",
            })}
          >
            <span>{boardName}</span>
          </div>
          <div {...css({ position: "absolute", right: "6px", fontSize: 15 })}>
            <span className="bi_interface-bottom" />
          </div>
        </div>
        {this.state.open && (
          <HeaderDropdown
            boards={boards}
            boardName={boardName}
            boardId={boardId}
            handleFocus={() => this.handleDropdown("close")}
            name="close"
            match={this.props.match}
          />
        )}
      </div>
    );
  }
}

export default BoardSwitcher;
