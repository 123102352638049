import { jwtDecode } from "jwt-decode";

export function timeInterval(value, intervalType) {
  let seconds = 0;
  switch (intervalType) {
    case "DAY":
      seconds = 24 * 60 * 60;
      break;
    case "WEEK":
      seconds = 7 * 24 * 60 * 60;
      break;
    default:
      seconds = 0;
  }

  return value * seconds;
}

export function isExpired(token) {
  let now = new Date().getTime() / 1000;
  const decodedToken = jwtDecode(token);
  if (!decodedToken) {
    return true;
  }
  let expiredAt = jwtDecode(token).exp;
  return expiredAt < now;
}

export function expiresIn(timeInterval, token) {
  let now = new Date().getTime() / 1000;
  let expiresAt = jwtDecode(token).exp;
  return expiresAt - now < timeInterval;
}
