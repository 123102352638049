import React from "react";
import { css, select as $ } from "glamor";
import { colors } from "../../helpers/styles";
import isLoading from "../../assets/images/loader-white.svg";

const Button = ({
  id,
  name,
  icon,
  theme,
  size,
  customStyle,
  customHoverBorder,
  action,
  disabled,
  loading,
  type,
  iconOnly,
  autoFocus,
  ariaLabel,
  intercomTarget,
}: {
  id?: string;
  name: string;
  icon?: string;
  theme: string;
  size?: string;
  customStyle?: any;
  customHoverBorder?: string;
  action?: Function;
  disabled?: boolean;
  loading?: boolean;
  type?: string;
  iconOnly?: boolean;
  autoFocus?: boolean;
  ariaLabel?: string;
  intercomTarget?: string;
}) => {
  let color;
  let background;
  let border;
  let hoverBorder = customHoverBorder;
  let hoverTextColor;
  switch (theme) {
    case "black":
      color = "white";
      background = "#353535";
      hoverBorder = "#353535";
      break;
    case "black-border":
      color = "#353535";
      background = "white";
      hoverBorder = "#353535";
      break;
    case "blue":
      color = "white";
      background = colors.blue;
      hoverBorder = colors.blue;
      break;
    case "red":
      color = "white";
      background = colors.red;
      hoverBorder = colors.red;
      break;
    case "blue-border":
      color = colors.blue;
      background = "white";
      break;
    case "red-border":
      color = colors.red;
      background = "white";
      break;
    case "green-border":
      color = colors.green;
      background = "white";
      break;
    case "grey-border":
      color = "rgba(0, 0, 0, 0.38)";
      border = "#E2E2E2";
      background = "white";
      hoverTextColor = "rgba(0,0,0,0.38)";
      break;
    case "purple-border":
      color = "#5551ff";
      border = "#5551ff";
      background = "white";
      break;
    case "purple":
      color = "white";
      background = "#5551ff";
      hoverBorder = "#5551ff";
      break;
    default:
      break;
  }

  let lineHeight;
  let paddingLeft;
  let paddingRight;
  let fontSize;
  let height;
  let width;
  let loadingWidth;
  let loadingPaddingTop;
  switch (size) {
    case "small":
      lineHeight = "24px";
      paddingLeft = 8;
      paddingRight = 8;
      fontSize = 14;
      height = 28;
      width = loading && "78.6px";
      loadingWidth = 14;
      loadingPaddingTop = 4;
      break;
    case "medium":
      lineHeight = "29px";
      paddingLeft = 8;
      paddingRight = 8;
      fontSize = 14;
      height = 32;
      width = loading && "78.6px";
      loadingWidth = 20;
      loadingPaddingTop = 4;
      break;
    default:
      lineHeight = "42px";
      paddingLeft = iconOnly ? 12 : 30;
      paddingRight = iconOnly ? 12 : 30;
      fontSize = 16;
      height = 46;
      loadingWidth = 30;
      loadingPaddingTop = 7;
      break;
  }

  return (
    <button
      data-intercom-target={intercomTarget}
      id={id}
      disabled={disabled ? true : loading ? true : false}
      {...css(
        {
          float: "left",
          borderRadius: 3,
          lineHeight,
          paddingLeft,
          paddingRight,
          fontSize,
          fontWeight: 500,
          fontFamily: "Museo Sans",
          boxSizing: "border-box",
          color,
          border: `1px solid ${border ? border : color}`,
          background,
          height,
          width,
        },
        customStyle,
        $(":hover", {
          background: `${border ? border : color}`,
          color: hoverTextColor ? hoverTextColor : background,
          cursor: "pointer",
          borderColor: hoverBorder,
        }),
        $(":disabled", {
          background: `${hoverBorder ? hoverBorder : border ? border : color}`,
          border: `1px solid ${hoverBorder ? hoverBorder : border}`,
          color: hoverBorder ? color : background,
          opacity: 0.5,
          cursor: "default",
        })
      )}
      onClick={(e) => {
        e.stopPropagation();
        action && action();
      }}
      type={type}
      autoFocus={autoFocus}
      arial-label={ariaLabel}
    >
      {icon && !loading && <span className={icon} />}
      {loading ? (
        <div {...css({ height: 44 })}>
          <img
            src={isLoading}
            alt="Loading"
            width={loadingWidth}
            {...css({ paddingTop: loadingPaddingTop })}
          />
        </div>
      ) : (
        !iconOnly && name
      )}
    </button>
  );
};

export default Button;
