import React from "react";
import { css, select as $, media } from "glamor";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import { motion } from "framer-motion";

import switchActive from "../../assets/images/switch-active.png";
import switchInactive from "../../assets/images/switch-inactive.png";
import checkBox from "../../assets/images/check-box.svg";
import checkBoxEmpty from "../../assets/images/check-box-empty.svg";
import { ModalContainer, ModalHeader } from "../toolBox/MenuModal";

const float = css({
  float: "left",
});

const hundred = css({
  width: "100%",
});

const notificationSettings = [
  {
    id: "emailNotifications",
    name: "Email notifications",
    description:
      "Receive emails to get notified about essential updates that involve you.",
    subItems: [
      // {
      //   id: "assigned",
      //   name: "Assigned events",
      //   description: "Email me when assigned to a new event"
      // },
      {
        id: "invited",
        name: "New events",
        description: "Email me when I’m added to a new event",
      },
      {
        id: "updated",
        name: "Updated events",
        description: "Email me when my event is updated",
      },
      {
        id: "cancelled",
        name: "Cancelled events",
        description: "Email me when my event is cancelled",
      },
      {
        id: "cancellation_request",
        name: "Cancel requests",
        description: "Email me about my cancel requests",
      },
      {
        id: "cover_request",
        name: "Cover requests",
        description: "Email me about my cover requests",
      },
      // {
      //   id: "swap_request",
      //   name: "Swap requests",
      //   description: "Email me about my swap requests",
      // },
      {
        id: "leave",
        name: "Leave requests",
        description: "Email me about my leave requests",
      },
    ],
  },
];

export function NotificationSettingsModal({
  emailNotifications,
  userId,
  handleClose,
}) {
  return (
    <div>
      <ModalContainer
        customStyle={{
          position: "fixed",
          color: "rgba(0,0,0,.87)",
          fontSize: 16,
          lineHeight: "initial",
        }}
      >
        <ModalHeader title="Notification settings" closeModal={handleClose} />
        <div
          {...css({
            float: "left",
            width: "100%",
            padding: "24px 16px",
            boxSizing: "border-box",
          })}
        >
          <NotificationSettings
            emailNotifications={emailNotifications}
            userId={userId}
          />
        </div>
      </ModalContainer>
      <div
        {...css({
          position: "fixed",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          background: "rgba(0,0,0,0.2)",
          zIndex: 1,
        })}
        onClick={handleClose}
      />
    </div>
  );
}

class NotificationSettingsWithoutMutations extends React.PureComponent {
  handleUpdateSetting = (id, on) => {
    if (id === "emailNotifications") {
      let emailNotifications;
      if (on) {
        emailNotifications = {
          invited: false,
          assigned: false,
          updated: false,
          cancelled: false,
          cancellation_request: false,
          cover_request: false,
          swap_request: false,
          leave: false,
        };
      } else {
        emailNotifications = {
          invited: true,
          assigned: true,
          updated: true,
          cancelled: true,
          cancellation_request: true,
          cover_request: true,
          swap_request: true,
          leave: true,
        };
      }
      this.props.updateNotificationSetting({
        variables: {
          id: this.props.userId,
          emailNotifications,
        },
      });
      return;
    }
    const setting = !this.props.emailNotifications[id];
    const emailNotifications = Object.assign(
      {},
      this.props.emailNotifications,
      { [id]: setting }
    );
    this.props.updateNotificationSetting({
      variables: {
        id: this.props.userId,
        emailNotifications,
      },
    });
  };
  render() {
    const notificationData = notificationSettings.map((setting) => {
      let on = false;
      const subItems = setting.subItems
        ? setting.subItems.map((s) => {
            const active = this.props[setting.id][s.id];
            if (active) {
              on = true;
            }
            return { ...s, active };
          })
        : [];
      return Object.assign({}, setting, { subItems, on });
    });
    const list = notificationData.map((setting) => {
      const subList =
        setting.subItems.length > 0 &&
        setting.subItems.map((s) => {
          return (
            <motion.li
              key={s.id}
              {...css(float, {
                width: "100%",
                paddingBottom: 12,
                listStyleType: "none",
              })}
              initial={{ marginLeft: -15, opacity: 0 }}
              animate={{ marginLeft: 0, opacity: 1 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
            >
              <div {...css(float, { width: 32 })}>
                <img
                  src={s.active ? checkBox : checkBoxEmpty}
                  height="20"
                  alt={s.active ? "Active" : "Inactive"}
                  onClick={() => this.handleUpdateSetting(s.id)}
                  {...css(
                    { cursor: "pointer" },
                    $(":active", {
                      transform: "scaleX(1.1)",
                    })
                  )}
                />
              </div>
              <div {...css(float, { width: "calc(100% - 32px)" })}>
                <div
                  {...css(float, hundred, {
                    fontSize: 14,
                    lineHeight: "17px",
                    fontWeight: 300
                  })}
                >
                  {s.name}
                </div>
                <div
                  {...css(
                    float,
                    hundred,
                    {
                      fontSize: 12,
                      lineHeight: "17px",
                      fontWeight: 300,
                      color: "rgba(0,0,0,0.54)",
                    },
                    media("(max-width: 900px)", {
                      fontSize: 14,
                    })
                  )}
                >
                  {s.description}
                </div>
              </div>
            </motion.li>
          );
        });
      return (
        <motion.li
          initial={{ marginLeft: -15, opacity: 0 }}
          animate={{ marginLeft: 0, opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          key={setting.id}
          {...css(
            float,
            {
              width: "100%",
              paddingBottom: 12,
            },
            $(":last-child", {
              borderBottom: 0,
            }),
            $(":first-child", {
              paddingTop: 0,
            })
          )}
        >
          <div
            {...css(float, hundred, {
              paddingBottom: 12,
              marginBottom: 12,
            })}
          >
            <div
              {...css(float, hundred, {
                fontSize: 14,
                lineHeight: "17px",
                marginBottom: 6,
                fontWeight: 700,
              })}
            >
              {setting.name}
              <div {...css({ float: "right", height: 20 })}>
                <img
                  src={setting.on ? switchActive : switchInactive}
                  height="23"
                  alt={setting.on ? "Active" : "Inactive"}
                  onClick={() =>
                    this.handleUpdateSetting(setting.id, setting.on)
                  }
                  {...css(
                    { cursor: "pointer" },
                    $(":active", {
                      transform: "scaleX(1.1)",
                    })
                  )}
                />
              </div>
            </div>
            <div
              {...css(float, {
                fontSize: 14,
                lineHeight: "16.8px",
                fontWeight: 300,
                color: "rgba(0,0,0,0.87)",
                width: "100%",
              })}
            >
              {setting.description}
            </div>
          </div>
          {subList && setting.on && (
            <ul {...css(float, hundred, { padding: 0, margin: 0 })}>
              {subList}
            </ul>
          )}
        </motion.li>
      );
    });
    return (
      <ul
        {...css({
          float: "left",
          width: "100%",
          listStyleType: "none",
          margin: 0,
          padding: 0,
          marginBottom: 12,
        })}
      >
        {list}
      </ul>
    );
  }
}

const updateNotificationSetting = gql`
  mutation($id: ID!, $emailNotifications: JSON) {
    updateUser(id: $id, emailNotifications: $emailNotifications) {
      id
      emailNotifications
    }
  }
`;

const NotificationSettings = graphql(updateNotificationSetting, {
  name: "updateNotificationSetting",
})(NotificationSettingsWithoutMutations);

export default NotificationSettings;
