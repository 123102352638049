/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { css, jsx } from "@emotion/core";
import { colors } from "../../helpers/styles";

export default function AlertModal({
  title,
  body,
  isWarning,
  actionOne,
  actionTwo,
}) {
  return (
    <div
      css={css`
        position: fixed;
        left: 12px;
        bottom: 12px;
        width: 280px;
        background: white;
        border-radius: 5px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        z-index: 2;
      `}
    >
      <div
        css={css`
          float: left;
          width: 100%;
          box-sizing: border-box;
          padding: 10px 24px 10px 24px;
        `}
      >
        <div
          css={css`
            height: 28px;
            line-height: 28px;
            padding-bottom: 10px;
            border-bottom: 2px solid;
            border-color: ${isWarning ? colors.orange : colors.green};
            width: fit-content;
            font-size: 16px;
            color: ${isWarning ? colors.orange : colors.green};
          `}
        >
          {title}
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            margin-top: 12px;
            font-size: 14px;
            line-height: 16px;
            font-weight: 300;
            color: rgba(0, 0, 0, 0.87);
          `}
        >
          {body}
        </div>
      </div>
      {actionOne && actionTwo && (
        <div
          css={css`
            float: left;
            width: 100%;
            box-sizing: border-box;
            border-top: 1px solid #e2e2e2;
            font-size: 14px;
            span {
              cursor: pointer;
              :hover {
                text-decoration: underline;
              }
            }
          `}
        >
          <div
            css={css`
              float: left;
              line-height: 42px;
              padding-left: 24px;
              padding-right: 24px;
              color: ${colors.te};
            `}
          >
            <span onClick={actionOne.action}>{actionOne.text}</span>
          </div>
          <div
            css={css`
              float: left;
              line-height: 42px;
              text-align: center;
              color: rgba(0, 0, 0, 0.87);
            `}
          >
            <span onClick={actionTwo.action}>{actionTwo.text}</span>
          </div>
        </div>
      )}
    </div>
  );
}
