import styles from "./ToolBox.module.css";

export default function ModalLarge({
  icon,
  iconAnimating,
  innerCircleStyle,
  title,
  subtitle,
  content,
  actions,
  footer,
  handleClose,
}: {
  icon: JSX.Element;
  iconAnimating?: boolean;
  innerCircleStyle: React.CSSProperties;
  title: string;
  subtitle: string;
  content: JSX.Element;
  needsSessionId: boolean;
  sessionId: string;
  actions: JSX.Element[];
  footer: boolean;
  handleClose?: Function;
}) {
  return (
    <>
      <div className={styles["modal-large"]}>
        <div>
          <div
            className={
              styles["modal-large-circle"] +
              " " +
              styles[
                iconAnimating
                  ? "modal-large-circle-animating"
                  : "modal-large-circle-static"
              ]
            }
          >
            <div
              className={styles["modal-large-inner-circle"]}
              style={innerCircleStyle}
            >
              {icon}
            </div>
          </div>
        </div>
        <div style={{ fontSize: 20, lineHeight: "24px" }}>
          {title}
          <br />
          <span style={{ fontSize: 16, fontWeight: 300 }}>{subtitle}</span>
        </div>
        <div style={{ fontWeight: 300 }}>{content}</div>
        <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
          {actions}
        </div>
        {footer && (
          <div className={styles["modal-large-footer"]}>
            We'd love your{" "}
            <a href="mailto:feedback@soonapp.io">feedback and suggestions</a> to
            shape our product's future.
          </div>
        )}
      </div>
      <div
        className="overlay"
        style={{ zIndex: 4 }}
        onClick={() => {
          if (handleClose) {
            handleClose();
          }
        }}
      />
    </>
  );
}
