import React, { useEffect, useState } from "react";
import { css, select as $ } from "glamor";

import InputField from "../toolBox/InputField";
import Button from "../button/Button";
import { colors } from "../../helpers/styles";

import ProfileCalendarSettings from "./ProfileCalendarSettings";
import GoogleCalendarIcon from "../../assets/images/google-calendar-icon.png";
import OutlookCalendarIcon from "../../assets/images/outlook-calendar-icon.svg";

export default function ProfileCalendars({
  googleStatus: currentGoogleStatus,
  googleCalendarConfig,
  userId,
  noInputField,
  integrationStatuses,
}) {
  const [calendarSettings, setCalendarSettings] = useState(false);

  let googleStatus;
  let googleConnectionStatus;
  let emailConnected = "";

  const hasGoogleCalendarConfig =
    googleCalendarConfig && Object.keys(googleCalendarConfig).length !== 0;

  googleStatus = currentGoogleStatus;
  if (!googleStatus) {
    if (hasGoogleCalendarConfig) {
      googleConnectionStatus = "CONNECTION_LOST";
    } else {
      googleConnectionStatus = "NO_CONNECTION";
    }
  } else {
    googleConnectionStatus = "CONNECTED";
  }

  if (hasGoogleCalendarConfig) {
    emailConnected = googleCalendarConfig.emailConnected
      ? googleCalendarConfig.emailConnected
      : "";
  }
  if (googleStatus) {
    emailConnected = googleStatus.email ? googleStatus.email : "";
  }

  const integrations = integrationStatuses
    ? integrationStatuses.integrations
    : [];

  function getCalendarIcon(type) {
    if (type === "GOOGLE_CALENDAR") {
      return GoogleCalendarIcon;
    }
    if (type === "OUTLOOK_CALENDAR") {
      return OutlookCalendarIcon;
    }
    return "";
  }
  const calendars = integrations.map((integration) => {
    const icon = getCalendarIcon(integration.type);
    const email = integration.email
      ? integration.email
      : integration.config?.emailConnected;
    const status = integration.status;
    return { id: integration.id, name: integration.type, icon, email, status };
  });

  let selectedCalendar = integrations.filter(
    (i) => i.id === calendarSettings
  )[0];

  if (calendarSettings === "OUTLOOK_CALENDAR") {
    selectedCalendar = { type: "OUTLOOK_CALENDAR" };
  }
  if (calendarSettings === "GOOGLE_CALENDAR") {
    selectedCalendar = { type: "GOOGLE_CALENDAR" };
  }

  const connectedCalendars = calendars.map((calendar, index) => {
    return (
      <div
        key={calendar.id}
        {...css({
          float: "left",
          width: "100%",
          position: "relative",
          height: 33,
          lineHeight: "33px",
          color: "rgba(0,0,0,0.87)",
          fontSize: 16,
          fontWeight: 300,
          borderBottom: "1px solid #E2E2E2",
          boxSizing: "border-box",
          marginTop: index > 0 ? 6 : 0,
          zIndex: calendars.length - index,
        })}
      >
        <div
          {...css({
            float: "left",
            width: 24,
            paddingTop: 3.75,
            boxSizing: "border-box",
          })}
        >
          <img width={18} src={calendar.icon} />
        </div>
        <div
          {...css({
            float: "left",
            width: "calc(100% - 64px)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          })}
        >
          {calendar.email}
        </div>
        <ConnectionAndSettings
          id={calendar.id}
          status={calendar.status}
          setCalendarSettings={setCalendarSettings}
        />
      </div>
    );
  });

  const hasOutlookConnection =
    integrations.filter(
      (i) =>
        i.type === "OUTLOOK_CALENDAR" &&
        ["CONNECTED", "CONNECTION_LOST"].includes(i.status)
    ).length > 0;

  return (
    <div>
      {!noInputField && (
        <div {...css({ float: "left", width: "100%", marginBottom: 24 })}>
          <div
            {...css({
              float: "left",
              width: "100%",
              fontSize: 14,
              color: "rgba(0,0,0,0.54)",
            })}
          >
            Calendar account(s)
          </div>
          {connectedCalendars.length > 0 ? (
            connectedCalendars
          ) : (
            <div
              {...css({
                float: "left",
                width: "100%",
                position: "relative",
                height: 33,
                lineHeight: "33px",
                color: "rgba(0,0,0,0.54)",
                fontSize: 16,
                fontWeight: 300,
                borderBottom: "1px solid #E2E2E2",
                boxSizing: "border-box",
              })}
            >
              No connected calendar
            </div>
          )}
        </div>
      )}
      {googleConnectionStatus === "NO_CONNECTION" && (
        <div
          {...css({
            float: "left",
            width: "100%",
            marginBottom: 12,
          })}
        >
          <Button
            text={
              <div
                {...css({
                  float: "left",
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: 8,
                  color: "rgba(0,0,0,0.87)",
                  height: 18,
                  boxSizing: "border-box",
                })}
              >
                <div
                  {...css({
                    float: "left",
                  })}
                >
                  <img width={18} height={18} src={GoogleCalendarIcon} />
                </div>
                <div
                  {...css({
                    float: "left",
                    marginLeft: 14,
                  })}
                >
                  Connect Google Calendar
                </div>
              </div>
            }
            nature="grey-border"
            size="large-no-margin"
            handleClick={() => setCalendarSettings("GOOGLE_CALENDAR")}
          />
        </div>
      )}
      {!hasOutlookConnection && (
        <div
          {...css({
            float: "left",
            width: "100%",
            marginBottom: 24,
          })}
        >
          <Button
            text={
              <div
                {...css({
                  float: "left",
                  width: "100%",
                  textAlign: "left",
                  paddingLeft: 8,
                  color: "rgba(0,0,0,0.87)",
                  height: 18,
                  boxSizing: "border-box",
                })}
              >
                <div
                  {...css({
                    float: "left",
                  })}
                >
                  <img width={18} height={18} src={OutlookCalendarIcon} />
                </div>
                <div
                  {...css({
                    float: "left",
                    marginLeft: 14,
                  })}
                >
                  Connect Outlook Calendar
                </div>
              </div>
            }
            nature="grey-border"
            size="large-no-margin"
            handleClick={() => setCalendarSettings("OUTLOOK_CALENDAR")}
          />
        </div>
      )}
      {selectedCalendar && (
        <div>
          <div
            {...css({
              position: "fixed",
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,0.2)",
              left: 0,
              top: 0,
              zIndex: 11,
            })}
            onClick={() => setCalendarSettings(false)}
          />
          <ProfileCalendarSettings
            userId={userId}
            closeModal={() => setCalendarSettings(false)}
            integrationId={selectedCalendar.id}
            lastSynced={integrationStatuses?.synced}
            emailConnected={
              selectedCalendar?.email
                ? selectedCalendar.email
                : selectedCalendar.config?.emailConnected
            }
            type={selectedCalendar.type}
            config={selectedCalendar.config ? selectedCalendar.config : {}}
            connectionStatus={
              selectedCalendar.status
                ? selectedCalendar.status
                : "NO_CONNECTION"
            }
          />
        </div>
      )}
    </div>
  );
}

function ConnectionAndSettings({ id, status, setCalendarSettings }) {
  return (
    <div
      {...css({
        position: "absolute",
        right: 0,
        zIndex: 1,
      })}
    >
      <div>
        <div className="tooltip">
          <div
            aria-label={
              status === "CONNECTION_LOST" ? "No connection" : "Connected"
            }
            {...css({
              position: "absolute",
              width: 8,
              height: 8,
              background:
                status === "CONNECTION_LOST" || status === "NOT_ENOUGH_SCOPES"
                  ? colors.red
                  : colors.green,
              top: -10,
              right: 5,
              borderRadius: 8,
              cursor: "default",
            })}
          />
          <span
            className="tooltiptext"
            {...css(
              {
                marginLeft: "-69px !important",
                marginTop: "10px !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "17px",
                textAlign: "left",
              },
              $("::after", {
                left: "56px",
              })
            )}
          >
            {status === "CONNECTION_LOST" ? "Disconnected" : "Connected"}
          </span>
        </div>
        <div className="tooltip">
          <div
            aria-label="Calendar settings"
            className="bi_setting-gear-a"
            {...css(
              { fontSize: 16.7, color: "rgba(0,0,0,0.54)" },
              $(":hover", {
                color: "rgba(0,0,0,0.87)",
                cursor: "pointer",
              })
            )}
            onClick={() => setCalendarSettings(id)}
          />
          <span
            className="tooltiptext"
            {...css(
              {
                marginLeft: "-51px !important",
                marginTop: "5px !important",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "17px",
                textAlign: "left",
              },
              $("::after", {
                left: "48px",
              })
            )}
          >
            Settings
          </span>
        </div>
      </div>
    </div>
  );
}
