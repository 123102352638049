/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import React, { useState, useRef, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import { useMutation } from "@apollo/client";
import { motion, useInView } from "framer-motion";
import { gql } from "@apollo/client";
import Moment from "moment-timezone";
import { Redirect } from "react-router-dom";

import { getWhen } from "../leave/functions";
import { formatISOasAMPM } from "../../helpers/functions";

const notificationStyle = css`
  position: relative;
  background: white;
  border-radius: 3px;
  margin-bottom: 6px;
  max-height: 110px;
  padding: 6px;
  box-sizing: border-box;
  line-height: 17px;
  font-size: 12px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 149, 255, 0.05);
  }

  @media (max-width: 900px) {
    max-height: 110px;
    font-size: 14px;
    line-height: 19px;

    &:hover {
      background: white;
    }
  }
`;

const notificationContent = css`
  width: 272px;
  margin-left: 12px;

  @media (max-width: 900px) {
    width: calc(100% - 16px);
  }
`;

const float = css`
  float: left;
`;

const hundred = css`
  width: 100%;
`;

const notificationType = css`
  font-size: 14px;

  @media (max-width: 900px) {
    font-size: 16px;
  }
`;

const markAsReadStyle = css`
  position: absolute;
  color: #0095ff;
  font-weight: 300;
  top: 0;
  right: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const notificationStatus = (markAsRead) => css`
  position: absolute;
  background: ${!markAsRead ? "#0095FF" : "rgba(0,0,0,0.15)"};
  width: 4px;
  padding-top: 6px;
  height: calc(100% - 18px);
  display: block;
  border-radius: 2px;
  margin-right: 12px;
`;

const MARK_AS_READ_MUTATION = gql`
  mutation($notificationId: ID!, $markAsRead: Boolean!) {
    updateNotification(id: $notificationId, markAsRead: $markAsRead) {
      id
      markAsRead
    }
  }
`;

const Notification = ({
  id,
  type,
  audience,
  change,
  eventId,
  board,
  slots,
  userId,
  title,
  leave,
  startTime,
  endTime,
  date,
  format12h,
  markAsRead,
  index,
  length,
  fetchMore,
  handleFocus,
  eventStatus,
  createdAt,
  match,
}) => {
  const [notificationAction, setNotificationAction] = useState(false);
  const [mutate] = useMutation(MARK_AS_READ_MUTATION);

  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    const number = length - 5;
    if (index === number && isInView) {
      fetchMore();
    }
  }, [isInView, index, length, fetchMore]);

  const handleNotificationAction = () => {
    setNotificationAction(true);
    if (audience !== "markAsRead") {
      handleFocus();
    }
  };

  const handleRead = async (e) => {
    e.stopPropagation();
    await mutate({
      variables: { notificationId: id, markAsRead: !markAsRead },
    });
  };

  const requestStillValid = () => {
    const containsRequest = slots
      ?.flatMap((slot) => slot.requests)
      .some((request) => request.user?.id === userId);
    return containsRequest || false;
  };

  const redirectUrl = () => {
    const eventDate = Moment(startTime).unix();
    const leaveId = leave?.id;
    const leaveDate =
      leave &&
      Moment(leave.startTime)
        .startOf("month")
        .unix();
    const stillValid = requestStillValid();
    const matchDate = match?.params.date ? match.params.date : +new Date();

    switch (audience) {
      case "memberEvent":
        return change
          ? change.requests.length > 0
            ? `/myschedule/${eventDate}/${eventId}/change/${change.id}`
            : ""
          : stillValid
          ? `/myschedule/${eventDate}/${eventId}`
          : "";
      case "adminEvent":
        if (change) {
          return change.requests.length > 0 && change.id && board?.id
            ? `/b/${board.id}/${matchDate}/event/${eventId}/people/${
                change.requests[0].id
              }/change/${change.id}`
            : "";
        } else {
          return eventStatus === "DELETED"
            ? ""
            : `/b/${board?.id}/${matchDate}/event/${eventId}`;
        }
      case "adminBoard":
        return "/boards";
      case "adminTeam":
      case "adminTeamManage":
        return "/account/profile";
      case "adminMemberManage":
        return "/users/team";
      case "leaveAdmin":
      case "leaveMember":
        return leaveDate && leaveId
          ? `/leave/requests/${leaveDate}/${leaveId}`
          : "";
      default:
        return "";
    }
  };

  if (notificationAction) {
    const url = redirectUrl();
    if (url) return <Redirect to={url} />;
  }

  const notificationValid = () => {
    if (audience === "memberEvent") {
      return change ? change.requests.length > 0 : requestStillValid();
    } else if (["leaveAdmin", "leaveMember"].includes(audience)) {
      return !!leave;
    } else {
      return !(type === "Cancellation requested" && !change);
    }
  };

  const category = leave?.category?.name || "No category";
  const secondary = leave?.user
    ? leave.user.id === userId
      ? category
      : leave.user.name
    : title || "Deleted user";
  const tertiary =
    leave && getWhen(leave.startTime, leave.endTime, true, format12h);

  const overnight = !Moment(startTime).isSame(endTime, "day");

  return (
    <motion.li
      initial={{ marginTop: -70, opacity: 0 }}
      animate={{ marginTop: 0, opacity: 1 }}
      exit={{ marginTop: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: [0, 1, 0.5, 1] }}
    >
      <div
        ref={ref}
        css={[
          float,
          hundred,
          notificationStyle,
          { cursor: notificationValid() ? "pointer" : "default" },
        ]}
        onClick={handleNotificationAction}
      >
        <div css={notificationStatus(markAsRead)} />
        <div css={notificationContent}>
          <div css={[float, hundred, notificationType]}>
            {type}
            {!notificationValid() && " (revoked)"}
          </div>
          <div
            css={[
              float,
              hundred,
              { fontWeight: 300, color: "rgba(0,0,0,0.87)" },
            ]}
          >
            {secondary}
          </div>
          {date ? (
            <div
              css={[
                float,
                hundred,
                { fontWeight: 300, color: "rgba(0,0,0,0.54)" },
              ]}
            >
              {Moment(date).format("ddd, D MMM")} from{" "}
              {format12h
                ? formatISOasAMPM(startTime)
                : Moment(startTime).format("HH:mm")}{" "}
              to{" "}
              {format12h
                ? formatISOasAMPM(endTime)
                : Moment(endTime).format("HH:mm")}
              {overnight ? "+1" : ""}
            </div>
          ) : tertiary ? (
            <div
              css={[
                float,
                hundred,
                { fontWeight: 300, color: "rgba(0,0,0,0.54)" },
              ]}
            >
              {tertiary}
            </div>
          ) : null}
          <div
            css={[
              float,
              hundred,
              {
                fontWeight: 100,
                color: "rgba(0,0,0,0.54)",
                position: "relative",
              },
            ]}
          >
            {Moment(createdAt).fromNow()}
            <div css={markAsReadStyle} onClick={(e) => handleRead(e)}>
              {!markAsRead ? "Mark as read" : "Mark unread"}
            </div>
          </div>
        </div>
      </div>
    </motion.li>
  );
};

export default Notification;
