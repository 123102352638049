import React from "react";
import { graphql } from "@apollo/client/react/hoc";
import { gql } from "@apollo/client";
import memoize from "memoize-one";
import Loader from "../loader/Loader";
import Notification from "./Notification";
import { css, media } from "glamor";
import loader from "../../assets/images/loader.svg";
import { handleAPIError } from "../../helpers/handleErrors";

const getRequest = memoize((slots, userId) => {
  let currentRequest;
  if (slots) {
    slots.forEach((slot) => {
      slot.requests.forEach((request) => {
        if (request.user && request.user.id === userId) {
          currentRequest = request;
        }
      });
    });
  }
  return currentRequest;
});

class NotificationList extends React.Component {
  state = {
    noMoreNotifications: false,
  };
  refetch = () => {
    this.props.data
      .refetch()
      .then((res) => {
        this.props.markAllRead("false");
      })
      .catch((err) => {
        handleAPIError(err, {});
        console.log(err);
      });
  };

  transform = (returnData, notification) => {
    let transformedType = "";
    let transformedTitle = "";
    let notificationAudience;
    const actionTaker = notification.actionTaker
      ? notification.actionTaker.name
      : "Admin";
    const actionTakenOn = notification.actionTakenOn
      ? notification.actionTakenOn.name
      : "Deleted user";
    const receiver = notification.receiver
      ? notification.receiver.firstName
      : "Deleted user";
    switch (notification.type) {
      case "MEMBER_EVENT_CREATED":
        transformedType = "New event";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_CANCELLATION_ACCEPTED":
        transformedType = "Cancellation request approved";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_CANCELLATION_DECLINED":
        transformedType = "Cancellation request rejected";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_EVENT_UPDATED":
        transformedType = "Updated event";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_EVENT_RESEND":
        transformedType = "Event reinvitation";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_EVENT_CANCELLED":
        transformedType = "Cancelled event";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_COVER_REQUESTED":
        transformedType = "Cover requested";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_COVER_ACCEPTED":
        transformedType = "Cover request accepted";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_COVER_DECLINED":
        transformedType = "Cover request declined";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_COVER_FORCE_ACCEPTED":
        transformedType = "Cover request force-accepted";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_COVER_FORCE_DECLINED":
        transformedType = "Cover request force-declined";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SWAP_REQUESTED":
        transformedType = "Swap requested";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SWAP_ACCEPTED":
        transformedType = "Swap request accepted";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SWAP_DECLINED":
        transformedType = "Swap request declined";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SWAP_FORCE_ACCEPTED":
        transformedType = "Swap request force-accepted";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SWAP_FORCE_DECLINED":
        transformedType = "Swap request force-declined";
        notificationAudience = "memberEvent";
        break;
      case "MEMBER_SYSTEM_WELCOME":
        transformedType = "Welcome to Soon";
        transformedTitle =
          receiver +
          ", great to have you on board! If you have any questions or feedback, please click the help button below. We're happy to lend a hand!";
        notificationAudience = "markAsRead";
        break;
      case "ADMIN_CANCELLATION_REQUESTED":
        transformedType = "Cancellation requested";
        notificationAudience = "adminEvent";
        break;
      case "ADMIN_EVENT_ISSUE":
        transformedType = "Issue";
        notificationAudience = "adminEvent";
        break;
      case "ADMIN_MEMBER_DEACTIVATED":
        transformedType = "Member account deactivated";
        transformedTitle = actionTakenOn + "'s account has been deactivated";
        notificationAudience = "adminMemberManage";
        break;
      case "ADMIN_MEMBER_REACTIVATED":
        transformedType = "Member account reactivated";
        transformedTitle = actionTakenOn + "'s account has been reactivated";
        notificationAudience = "adminMemberManage";
        break;
      case "ADMIN_MEMBER_DISCONNECTED":
        transformedType = "Member removed from team";
        transformedTitle =
          actionTakenOn + " has been removed from the team by " + actionTaker;
        notificationAudience = "adminMemberManage";
        break;
      case "ADMIN_ADMIN_DEACTIVATED":
        transformedType = "Admin account deactivated";
        transformedTitle = actionTakenOn + "'s account has been deactivated";
        notificationAudience = "adminTeamManage";
        break;
      case "ADMIN_ADMIN_REACTIVATED":
        transformedType = "Admin account reactivated";
        transformedTitle = actionTakenOn + "'s account has been reactivated";
        notificationAudience = "adminTeamManage";
        break;
      case "ADMIN_MEMBER_JOINED":
        transformedType = "Member has joined the team";
        transformedTitle = actionTakenOn + " has joined the team";
        notificationAudience = "adminMemberManage";
        break;
      case "ADMIN_ADMIN_JOINED":
        transformedType = "Admin has joined the team";
        transformedTitle = actionTakenOn + " has joined the team";
        notificationAudience = "adminTeamManage";
        break;
      case "ADMIN_BOARD_DELETED":
        transformedType = "Board deleted";
        transformedTitle =
          actionTaker + " has deleted the board " + notification.boardName;
        notificationAudience = "adminBoard";
        break;
      case "ADMIN_TEAM_UPDATED":
        transformedType = "Team profile updated";
        transformedTitle = actionTaker + " has updated the team profile";
        notificationAudience = "adminTeam";
        break;
      case "ADMIN_SYSTEM_WELCOME":
        transformedType = "Welcome to Soon";
        transformedTitle =
          receiver +
          ", great to have you on board! If you have any questions or feedback, please click the help button below. We're happy to lend a hand!";
        notificationAudience = "markAsRead";
        break;
      case "LEAVE_REQUESTED":
        transformedType = "New leave requested";
        notificationAudience = "leaveAdmin";
        break;
      case "LEAVE_CREATED":
        transformedType = "New leave created";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_UPDATED":
        transformedType = "Leave request updated";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_CANCELLATION_REQUESTED":
        transformedType = "Leave cancellation requested";
        notificationAudience = "leaveAdmin";
        break;
      case "LEAVE_APPROVED":
        transformedType = "Leave request approved";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_REJECTED":
        transformedType = "Leave request rejected";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_CANCELLED":
        transformedType = "Leave cancelled";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_CANCELLATION_APPROVED":
        transformedType = "Leave cancellation approved";
        notificationAudience = "leaveMember";
        break;
      case "LEAVE_CANCELLATION_REJECTED":
        transformedType = "Leave cancellation rejected";
        notificationAudience = "leaveMember";
        break;
      case "OWNER_SYSTEM_WELCOME":
        transformedType = "Welcome to Soon";
        transformedTitle =
          receiver +
          ", great to have you on board! If you have any questions or feedback, please click the help button below. We're happy to lend a hand!";
        notificationAudience = "markAsRead";
        break;
      default:
        break;
    }
    if (returnData === "audience") {
      return notificationAudience;
    } else if (returnData === "type") {
      return transformedType;
    } else if (returnData === "title") {
      return transformedTitle;
    } else {
      return;
    }
  };

  fetchMore = () => {
    const skip = this.props.data.allNotifications.length;
    const { userId } = this.props;
    if (!this.state.noMoreNotifications) {
      this.props.data
        .fetchMore({
          variables: { first: 30, skip, id: userId },
          updateQuery: (
            previousResult,
            { fetchMoreResult, queryVariables }
          ) => {
            return {
              allNotifications: [
                ...previousResult.allNotifications,
                ...fetchMoreResult.allNotifications,
              ],
            };
          },
        })
        .then((res) => {
          if (
            res.data.allNotifications &&
            res.data.allNotifications.length < 1
          ) {
            // no more notifications
            this.setState({ noMoreNotifications: true });
          }
        })
        .catch((err) => {
          handleAPIError(err, {});
          console.log(err);
        });
    }
  };

  render() {
    if (this.props.data.loading && !this.props.data.allNotifications) {
      return (
        <div
          {...css({
            float: "left",
            width: "100%",
            background: "white",
            height: "60px",
          })}
        >
          <Loader />
        </div>
      );
    }

    this.props.markAll && this.refetch();
    let allNotifications = this.props.unread
      ? this.props.data.allNotifications.filter((n) => n.markAsRead === false)
      : this.props.data.allNotifications;

    const notifications = allNotifications.map((notification, i) => {
      let currentRequest;
      if (notification.event) {
        currentRequest = getRequest(
          notification.event.slots,
          this.props.userId
        );
      }

      const actualStart = currentRequest && currentRequest.actualStart;
      const actualEnd = currentRequest && currentRequest.actualEnd;

      let eventId;
      let date;
      let startTime;
      let endTime;
      let eventStatus;
      let title;
      let board;
      if (notification.event) {
        eventId = notification.event.id;
        date = notification.event.date;
        startTime = actualStart ? actualStart : notification.event.startTime;
        endTime = actualEnd ? actualEnd : notification.event.endTime;
        eventStatus = notification.event.status;
        title = notification.event.title;
        board = notification.event.board;
      } else {
        title = this.transform("title", notification);
      }

      const audience = this.transform("audience", notification);

      return (
        <Notification
          key={notification.id}
          id={notification.id}
          type={this.transform("type", notification)}
          change={notification.change}
          title={title}
          audience={audience}
          markAsRead={notification.markAsRead}
          createdAt={notification.createdAt}
          startTime={startTime}
          endTime={endTime}
          date={date}
          eventId={eventId}
          eventStatus={eventStatus}
          teamId={this.props.teamId}
          handleFocus={this.props.handleFocus}
          board={board}
          slots={notification.event && notification.event.slots}
          leave={notification.leave}
          userId={this.props.userId}
          index={i}
          length={allNotifications.length}
          fetchMore={this.fetchMore}
          noMoreNotifications={this.state.noMoreNotifications}
          match={this.props.match}
          format12h={this.props.format12h}
        />
      );
    });

    return (
      <div {...css({ float: "left" })}>
        {notifications.length > 0 ? (
          <div>
            <ul>{notifications}</ul>
            {this.props.data.loading && this.props.data.networkStatus !== 6 && (
              <div
                {...css(
                  { float: "left", textAlign: "center", width: "100%" },
                  media("(min-width: 900px)", {
                    float: "right",
                    textAlign: "right",
                  })
                )}
              >
                <img
                  width="25px"
                  height="25px"
                  src={loader}
                  alt="Loading more notifications"
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            {this.props.markAsRead ? (
              <p>You don't have any notifications.</p>
            ) : (
              <p>You don't have any unread notifications.</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

export const notificationsQuery = gql`
  query NotificationQuery($id: ID!, $skip: Int, $first: Int) {
    allNotifications(userId: $id, first: $first, skip: $skip) {
      id
      type
      markAsRead
      createdAt
      boardName
      receiver {
        id
        firstName
      }
      actionTakenOn {
        id
        name
      }
      actionTaker {
        id
        name
        teamClasses {
          id
          userIs
        }
      }
      leave {
        id
        startTime
        endTime
        category {
          id
          name
        }
        user {
          id
          name
        }
      }
      change {
        id
        requests {
          id
          slot {
            id
            event {
              id
              title
              status
              startTime
              endTime
              date
              board {
                id
                url
              }
            }
          }
        }
      }
      event {
        id
        title
        date
        startTime
        endTime
        status
        slots {
          id
          requests {
            id
            status
            actualStart
            actualEnd
            actualBreak
            user {
              id
              avatar
              name
              firstName
              lastName
            }
          }
        }
        board {
          id
          url
        }
      }
    }
  }
`;

export default graphql(notificationsQuery, {
  options: (props) => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: props.userId,
      first: 15,
      skip: 0,
    },
    fetchPolicy: "network-only",
  }),
})(NotificationList);
