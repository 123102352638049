import uuidv4 from "uuid/v4";

export async function connectGoogle({ userId, setLoading, callback }) {
  let domain = import.meta.env.VITE_APP_GRAPHCOOL_URI;
  if (domain[domain.length - 1] === "/") {
    domain = domain.slice(0, -1);
  }
  let backendUrl = domain + "/google/authorize";

  fetch(backendUrl, {
    method: "GET",
    headers: {
      userId,
    },
  })
    .then((response) => {
      console.log({ response });
      return response.json();
    })
    .then(async (result) => {
      console.log(result, callback);
      if (callback) {
        const test = await callback().catch((err) => console.log(err));
      }
      setLoading(false);
      window.location.replace(result.authUrl);
    })
    .catch((err) => {
      console.log({ err });
      setLoading(false);
      // handleAPIError(err, {});
      // reject(err);
    });
}

export async function connectOutlook({ userId }) {
  const backendUrl = import.meta.env.VITE_APP_GRAPHCOOL_URI;
  const endpoint = encodeURIComponent(
    import.meta.env.VITE_APP_GRAPHCOOL_URI + "/ms/authorize"
  );
  const outlookUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=76d6dd83-2c71-403d-9826-8407296523e1&response_type=code&redirect_uri=${endpoint}&response_mode=query&scope=offline_access%20user.read%20calendars.readwrite%20calendars.readwrite.shared&state=${userId}`;
  window.location.replace(outlookUrl);
}

export async function handleSSOConnection({
  provider,
  state,
  isAuthenticated,
}) {
  // const isApplication = true;
  const isApplication = import.meta.env.VITE_APP_ISMSTEAMSDOMAIN;

  let domain = import.meta.env.VITE_APP_GRAPHCOOL_URI;
  if (domain[domain.length - 1] === "/") {
    domain = domain.slice(0, -1);
  }
  let backendUrl = domain + "/auth/" + provider;

  let headers = {
    state,
  };
  if (isAuthenticated) {
    const token = localStorage.getItem("graphcoolToken");
    const authorizationHeader = token ? `Bearer ${token}` : null;
    headers.Authorization = authorizationHeader;
  }

  if (isApplication) {
    // in this case the auth flow takes place in a browser
    // while we are currently inside an 'application'
    // we therefore store a code in localstorage, to
    // later be able to query the ssoCode
    const ssoCode = uuidv4();

    headers.state = state + "+" + ssoCode;
    window.localStorage.removeItem("ssoCode");
    window.localStorage.setItem("ssoCode", ssoCode);
  }

  fetch(backendUrl, {
    method: "GET",
    headers,
  })
    .then((response) => {
      console.log({ response });
      return response.json();
    })
    .then(async (result) => {
      if (isApplication) {
        window.localStorage.setItem("redirectUrl", result.url);
        window.open(result.url, "_blank");
        window.location.assign("/sso");
      } else {
        window.location.assign(result.url);
      }
    })
    .catch((err) => {
      console.log({ err });
    });
}
