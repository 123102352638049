import React from "react";
import { gql, useQuery } from "@apollo/client";
import HeaderBar from "./HeaderBar";

export default React.memo(function Header({
  userId,
  headerTitle,
  match,
  history,
  billingPlan,
  isZoomedIn,
  handleSaveClose,
  customItems,
  currentCustomItem,
  isDisabled,
}) {
  const { loading, error, data, refetch } = useQuery(HEADER_QUERY, {
    variables: { userId },
  });
  const {
    loading: loadingGoogleStatus,
    error: errorGoogleStatus,
    data: dataGoogleStatus,
  } = useQuery(GET_GOOGLE_STATUS, {
    variables: { userId },
  });
  const {
    loading: loadingIntegrationStatuses,
    error: errorIntegrationStatuses,
    data: dataIntegrationStatuses,
  } = useQuery(GET_INTEGRATION_STATUSES, {
    variables: { userId },
  });

  const { loading: loading2, error: error2, data: data2 } = useQuery(
    NOTI_COUNT_QUERY,
    {
      pollInterval: 20000,
    }
  );

  let boardName = "";
  let boards = [];

  let defaultScreenDate = "";
  let user = {};
  let team = {};
  let format12h;
  let googleCalendarConfig = {};
  if (!loading && data) {
    const userData = data.getHeaderData;
    const teamData = userData.teams[0];
    if (teamData.hasSSOEnabled) {
      if (!userData.hasEnterpriseSSOAccount) {
        localStorage.removeItem("graphcoolToken");
        window.location.reload();
      }
    }

    team = {
      id: teamData.id,
      logo: teamData.logo,
      name: teamData.name,
      settings: {
        hasSSOEnabled: teamData.hasSSOEnabled,
      },
    };
    boards = teamData.boards;
    defaultScreenDate = teamData.defaultScreenDate;

    user = {
      id: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      name: userData.name,
      avatar: userData.avatar,
      userIs: userData.teamClasses[0].userIs,
      emailNotifications: userData.emailNotifications,
    };

    format12h = userData.timeFormat === "12-hour";
    googleCalendarConfig = userData.googleCalendarConfig;

    if (match) {
      const board = boards.filter((b) => b.id === match.params.boardId);
      boardName = board.length > 0 ? board[0].name : "";
    }
  }

  let notificationCount;
  if (!loading2 && data2) {
    notificationCount = data2.notificationCount.count;
  }

  let googleStatus;
  if (!loadingGoogleStatus && dataGoogleStatus) {
    if (!dataGoogleStatus.getGoogleStatus.error) {
      googleStatus = dataGoogleStatus.getGoogleStatus;
    }
  }

  let integrationStatuses;
  if (!loadingIntegrationStatuses && dataIntegrationStatuses) {
    integrationStatuses = dataIntegrationStatuses.getIntegrationStatuses;
  }

  return (
    <>
      <HeaderBar
        team={team}
        defaultScreenDate={defaultScreenDate}
        boards={boards}
        boardName={boardName}
        user={user}
        boardId={match?.params?.boardId}
        match={match}
        headerTitle={headerTitle}
        notificationCount={notificationCount}
        billingPlan={billingPlan}
        format12h={format12h}
        history={history}
        googleStatus={googleStatus}
        googleCalendarConfig={googleCalendarConfig}
        loadingGoogleStatus={loadingGoogleStatus}
        loading={loading}
        isZoomedIn={isZoomedIn}
        handleSaveClose={handleSaveClose}
        customItems={customItems}
        currentCustomItem={currentCustomItem}
        integrationStatuses={integrationStatuses}
      />
      {isDisabled ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: 60,
            background: "rgba(255,255,255,0.2)",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
});

export const HEADER_QUERY = gql`
  query HeaderQuery($userId: ID!) {
    getHeaderData(userId: $userId) {
      id
      name
      timeFormat
      firstName
      lastName
      avatar
      teamClasses {
        id
        userIs
      }
      hasEnterpriseSSOAccount
      googleCalendarConfig
      teams {
        id
        name
        logo
        defaultScreenDate
        hasSSOEnabled
        boards {
          # ) #   } #     ] #       { team: { owner: { id: $userId } } } #       } #         ] #           { readOnly_some: { id: $userId } } #           { members_some: { id: $userId } } #         AND: [ #       { #       { admins_some: { id: $userId } } #       { owner: { id: $userId } } #     OR: [ #   where: { # (
          id
          name
          url
        }
      }
      emailNotifications
    }
  }
`;

export const GET_GOOGLE_STATUS = gql`
  query getGoogleStatus($userId: ID!) {
    getGoogleStatus(userId: $userId)
  }
`;

export const GET_INTEGRATION_STATUSES = gql`
  query getIntegrationStatuses($userId: ID!) {
    getIntegrationStatuses(userId: $userId)
  }
`;

export const NOTI_COUNT_QUERY = gql`
  query NotificationCountQuery {
    notificationCount {
      count
    }
  }
`;
