import React, { createContext, useContext, useState, useEffect } from "react";
import usePartySocket from "partysocket/react";

const defaultValue = [];
const PresenceContext = createContext(defaultValue);

export const PresenceProvider = ({
  children,
  currentUser,
}: {
  children: React.ReactNode;
  currentUser: {
    id: string;
    avatar: string;
    firstName: string;
    lastName: string;
    teamId: string;
  };
}) => {
  const currentUrl = window.location.href;
  const [users, setUsers] = useState([]);
  const [isActive, setIsActive] = useState(true); // Track user's active/inactive state

  const ws = usePartySocket({
    host: import.meta.env.VITE_APP_PARTYKIT || "localhost:1999",
    room: "room-" + currentUser.teamId,
    query: async () => ({
      token: localStorage.getItem("graphcoolToken"),
    }),
    onOpen() {
      ws.send(
        JSON.stringify({
          type: "add-user",
          payload: {
            id: currentUser.id,
            avatar: currentUser.avatar,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            url: currentUrl,
            status: "active",
            teamId: currentUser.teamId,
          },
        })
      );
    },
    onMessage(e) {
      const message = JSON.parse(e.data);
      if (message.type === "presence") {
        setUsers(message.payload.users);
      }
    },
    onClose() {
      console.log("closed");
    },
    onError(e) {
      console.log("error", e);
    },
  });

  useEffect(() => {
    // Clean up user when the component is unmounted
    return () => {
      ws.send(
        JSON.stringify({
          type: "remove-user",
          payload: { id: currentUser.id },
        })
      );
    };
  }, [currentUser.id, ws]);

  useEffect(() => {
    // Update the user's URL when it changes
    ws.send(
      JSON.stringify({
        type: "update-user",
        payload: {
          id: currentUser.id,
          avatar: currentUser.avatar,
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          url: currentUrl,
          status: isActive ? "active" : "inactive", // Include user's activity status
        },
      })
    );
  }, [currentUrl, currentUser.id, ws, isActive]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      const newStatus = document.hidden ? "inactive" : "active";
      setIsActive(newStatus === "active");

      ws.send(
        JSON.stringify({
          type: "update-user",
          payload: {
            id: currentUser.id,
            avatar: currentUser.avatar,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            url: currentUrl,
            status: newStatus,
          },
        })
      );
    };

    // Listen to visibilitychange events
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [currentUser.id, currentUrl, ws]);

  return (
    <PresenceContext.Provider value={users}>
      {children}
    </PresenceContext.Provider>
  );
};

export const usePresence = () => {
  return useContext(PresenceContext);
};
