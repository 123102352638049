import React from "react";
import { Link } from "react-router-dom";
import { css } from "glamor";
import { colors } from "../../helpers/styles";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

class HeaderDropdownOutside extends React.Component {
  render() {
    const params = this.props.match.params;
    const listItems = this.props.boards.map((b) => {
      let boardLink;
      if (params.tab) {
        boardLink =
          "/b/" + b.id + "/" + params.date + "/settings/" + params.tab;
      } else {
        boardLink = "/b/" + b.id + "/" + params.date;
      }
      const isCurrentItem = this.props.boardId === b.id;
      return (
        <Link to={boardLink} key={b.id}>
          <li
            onClick={() => this.props.handleFocus("close")}
            {...css({
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              color: isCurrentItem && colors.blue,
              fontWeight: isCurrentItem && "500 !important",
            })}
          >
            {b.name}
          </li>
        </Link>
      );
    });

    return (
      <OutsideAlerter action={this.props.handleFocus}>
        <div className="header_dropdown_list">
          <ul>{listItems}</ul>
        </div>
      </OutsideAlerter>
    );
  }
}

export default HeaderDropdownOutside
