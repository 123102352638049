import React from "react";
import * as Sentry from "@sentry/browser";
import { APP_VERSION } from "../../helpers/globals";
import Error from "../toolBox/Error"


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorId: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    Sentry.setTag("app-version", APP_VERSION);
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      const errorId = Sentry.captureException(error);
      this.setState({ errorId });
    });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Error
          buttonOne={{
            name: "Report Bug",
            action: () => {
              Sentry.showReportDialog({ eventId: this.state.errorId });
            },
          }}
          buttonTwo={{
            name: "Refresh",
            action: () => window.location.reload(),
          }}
          errorId={this.state.errorId}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
