import React, { createContext, useContext, useState } from "react";

// Define default values for the context
const defaultValue = {
  confettiExplosion: false,
  showConfetti: () => {},
};

// Create the context with default values
const ConfettiContext = createContext(defaultValue);

// Create a provider component
export const ConfettiProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confettiExplosion, setConfettiExplosion] = useState(false);

  // Function to show the confetti explosion
  const showConfetti = () => {
    setConfettiExplosion(true);
    setTimeout(() => {
      setConfettiExplosion(false);
    }, 5000);
  };

  return (
    <ConfettiContext.Provider value={{ confettiExplosion, showConfetti }}>
      {children}
    </ConfettiContext.Provider>
  );
};

// Custom hook to use the confetti context
export const useConfetti = () => useContext(ConfettiContext);
