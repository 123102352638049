/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import React, { useState } from "react";
import { css, jsx } from "@emotion/core";
import Avatar from "./Avatar";
import UploadImage from "../uploadImage/UploadImage";

export default function AvatarChangeable({
  image,
  customStyle,
  handleImage,
  preset,
  action,
}) {
  const [uploadImage, setUploadImage] = useState(false);

  return (
    <div
      onClick={() => setUploadImage(true)}
      style={{ float: "left", position: "relative" }}
      css={css`
        color: rgba(0, 0, 0, 0.54);
        :hover {
          color: rgba(0, 0, 0, 0.87);
        }
      `}
    >
      <Avatar
        placeholder={<span className="bi_tool-camera-b" />}
        image={image}
        customStyle={{
          width: 72,
          height: 72,
          border: "1px solid #E2E2E2",
          background: "#F9F9F9",
          fontSize: 30,
          lineHeight: "72px",
          boxSizing: "border-box",
        }}
      />
      <UploadImage
        handleImage={handleImage}
        handleFocus={() => setUploadImage(!uploadImage)}
        handleError={() => {}}
        name="uploadImage"
        uploadStyle={{
          position: "absolute",
          top: "0",
          background: "transparent",
          width: 72,
          height: 72,
        }}
        uploadClass="avatar_profile_image"
        preset={preset}
        action={action}
      />
    </div>
  );
}
