/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { css, jsx } from "@emotion/core";
import Button from "./Button";
import Label from "./Label";
import Embed from "./Embed";

export default function NewFeature({
  userId,
  spotlights,
  featureId,
  hideTemp,
}) {
  const [updateSpotlight, { data, loading }] = useMutation(UPDATE_SPOTLIGHT);

  async function handleUpdateSpotlight() {
    await updateSpotlight({
      variables: {
        id: userId,
        spotlights: { ...spotlights, [featureId]: true },
      },
    });
  }

  return (
    <>
      <div
        css={css`
          position: fixed;
          width: 360px;
          height: fit-content;
          background: white;
          margin: auto;
          border-radius: 5px;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
          z-index: 4;
          @media (max-width: 375px) {
            width: 343px;
          }
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            height: 270px;
            img {
              max-width: 100%;
            }
          `}
        >
          <Embed
            embeds={[
              {
                type: "youtube",
                url: "https://www.youtube.com/embed/R7Bmks3KL0I",
              },
            ]}
          />
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            padding: 36px;
            padding-top: 24px;
            box-sizing: border-box;
          `}
        >
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 12px;
            `}
          >
            <Label>NEW</Label>
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 12px;
              line-height: 29.33px;
              font-size: 20px;
            `}
          >
            AI-Powered Template Generator ✨
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 24px;
              font-size: 14px;
              font-weight: 300;
              line-height: 20.53px;
              a {
                color: #5551ff;
                font-weight: 500;
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
              }
            `}
          >
            Turn simple text into a fully optimized schedule with our new
            AI-powered Template Generator! Just describe your schedule, and Soon
            instantly transforms it into a ready-to-use template.
            <br />
            <a
              href="https://help.soon.works/en/articles/9891783-how-to-use-soon-s-ai-powered-template-generator"
              target="_blank"
            >
              Learn more
            </a>
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
            `}
          >
            <Button
              name="Got it"
              theme="purple-border"
              size="large"
              loading={loading}
              customStyle={{ width: "100%" }}
              action={handleUpdateSpotlight}
            />
          </div>
        </div>
      </div>
      <div
        css={css`
          position: fixed;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.2);
          width: 100%;
          height: 100%;
          z-index: 3;
        `}
        onClick={hideTemp}
      />
    </>
  );
}

const UPDATE_SPOTLIGHT = gql`
  mutation ($id: ID!, $spotlights: Json) {
    updateUser(id: $id, spotlights: $spotlights) {
      id
      spotlights
    }
  }
`;
