import React from "react";
import Soon from "../../assets/images/soon-wordmark-black.svg";

import Pitchground from "../../assets/images/pitchground-logo.png";
import AppSumo from "../../assets/images/appsumo-logo.png";
import Knab from "../../assets/images/knab-logo.png";
import AzureMarketplace from "../../assets/images/azure-marketplace.png";

export default function AuthHeader() {
  let privateLogo;
  if (window.location.href.includes("pitchground")) {
    privateLogo = Pitchground;
  } else if (window.location.href.includes("appsumo")) {
    privateLogo = AppSumo;
  } else if (window.location.href.includes("knab")) {
    privateLogo = Knab;
  } else if (
    window.location.href.includes("/azure?token=") ||
    window.location.href.includes("azId=")
  ) {
    privateLogo = AzureMarketplace;
  }
  return (
    <div className="auth-header">
      <div className="auth-header-left">
        <a href="https://soon.works" target="_blank">
          <img src={Soon} height={20} />
        </a>
      </div>
      <div className="auth-header-right">
        {privateLogo ? (
          <img src={privateLogo} height={20} />
        ) : (
          <span>
            Need help? <a id="open_intercom">Contact us</a>
          </span>
        )}
      </div>
    </div>
  );
}
