import styles from "./ToolBox.module.css";
import Loader from "../loader/Loader";
import OutsideAlerter from "../clickOutside/OutsideAlerter";
type Option = {
  name: string;
  icon?: string;
  style?: React.CSSProperties;
  action: () => void;
};

export default function OptionsMenu({
  options,
  customStyle,
  loading,
  closeMenu,
}: {
  options: Option[];
  customStyle: React.CSSProperties;
  loading?: boolean;
  closeMenu?: () => void;
}) {
  return (
    <OutsideAlerter
      action={() => {
        if (closeMenu) {
          closeMenu();
        }
      }}
    >
      <div className={styles["options-menu"]} style={customStyle}>
        {loading ? (
          <div style={{ padding: 16 }}>
            <Loader />
          </div>
        ) : (
          <ul>
            {options.map((item: Option, i: number) => {
              return (
                <li
                  key={i}
                  onClick={item.action}
                  style={item.style}
                  onKeyDown={(e) => {
                    e.preventDefault();
                    if (e.target instanceof HTMLElement) {
                      const previousNode = e.target
                        .previousElementSibling as HTMLElement | null;
                      const nextNode = e.target
                        .nextElementSibling as HTMLElement | null;

                      if (e.key === "ArrowDown" && nextNode) {
                        nextNode.focus();
                      } else if (e.key === "ArrowUp" && previousNode) {
                        previousNode.focus();
                      } else if (e.key === "Enter") {
                        item.action();
                      }
                    }
                  }}
                  tabIndex={0}
                >
                  {item.icon && <span className={item.icon}> </span>}
                  {item.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </OutsideAlerter>
  );
}
