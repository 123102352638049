import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Moment from "moment";

export default function Forecasts() {
  // useQuery
  const { data, loading, error } = useQuery(GET_FORECASTS);
  console.log(data, loading, error);

  const forecasts = data?.getTimeSeries.map(
    (forecast: { ds: string; y: number }) => ({
      x: Moment(forecast.ds).format("YYYY-MM-DD, HH:mm:ss"),
      y: forecast.y,
    })
  );
  return <TimeSeriesChart data={forecasts} />;
}

const GET_FORECASTS = gql`
  query getForecasts {
    getTimeSeries
  }
`;

const TimeSeriesChart = ({ data }: { data: any }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="x" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="y" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};
