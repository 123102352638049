import * as Sentry from "@sentry/browser";

export function handleAPIError(error, tags) {
  Sentry.withScope((scope) => {
    scope.setLevel(Sentry.Severity.Fatal);
    Sentry.captureException(error, {
      tags: { type: "API", ...tags },
    });
  });
}
