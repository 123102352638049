import React from "react";
import { css } from "glamor";
import { getAvatarColor } from "../../helpers/styles";

export default function Avatar({
  image,
  firstName,
  lastName,
  size,
  placeholder,
  customStyle,
  className,
  isCircle,
}: {
  image?: string;
  firstName: string;
  lastName: string;
  size?: "small" | "medium" | "large";
  placeholder?: string;
  customStyle?: any;
  className?: string;
  isCircle?: boolean;
}) {
  const background = firstName && getAvatarColor(firstName);

  let measurements = {
    width: 36,
    height: 36,
    lineHeight: "36px",
    fontSize: 16,
  };
  if (size === "small") {
    measurements = {
      width: 24,
      height: 24,
      lineHeight: "24px",
      fontSize: 12,
    };
  }
  if (size === "medium") {
    measurements = {
      width: 30,
      height: 30,
      lineHeight: "30px",
      fontSize: 12,
    };
  }
  if (size === "large") {
    measurements = {
      width: 72,
      height: 72,
      lineHeight: "72px",
      fontSize: 30,
    };
  }

  let secondLetter = "";
  if (lastName && lastName.length > 0) {
    secondLetter = lastName.charAt(0).toUpperCase();
  }

  const deleted = firstName === "Deleted" && lastName === "User";
  const anonymous = firstName === "Anonymous" && lastName === "User";

  if (anonymous) {
    return (
      <div
        {...css(
          {
            float: "left",
            textAlign: "center",
            background: "#353535",
            fontWeight: 900,
            color: !placeholder && "white",
            boxSizing: "border-box",
            borderRadius: isCircle ? "50%" : 5,
          },
          measurements,
          customStyle
        )}
        className={className}
      >
        <span className="bi_web-reading-list" />
      </div>
    );
  }
  return image ? (
    <img
      alt="avatar"
      {...css(
        { float: "left", borderRadius: isCircle ? "50%" : 5 },
        customStyle
      )}
      src={image}
      width={measurements.width}
      height={measurements.height}
      className={className}
    />
  ) : (
    <div
      {...css(
        {
          float: "left",
          textAlign: "center",
          background,
          borderRadius: isCircle ? "50%" : 5,
          fontWeight: 900,
          color: !placeholder && "white",
          boxSizing: "border-box",
        },
        measurements,
        customStyle,
        { background: deleted && "#e2e2e2 !important" }
      )}
      className={className}
    >
      {placeholder ? placeholder : firstName.charAt(0) + secondLetter}
    </div>
  );
}
