/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";

export default function ColorCube({ color, marginTop, marginLeft }) {
  return (
    <span
      css={css`
        float: right;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid #e2e2e2;
        border-radius: 3px;
        box-sizing: border-box;
        background: white;
        padding: 2px;
        margin-left: ${marginLeft ? marginLeft : 0}px;
        margin-top: ${marginTop || marginTop === 0 ? marginTop : 4}px;
      `}
    >
      <div
        css={css`
          width: 12px;
          height: 12px;
          background: ${color};
          border-radius: 1px;
        `}
      />
    </span>
  );
}
