import React, { useState } from "react";
import { css, select as $ } from "glamor";
import OptionsMenu from "./OptionsMenu";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

export default function MoreOptions({
  customStyle,
  options = [],
  toolTipText,
}) {
  const [menu, setMenu] = useState(false);
  return (
    <div {...css(customStyle)}>
      {menu ? (
        <OutsideAlerter action={() => setMenu(false)}>
          <OptionsMenu
            customStyle={{
              position: "absolute",
              right: "0",
              zIndex: "1",
              top: 10,
            }}
            options={options}
          />
        </OutsideAlerter>
      ) : (
        <div
          className="tooltip"
          onClick={() => setMenu(true)}
          {...css(
            { color: "rgba(0,0,0,0.54)", cursor: "pointer" },
            $(":hover", {
              color: "rgba(0,0,0,0.87)",
            })
          )}
          data-testid="moreOptions"
        >
          <span
            className="bi_interface-more"
            {...css({
              display: "block",
              transform: "rotate(90deg)",
            })}
          />
          {toolTipText && (
            <span
              className="tooltiptext"
              {...css({
                marginTop: "3.5px",
                marginLeft: "-79px !important",
                lineHeight: "initial",
              })}
              data-testid="toolTip"
            >
              {toolTipText}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

// export class OptionsMenu extends React.Component {
//   render() {
//     const list = this.props.options.map((item, i) => {
//       return (
//         <li
//           key={i}
//           onClick={this.props.loading ? () => {} : item.action}
//           {...css(
//             {
//               display: "block !important",
//               width: "100%",
//               lineHeight: "17px",
//               padding: "3px 6px 3px 6px",
//               boxSizing: "border-box",
//               fontSize: "14px",
//               fontWeight: "300",
//               cursor: "pointer",
//               color: "rgba(0,0,0,0.54)",
//               whiteSpace: "nowrap",
//               ...item.style,
//             },
//             $(":hover", {
//               background: "rgba(0,0,0,0.05)",
//               borderRadius: "3px",
//               fontWeight: "500",
//             })
//           )}
//         >
//           {item.icon && <span className={item.icon}> </span>}
//           {item.name}
//         </li>
//       );
//     });
//     return (
//       <div
//         {...css({
//           background: "white",
//           borderRadius: "5px",
//           padding: "9px 7px 9px 6px",
//           boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
//           boxSizing: "border-box",
//           ...this.props.customStyle,
//         })}
//       >
//         <ul {...css({ listStyle: "none", padding: "0", margin: "0" })}>
//           {list}
//         </ul>
//       </div>
//     );
//   }
// }
