import React from "react";
import { css, select as $, media } from "glamor";
import { motion } from "framer-motion";

import "./authentication.css";

import InputField from "../toolBox/InputField";
import Button from "../toolBox/Button";

import { colors } from "../../helpers/styles";

import Checked from "../../assets/images/check-blue.svg";
import Unchecked from "../../assets/images/check-empty-light.svg";
import Microsoft from "../../assets/images/microsoft.png";
import Google from "../../assets/images/google.png";

export const TERMS_PRIVACY_TEXT = (
  <span>
    By continuing, you agree to our{" "}
    <a href="https://soon.works/terms-of-service" target="_blank">
      Terms of Service
    </a>{" "}
    and{" "}
    <a href="https://soon.works/privacy-policy" target="_blank">
      Privacy Policy
    </a>
    .
  </span>
);

class AuthBox extends React.Component {
  state = {
    height: window.innerHeight,
  };

  updateDimensions = () => {
    this.setState({ height: window.innerHeight });
  };

  componentWillMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    const {
      icon,
      title,
      subtitle,
      subText,
      ssoType,
      removeSSOType,
      ssoButtons,
      inputs = [],
      bodyText,
      button,
      buttonTwo,
      bottomText,
      metaBoxOne,
      metaBoxTwo,
      customStyle,
      children,
    } = this.props;

    const height = this.state.height;

    const ssoButtonsList = ssoButtons
      ? ssoButtons.map((button) => {
          return (
            <Button
              key={button.name}
              name={
                <div
                  {...css({
                    float: "left",
                    width: "100%",
                    textAlign: "left",
                    paddingLeft: 14,
                    color: "rgba(0,0,0,0.87)",
                    height: 18,
                    boxSizing: "border-box",
                  })}
                >
                  <div
                    {...css({
                      float: "left",
                    })}
                  >
                    <img width={18} height={18} src={button.logo} />
                  </div>
                  <div
                    {...css({
                      float: "left",
                      marginLeft: 14,
                    })}
                  >
                    {button.name}
                  </div>
                </div>
              }
              theme="grey-border"
              size="large"
              action={button.action}
              customStyle={{
                width: "100%",
                marginBottom: 12,
                padding: 0,
                lineHeight: "initial",
              }}
            />
          );
        })
      : "";

    const inputList = inputs.map((input, i) => {
      return (
        <motion.li
          key={input.id}
          initial={{ marginLeft: -70, opacity: 0 }}
          animate={{ marginLeft: 0, opacity: 1 }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          <InputField
            autoFocus={i === 0}
            value={input.value}
            label={input.label}
            customStyle={input.customStyle}
            placeholder={input.placeholder}
            type={input.type}
            fieldName={input.name}
            changeInput={input.handleChange}
            rightLink={input.rightLink}
            immutable={input.immutable}
            error={input.error}
            options={input.options}
            handleFocus={input.onFocus}
            handleClick={input.onClick}
          />
        </motion.li>
      );
    });

    return (
      <div
        {...css(
          { float: "left", width: "100%", marginBottom: 50 },
          media("(max-width: 480px)", {
            marginBottom: 0,
            height,
            overflow: "scroll",
          })
        )}
      >
        <div
          {...css(
            {
              position: "absolute",
              width: 360,
              height: "fit-content",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 5,
              boxSizing: "border-box",
              background: "white",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              margin: "auto",
              /*Solves a problem in which the content is being cut when the div is smaller than its' wrapper:*/
              maxWidth: "100%",
              maxHeight: "100%",
              overflow: "auto",
            },
            customStyle,
            media("(max-width: 480px)", {
              width: "calc(100% - 32px)",
              marginLeft: 16,
              marginRight: 16,
              transform: "translateX(0%)",
              marginTop: 36,
              maxHeight: "fit-content",
              position: "initial",
              float: "left",
              overflow: "initial",
              marginBottom: 136,
            })
          )}
        >
          <div
            {...css({
              float: "left",
              width: "100%",
              padding: 36,
              paddingBottom: 24,
              boxSizing: "border-box",
            })}
          >
            <div
              {...css({
                float: "left",
                width: "100%",
                textAlign: icon ? "left" : "center",
                fontSize: 20,
                lineHeight: "24px",
              })}
            >
              {icon && <span style={{ color: colors.blue }} className={icon} />}{" "}
              {title}
            </div>
            <div
              {...css({
                float: "left",
                width: "100%",
                marginTop: 6,
                marginBottom: subText ? 24 : 36,
                textAlign: "center",
                fontSize: 16,
                lineHeight: "19px",
                fontWeight: 300,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflowX: "hidden",
              })}
            >
              {subtitle}
            </div>
            {subText && (
              <div
                {...css({
                  float: "left",
                  width: "100%",
                  marginBottom: 24,
                  textAlign: "center",
                  fontSize: 14,
                  lineHeight: "16.8x",
                  fontWeight: 300,
                })}
              >
                {subText}
              </div>
            )}
            {children && children}
            {ssoType && (
              <LinedItem>
                <img
                  src={ssoType === "MicrosoftOAuth" ? Microsoft : Google}
                  width={18}
                  alt={
                    ssoType === "MicrosoftOAuth"
                      ? "Sign up with Microsoft"
                      : "Sign up with Google"
                  }
                  {...css({ float: "left", marginRight: 12 })}
                />
                <div
                  {...css({
                    float: "left",
                    marginRight: 9,
                    lineHeight: "18px",
                  })}
                >
                  Signing up with{" "}
                  {ssoType === "MicrosoftOAuth" ? "Microsoft" : "Google"}
                </div>
                <div className="tooltip">
                  <span
                    {...css(
                      {
                        float: "left",
                        fontSize: 18,
                        lineHeight: "18px",
                      },
                      $(":hover", {
                        color: colors.red,
                      })
                    )}
                    className="bi_interface-circle-cross"
                    onClick={removeSSOType}
                  />
                  <span
                    className="tooltiptext"
                    {...css(
                      {
                        left: "auto !important",
                        right: "3px !important",
                        top: "23px !important",
                      },
                      $("::after", {
                        left: 44,
                      })
                    )}
                  >
                    Cancel
                  </span>
                </div>
              </LinedItem>
            )}
            {ssoButtons && (
              <div
                {...css({ float: "left", width: "100%", marginBottom: 3.5 })}
              >
                <div
                  {...css({ float: "left", width: "100%", marginBottom: 27.5 })}
                >
                  {ssoButtonsList}
                </div>
                <LinedItem>or</LinedItem>
              </div>
            )}
            <form onSubmit={(e) => e.preventDefault()}>
              <ul {...css({ listStyle: "none", margin: 0, padding: 0 })}>
                {inputList}
              </ul>
              {bodyText && bodyText}
              {button && (
                <Button
                  name={button.name}
                  theme={button.theme}
                  action={button.action}
                  disabled={button.disabled}
                  customStyle={{ width: "100%", ...button.customStyle }}
                  loading={button.loading}
                  type="submit"
                />
              )}
              {buttonTwo && (
                <Button
                  name={buttonTwo.name}
                  theme={buttonTwo.theme}
                  action={buttonTwo.action}
                  disabled={buttonTwo.disabled}
                  customStyle={{ width: "100%", ...buttonTwo.customStyle }}
                  loading={buttonTwo.loading}
                  type="submit"
                />
              )}
            </form>

            {bottomText && (
              <div
                {...css({
                  float: "left",
                  width: "100%",
                  marginTop: 24,
                  fontSize: 14,
                  lineHeight: "17px",
                  textAlign: "center",
                  fontWeight: 300,
                })}
              >
                {bottomText}
              </div>
            )}
          </div>
          {metaBoxOne && <div className="auth-box-meta">{metaBoxOne}</div>}
          {metaBoxTwo && (
            <div
              className="auth-box-meta"
              {...css({
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
              })}
            >
              {metaBoxTwo}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AuthBox;

export const PasswordCheck = ({ validPassword, customStyle }) => {
  return (
    <ul
      {...css(
        {
          float: "left",
          width: "100%",
          margin: 0,
          padding: 0,
          marginTop: -20,
          marginBottom: 36,
          listStyle: "none",
        },
        $(" li", {
          float: "left",
          width: "100%",
          height: 20,
          fontSize: 14,
          lineHeight: "20px",
          color: "rgba(0,0,0,0.54)",
          fontWeight: 300,
          marginBottom: 6,
        }),
        customStyle
      )}
    >
      <li>
        <div {...css({ float: "left", marginRight: 6, paddingTop: 1.7 })}>
          {validPassword.hasLowerCase ? (
            <img src={Checked} width="12.5" alt="Checked" />
          ) : (
            <img src={Unchecked} width="12.5" alt="Unchecked" />
          )}
        </div>
        <div
          {...css({
            float: "left",
            color: validPassword.hasLowerCase
              ? colors.blue
              : "rgba(0,0,0,0.54)",
          })}
        >
          1 lowercase character
        </div>
      </li>
      <li>
        <div {...css({ float: "left", marginRight: 6, paddingTop: 1.7 })}>
          {validPassword.hasUpperCase ? (
            <img src={Checked} width="12.5" alt="Checked" />
          ) : (
            <img src={Unchecked} width="12.5" alt="Unchecked" />
          )}
        </div>
        <div
          {...css({
            float: "left",
            color: validPassword.hasUpperCase
              ? colors.blue
              : "rgba(0,0,0,0.54)",
          })}
        >
          1 uppercase character
        </div>
      </li>
      <li>
        {" "}
        <div {...css({ float: "left", marginRight: 6, paddingTop: 1.7 })}>
          {validPassword.hasNumber ? (
            <img src={Checked} width="12.5" alt="Checked" />
          ) : (
            <img src={Unchecked} width="12.5" alt="Unchecked" />
          )}
        </div>
        <div
          {...css({
            float: "left",
            color: validPassword.hasNumber ? colors.blue : "rgba(0,0,0,0.54)",
          })}
        >
          1 number
        </div>
      </li>
      <li>
        {" "}
        <div {...css({ float: "left", marginRight: 6, paddingTop: 1.7 })}>
          {validPassword.hasSymbol ? (
            <img src={Checked} width="12.5" alt="Checked" />
          ) : (
            <img src={Unchecked} width="12.5" alt="Unchecked" />
          )}
        </div>
        <div
          {...css({
            float: "left",
            color: validPassword.hasSymbol ? colors.blue : "rgba(0,0,0,0.54)",
          })}
        >
          1 special character
        </div>
      </li>
      <li>
        {" "}
        <div {...css({ float: "left", marginRight: 6, paddingTop: 1.7 })}>
          {validPassword.hasMoreThanEight ? (
            <img src={Checked} width="12.5" alt="Checked" />
          ) : (
            <img src={Unchecked} width="12.5" alt="Unchecked" />
          )}
        </div>
        <div
          {...css({
            float: "left",
            color: validPassword.hasMoreThanEight
              ? colors.blue
              : "rgba(0,0,0,0.54)",
          })}
        >
          8 characters minimum
        </div>
      </li>
    </ul>
  );
};

function LinedItem({ children }) {
  return (
    <div
      {...css({
        float: "left",
        width: "100%",
        fontSize: 14,
        color: "rgba(0,0,0,0.54)",
        borderBottom: "1px solid #E2E2E2",
        position: "relative",
        marginBottom: "35px",
      })}
    >
      <div
        {...css({
          position: "absolute",
          background: "white",
          left: "50%",
          transform: "translateX(-50%)",
          top: -7,
          whiteSpace: "nowrap",
          paddingLeft: 12,
          paddingRight: 12,
          width: "max-content",
        })}
      >
        {children}
      </div>
    </div>
  );
}
