import { useState, useEffect, useRef } from "react";
import { getBrowserVisibilityProp, getIsDocumentHidden } from "./functions";

export function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

export function useScrollIndicator(
  scrollRef: React.RefObject<HTMLDivElement>,
  effectArg?: string
) {
  // ref
  const [showIndicator, setShowIndicator] = useState(true);

  const checkScroll = () => {
    if (!scrollRef.current) return;

    const { scrollHeight, clientHeight, scrollTop } = scrollRef.current;
    // Determine whether the content is scrollable and whether it's at the bottom.
    if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
      setShowIndicator(false);
    } else {
      setShowIndicator(true);
    }
  };

  const useEffectArg = effectArg || effectArg === "" ? [effectArg] : [];
  useEffect(() => {
    // Initial check to see if content is not long enough to scroll.
    checkScroll();

    const el = scrollRef.current;

    if (el) {
      // Attach the event listener to the scrollable div.
      el.addEventListener("scroll", checkScroll);
    }

    return () => {
      // Clean up event listener when component unmounts.
      if (el) {
        el.removeEventListener("scroll", checkScroll);
      }
    };
  }, useEffectArg);

  return { showIndicator, checkScroll };
}

export function useServiceWorkerUpdate() {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  function handlePreloadError() {
    window.location.reload();
  }

  useEffect(() => {
    window.addEventListener("vite:preloadError", handlePreloadError);
    return () => {
      window.removeEventListener("vite:preloadError", handlePreloadError);
    };
  }, [handlePreloadError]);

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.addEventListener("updatefound", () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  // New content is available; prompt the user to refresh.
                  setNewVersionAvailable(true);
                }
              }
            };
          }
        });
      });
    }
  }, []);

  return newVersionAvailable;
}
