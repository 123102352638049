import React from "react";
import { css, select as $, media } from "glamor";
import Button from "./Button";
import { colors } from "../../helpers/styles";

export default function MessageModal({
  icon,
  iconColor,
  iconJsx,
  iconImage,
  iconImageStyle,
  title,
  message,
  buttonOne,
  buttonTwo,
}) {
  return (
    <>
      <div
        {...css(
          {
            position: "absolute",
            width: "calc(100% - 32px)",
            maxWidth: 360,
            height: "fit-content",
            marginRight: 16,
            marginLeft: 16,
            background: "white",
            zIndex: 100,
            borderRadius: 5,
            left: "50%",
            margin: "auto",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            padding: 36,
            boxSizing: "border-box",
          },
          $(" p", {
            color: "rgba(0,0,0,0.87)",
            fontWeight: 300,
            fontSize: 14,
            padding: 0,
            margin: 0,
            marginBottom: 24,
            lineHeight: "17px",
          }),
          media("(max-width: 480px)", {
            marginTop: 84,
          })
        )}
      >
        {iconImage && (
          <div {...css({ float: "left", width: 30, marginRight: 6 })}>
            <img style={iconImageStyle} src={iconImage} />
          </div>
        )}
        {iconJsx && iconJsx}
        <h1
          style={{
            float: "left",
            width: iconImage || iconJsx ? "calc(100% - 36px)" : "100%",
            fontSize: 20,
            lineHeight: "24px",
            padding: 0,
            margin: 0,
            fontWeight: 500,
            marginBottom: 36,
          }}
        >
          {!iconImage && (
            <span
              className={icon}
              style={{
                color: iconColor ? iconColor : colors.red,
                fontSize: 24,
              }}
            />
          )}{" "}
          {title}
        </h1>
        <div>{message}</div>
        {buttonOne && (
          <Button
            name={buttonOne.name}
            theme={buttonOne.theme ? buttonOne.theme : "red-border"}
            customStyle={{
              width: "100%",
              marginBottom: 12,
              ...buttonOne.customStyle,
            }}
            action={buttonOne.action}
          />
        )}
        {buttonTwo && (
          <Button
            name={buttonTwo.name}
            theme={buttonTwo.theme ? buttonTwo.theme : "blue-border"}
            customStyle={{ width: "100%", ...buttonTwo.customStyle }}
            action={buttonTwo.action}
          />
        )}
      </div>
    </>
  );
}
